export class ShowroomData {
  ['@odata.count']: number;
  id: string;
  oldId: number;
  companyOldId: number;
  make: string;
  masterModel: string;
  variant: string;
  modelYear: string;
  color: string;
  baseColor: string;
  bodyType: string;
  customBodyType: string;
  mileage: number;
  miles: Array<{
    low: number;
    high: number
  }>;
  sellingPrice: number;
  selling: Array<{
    low: number;
    high: number
  }>;
  sellingLow: number;
  sellingHigh: number;
  region: string;
  dateCreated: string;
  dateUpdated: string;
  image_url: string;
  imageUrl: string;
  transmission: string;
  tradingName: string;
  latitude: number | string;
  longitude: number | string;
  addressLine1: string;
  city: string;
  valueInKms?: string;
  images: string;
  top?: string;
  skip?: string;

}
