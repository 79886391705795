<section class="page-container">
  <div class="container-fluid" style="padding: 0">
    <div class="polo-header">
      <h1>Toyota
        <br>
        <span>Hilux</span>
      </h1>
      <img src="../../assets/images/showroom-banners/hilux.png">
    </div>
  </div>
</section>
<app-showroom></app-showroom>
