<section class="page-container">
  <div class="home-body-container">
    <div class="container bg-white pd-30">
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom: 40px;text-align: center">What Is TheCarPlace?</h1>
          <div class="vid-section">
            <iframe src="https://www.youtube.com/embed/9sO6pMq3muk" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="" title="YouTube video player" ></iframe>
          </div>

          <p>TheCarPlace.co.za is a vehicle buying and selling platform designed for the exclusive benefit of the South African Motoring public.
            It is a platform built by South Africans for South Africans.</p>
          <p>TheCarPlace.co.za is partnered with VMG Dealership Management Software (DMS) who for over 16 years, serve the Admin,
            Accounting, Web Management, CRM and Mobile App Software needs for over 400 independent dealerships in South Africa.
          </p>

          <p></p>
          <p>TheCarPlace.co.za has two distinct Vehicle showrooms: </p>
          <p>1. TheCarPlace.co.za online showroom is available exclusively to VMG DMS Customers.</p>
          <p>2. The Capitec Finance Channel Showroom is available to all South African Dealerships who meet the
            stringent qualification criteria.</p>

          <p>TheCarPlace.co.za is focused on three main priorities…</p>
          <p>1. The platform is designed to <strong>help the South Africa motoring public</strong> gain access to <strong>South Africa’s
            most reputable motor dealers</strong> and <a [routerLink]="['/showroom']">ALL THEIR VEHICLES FOR SALE.</a></p>
          <p>2. With many thanks to the amazing and generous teams at <strong>Capitec Bank</strong>, the platform also helps
            South Africans apply for Capitec Finance for any of the <strong>approved vehicles</strong> found in the
            <a [routerLink]="['/capitec-finance']">CAPITEC FINANCE CHANNEL SHOWROOM.</a></p>
          <p>3. TheCarPlace.co.za is also a channel to enable SA public who wish to <a [routerLink]="['/sell-car']">SELL A CAR</a> for the <strong>best price</strong>
            possible in the market. Instead of these cars being sold to big corporates who then sell them onto
            the dealers for a higher price, the motoring public can <strong>sell their cars direct</strong> to the independent
            dealerships to keep things local and orientated around <strong>supporting smaller family orientated
              businesses.</strong></p>
          <p>Working alongside the team at VMG Software, TheCarPlace.co.za aspires to add more value to your dealership and the Motor Trade of SA.</p>
          <div style="text-align: center">
            <img class="img-width" src="../../../../assets/images/findrightcar.png" alt="">
            <div class="col-sm-12">
              <button class="btn tcp-btn bg-tcp-red" [routerLink]="['/showroom']" >
                SHOP NOW!
              </button>
            </div>
            <div class="col-sm-12">
              <button class="btn tcp-btn bg-tcp-blue" [routerLink]="['/dealer-sign-up']" >
                REGISTER DEALERSHIP!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
