<div class = "car-enquire brochure_tab">
  <div class = "mailsent"
       *ngIf = "mailSent">Your request has been sent!
  </div>
  <div class = "container form-container">
    <form [formGroup] = "financeForm"
          *ngIf = "financeFormHide"
          (ngSubmit) = "onSubmit()"
          class="mt-2">
      <div class ="row">
        <div class = "col-md-12">
          <div class = "form-group">
            <span *ngIf = "financeForm.controls['name'].invalid &&
        (financeForm.controls['name'].dirty || financeForm.controls['name'].touched || financeForm.controls['name'].errors )"
                  class = "required">*</span>
            <input type = "text"
                   formControlName = "name"
                   placeholder = "Name"
                   class = "form-control">
          </div>
        </div>
        <div class = "col-md-12">
          <div class = "form-group">
            <span *ngIf = "financeForm.controls['contactNumber'].invalid &&
        (financeForm.controls['contactNumber'].dirty || financeForm.controls['contactNumber'].touched || financeForm.controls['contactNumber'].errors )"
                  class = "required">
               *
            </span>
            <input type = "tel"
                   formControlName = "contactNumber"
                   placeholder = "Contact Number"
                   class = "form-control"
                   pattern="[0-9]*">
          </div>
        </div>
      </div>


      <div class = "form-group">
            <span *ngIf = "financeForm.controls['eMail'].invalid &&
        (financeForm.controls['eMail'].dirty || financeForm.controls['eMail'].touched || financeForm.controls['eMail'].errors )"
                  class = "required">
               *
            </span>
        <input type = "email"
               formControlName = "eMail"
               placeholder = "Email"
               class = "form-control">
      </div>
      <div class="form-group">
            <span *ngIf="financeForm.controls['myId'].invalid &&
        (financeForm.controls['myId'].dirty || financeForm.controls['myId'].touched || financeForm.controls['myId'].errors )"
                  class="required">*</span>
        <input type="number"
               formControlName="myId"
               placeholder="ID Number"
               class="form-control">
      </div>
      <div class="form-group">
        <textarea formControlName="message"
                  placeholder="Additional Information"
                  class="form-control"
                  style="height: 120px">
        </textarea>
      </div>
      <div class="form-group">
        <label>
          <input type="checkbox"
                 [(ngModel)]="credit"
                 formControlName="creditCheck">
          Permission ganted for NCR credit check
        </label>
      </div>
      <div class="form-group">
        <label>
          <input type="checkbox"
                 [(ngModel)]="deposit"
                 formControlName="deposit">
          Deposit?
        </label>
      </div>
      <div class="form-group"
           *ngIf="deposit">
        <input type="number"
               required
               formControlName="depositAmount"
               placeholder="Deposit Amount"
               class="form-control">
      </div>
      <div class="form-group">
        <label>
          <input type="checkbox"
                 [(ngModel)]="tradeIn"
                 formControlName="tradeIn">
          Trade In?
        </label>
      </div>
      <div class="form-group"
           *ngIf="tradeIn">
        <textarea formControlName="tradeInDetails"
                  placeholder="TradeIn Details"
                  class="form-control"
                  style="height: 80px">
        </textarea>
      </div>
      <div class="form-group">
        <label>
          <input type="checkbox"
                 [(ngModel)]="newsSpecials"
                 formControlName="newsSpecials">
          Email me news and specials
        </label>
      </div>

      <div class = "form-group">
        <button *ngIf = "btnShow"
                [disabled] = "!financeForm.valid">SEND&nbsp;&nbsp;<fa-icon [icon]="arrowIcon"></fa-icon>
        </button>
      </div>
    </form>
    <div class = "form-spinner"
         *ngIf = "spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</div>

