<section class="page-container" style="background: #fff">
  <div *ngIf="loading" class="centered">
      <div class="col-md-12">
        <div style="display:flex; justify-content: center;"
             class="showroom-loader">
          <img src="assets/images/TCP_Gif.gif" alt="theCarPlace">
        </div>
      </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mobile-center"
             style="align-self:center;">
          <div class="make-header">
            <h1>{{car.make}}</h1>
            <h1 class="secondary-title">{{car.variant}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <div class="pricing-row">
            <h4 class="vehicle-price"
                *ngIf="car.sellingPrice > 1000">{{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}</h4>
            <h4 class="vehicle-price" *ngIf="car.sellingPrice < 1000">POA</h4>
            <p class="disclaimer">Excludes Value Add Products and Fees</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 col-sm-4 spacer-vehicle-page mobile-center" *ngIf="!mobileTabs">
          <button class="button-color-2 back-button cfc-back"
                  (click)="backToShowroom()">
            <fa-icon [icon]="backIcon" class="double-chev"></fa-icon>
            BACK
          </button>
        </div>
      </div>
      <div class="row">
        <div *ngIf="mobileTabs" class="col-md-12">
          <div style="display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; align-items: center">

            <div class="social-shares capitec-social-shares">

              <div class="row m-0 gap-1">
                <h5 class="col">Share:</h5>
                <div class="col" style="background-color: #0866FF" >
                  <a [href]="facebookLink" target="_blank"><i class="pi pi-facebook"></i></a>
                </div>
                <div class="col" style="background-color: #1D9BF0" >
                  <a [href]="twitterLink" target="_blank"><i class="pi pi-twitter"></i></a>
                </div>
                <div class="col" style="background-color: #25D366">
                  <a [href]="whatsAppLink" target="_blank"><i  class="pi pi-whatsapp"></i></a>
                </div>
              </div>

            </div>
            <button class="button-color-2 back-button cfc-back"
                    (click)="backToShowroom()">
              <fa-icon [icon]="backIcon" class="double-chev"></fa-icon>
              BACK
            </button>
          </div>
        </div>


      </div>
      <div class="row">
        <div class="col-md-8 col-lg-6 ">
          <div>
            <p-galleria [value]="slides"
                        [responsiveOptions]="responsiveOptions"
                        [numVisible]="4"
                        [circular]="true"
                        [containerStyle]="{ 'max-width': '850px' }"
                        [showItemNavigators]="true">
              <ng-template pTemplate="item" let-item let-rowindex="rowindex">
                <img [src]="item" style="width: 100%;" (click)="imageClick(item)" />
              </ng-template>
              <ng-template pTemplate="thumbnail" let-item let-rowindex="rowindex">
                <div class="grid grid-nogutter justify-content-center">
                  <img [src]="item" style="padding: 8px" (click)="imageClick(item)" />
                </div>
              </ng-template>
            </p-galleria>
            <p-galleria
              [value]="slides"
              [(visible)]="displayCustom"
              [(activeIndex)]="activeIndex"
              [containerStyle]="{ 'max-width': '850px' }"
              [numVisible]="7"
              [circular]="true"
              [fullScreen]="true"
              [showItemNavigators]="true"
              [showThumbnails]="false"
            >
              <ng-template pTemplate="item" let-item>
                <img [src]="item" style="width: 100%; display: block;" />
              </ng-template>
            </p-galleria>
          </div>
        </div>
        <div class="col-md-8 col-lg-3">
          <div class="details-sidebar">
            <h4>Vehicle Details:</h4>
            <ul>
              <li>
                <strong>Variant:</strong>
                {{car.variant || 'n/a'}}
              </li>
              <li>
                <strong>Body Type:</strong>
                {{car.customBodyType || 'n/a'}}
              </li>
              <li>
                <strong>Year:</strong>
                {{car.modelYear || 'n/a'}}
              </li>
              <li>
                <strong>Mileage:</strong>
                {{car.mileage || 'n/a'}}
              </li>
              <li>
                <strong>Colour:</strong>
                {{car.color || 'n/a'}}
              </li>
              <li>
                <strong>Condition: </strong>
                {{car.condition || 'n/a'}}
              </li>
              <li>
                <strong>Region: </strong>
                {{car.region || 'n/a'}}
              </li>
            </ul>

          </div>
          <div class="row" *ngIf="mobileTabs">
            <div class="col-lg-12 specs">
              <hr class="mobile">
              <div class=" vehicle-specs" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false"
                   aria-controls="collapseExample">
                <div class="row">
                  <div class="col-md-10 col-10">
                    <h5>Vehicle Specifications</h5>
                  </div>
                  <div class="col-md-2 col-2">
                    <img src="/assets/images/icons/plus.svg" class="plus-icon" width="17px">
                    <img class="minus-icon" src="/assets/images/icons/minus.svg" width="18px">
                  </div>
                </div>
              </div>
              <hr class="mobile">
            </div>
            <div class="col-lg-12">
              <div class="details-sidebar" style="padding: 0 10px; text-align: center">
                <ul class="collapse dont-collapse-sm" id="collapseExample" style="padding: 20px 0; margin-left: 20px;">
                  <div class="">
                    <div class="tech-spec-container row">
                      <div class="col-md-12">
                        <p style="text-align: left; word-wrap: break-word;"
                           [innerHtml]="car.description || '- no description -'"></p>
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">HEIGHT</span>{{car.stockInformationHeight + "cm" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">WIDTH</span>{{car.stockInformationWidth + "cm" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">LENGTH</span>{{car.stockInformationLength + "cm" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">FUEL</span>{{car.stockInformationFuelType || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">TANK</span>{{car.stockInformationFuelTankSize + " L" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">TRANSMISSION</span>{{car.stockInformationTransmission || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">COOLING</span>{{car.stockInformationCooling || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">GEARS</span>{{car.stockInformationGears || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">SEATS</span>{{car.stockInformationSeats || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">DOORS</span>{{car.stockInformationDoors || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span
                          class="tech-spec">ENGINE</span>{{car.stockInformationCubicCapacity + "cm&#178;" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">POWER</span>{{car.stockInformationKilowatts + " kw" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">DRIVETRAIN</span>{{car.stockInformationAxleConfiguration || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                        <span class="tech-spec">TARE</span>{{car.stockInformationTares + " kg" || 'n/a'}}
                      </div>
                      <div class="tech-specs-block col-xl-4 col-lg-4 col-md-6 col-sm-4 col-12">
                        <span class="tech-spec">CO&#8322;</span>{{car.stockInformationCo2 || 'n/a'}}
                      </div>
                    </div>
                  </div>

                </ul>
              </div>
            </div>

            <div class="col-lg-12 car-descrip details">
              <div class="dealer-details" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false"
                   aria-controls="collapseExample2">
                <div class="row">
                  <div class="col-md-10 col-10">
                    <h5>Vehicle Extras</h5>
                  </div>
                  <div class="col-md-2 col-2">
                    <img src="/assets/images/icons/plus.svg" class="plus-icon" width="17px">
                    <img class="minus-icon" src="/assets/images/icons/minus.svg" width="18px">
                  </div>
                </div>
              </div>
              <hr class="mobile">
            </div>
            <div class="col-lg-12">
              <div class="collapse dont-collapse-sm" id="collapseExample2" style="padding: 20px 25px;">
                <div *ngIf="!car.extras">
                  <p>Currently no extras listed</p>
                </div>
                <ul class="vehicle-extras" >
                  <li *ngFor="let extra of carExtras">
                    <span>{{extra || 'n/a'}}</span>
                  </li>
                </ul>
              </div>
            </div>


            <div class="col-lg-12 dealer" style="display: contents">
              <div class="collapse dont-collapse-sm" id="collapseExample1" style="margin-left: 30px;">
                <img class="dealer-logo img-fluid"
                     style="width: 100px; margin: 10px 0"
                     src="{{car.branchLogoUrl || localData.store.defaultLogo}}"
                     alt="{{car.branchTradingName || car.branchCompanyName}}">
                <h4>{{car.branchTradingName || car.branchCompanyName}}</h4>
                <ul>
                  <li>
                    <span>Address: </span>
                    <a
                      href="https://maps.google.com/?q={{car.branchTradingName || car.branchCompanyName}} {{car.branchAddressLine1}} {{car.branchRegion }}"
                      target="_blank">
                      {{car.branchAddressLine1 || 'n/a'}}<br>
                      <strong>{{car.branchRegion || 'unknown region'}}</strong> </a>
                  </li>
                  <li>
                    <span>Tel: </span>
                    <a href="tel:{{car.branchWhatsappNumber}}"
                       target="_blank"> {{car.branchWhatsappNumber || 'n/a'}}</a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
          <div *ngIf="!mobileTabs" style="margin-top: 15px">
            <hr>
            <div class="social-shares capitec-social-shares">

              <div class="row m-0 gap-1">
                <h5 class="col">Share:</h5>
                <div class="col" style="background-color: #0866FF" >
                  <a [href]="facebookLink" target="_blank"><i class="pi pi-facebook"></i></a>
                </div>
                <div class="col" style="background-color: #1D9BF0" >
                  <a [href]="twitterLink" target="_blank"><i class="pi pi-twitter"></i></a>
                </div>
                <div class="col" style="background-color: #25D366">
                  <a [href]="whatsAppLink" target="_blank"><i  class="pi pi-whatsapp"></i></a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-3 position-relative" *ngIf="!mobileTabs">

          <div class="accordion" id="form">
            <div style="margin-bottom:149px">
              <img src="/assets/images/Vehicle%20View/vehicle-banner-1.webp" alt="capitec-vehicle-banner"
                   style="height: auto">
              <div class="btn capitec-pop-up" id="formhead2" style="width: 100%;">
                <div class="capitec-approved-logo">
                  <img src="../../../../../assets/images/CapitecStamp.png" alt="capitec-approved-stamp">
                </div>
                <a href="https://www.capitecbank.co.za/vehicle-estimate?r=TheCarPlace&c=269" target="_blank">
                  <button type="button"  class="apply-button cfc-applynow" label="Show">
                    <h4 class="capitec-apply-button">
                      Apply Now
                    </h4>
                  </button>
                </a>

              </div>
              <img src="/assets/images/Vehicle%20View/vehicle-banner-2.webp" alt="capitec-vehicle-banner"
                   style="height: auto">
              <div class="capitec-reference text-center">
                <p style="margin-bottom: 0">
                  <strong>Vehicle Reference Number</strong>
                </p>
                <p class="reference-number">
                  {{car.companyOldId}} - {{car.oldId}}
                </p>
                <p style=" padding: 5px; font-size: 15px">
                  Please keep this Reference number on record. Your Capitec consultant will require it.
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-12 col-lg-9 min-h500" >
          <div class="forms-block" *ngIf="!mobileTabs" style="margin-bottom: 40px">


            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <span>VEHICLE SPECIFICATIONS</span>
                </ng-template>
                <div class="container">
                  <div class="tech-spec-container row">
                    <div class="col-md-12">
                      <p style="text-align: left; "
                         [innerHtml]="car.description || '- no description -'"></p>
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">HEIGHT</span>{{car.stockInformationHeight + "cm" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">WIDTH</span>{{car.stockInformationWidth + "cm" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">LENGTH</span>{{car.stockInformationLength + "cm" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">FUEL</span>{{car.stockInformationFuelType || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">TANK</span>{{car.stockInformationFuelTankSize + " L" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">TRANSMISSION</span>{{car.stockInformationTransmission || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">COOLING</span>{{car.stockInformationCooling || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">GEARS</span>{{car.stockInformationGears || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">SEATS</span>{{car.stockInformationSeats || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">DOORS</span>{{car.stockInformationDoors || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">ENGINE</span>{{car.stockInformationCubicCapacity + "cm&#178;" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-4 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">POWER</span>{{car.stockInformationKilowatts + " kw" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-4 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">DRIVETRAIN</span>{{car.stockInformationAxleConfiguration || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-4 col-md-6 col-sm-4 col-6">
                      <span class="tech-spec">TARE</span>{{car.stockInformationTares + " kg" || 'n/a'}}
                    </div>
                    <div class="tech-specs-block col-xl-2 col-lg-4 col-md-6 col-sm-4 col-12">
                      <span class="tech-spec">CO&#8322;</span>{{car.stockInformationCo2 || 'n/a'}}
                    </div>
                  </div>
                  <div class="row">
                    <h3></h3>

                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <span>VEHICLE EXTRAS</span>
                </ng-template>
                <div *ngIf="!car.extras">
                  <p>Currently no extras listed</p>
                </div>
                <ul class="vehicle-extras" [ngClass]="{'columns2' : carExtras.length > 15}">
                  <li *ngFor="let extra of carExtras">
                    <span>{{extra || 'n/a'}}</span>
                  </li>
                </ul>
              </p-tabPanel>
            </p-tabView>



          </div>
        </div>
        <div class="col-md-12">
          <div class="forms-block" *ngIf="mobileTabs">
            <img src="/assets/images/Vehicle%20View/vehicle-banner-1.webp" alt="capitec-vehicle-banner"
                 style="height: auto">
            <div class="accordion" id="form">
              <div class="btn capitec-pop-up" id="formhead2" style="width: 100%;">
                <div class="capitec-approved-logo">
                  <img src="../../../../../assets/images/CapitecStamp.png" alt="capitec-approved-stamp">
                </div>
                <a href="https://www.capitecbank.co.za/vehicle-estimate?r=TheCarPlace&c=269" target="_blank">
                  <button type="button"  class="apply-button cfc-applynow" label="Show">
                    <h4 class="capitec-apply-button">
                      Apply Now
                    </h4>
                  </button>
                </a>

              </div>
            </div>
            <img src="/assets/images/capitec-banners/vehicle-banner-2.webp" alt="capitec-vehicle-banner"
                 style="height: auto">

            <div class="capitec-reference text-center">
              <p style="margin-bottom: 0">
                <strong>Vehicle Reference Number</strong>
              </p>
              <p class="reference-number">
                {{car.companyOldId}} - {{car.oldId}}
              </p>
              <p style=" padding: 5px; font-size: 15px">
                Please keep this Reference number on record. Your Capitec consultant will require it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

