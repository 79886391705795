<section class="page-container"  >
  <div class="home-body-container" >
    <div class="container dealer-sign-up" >
      <div class="row">
        <div class="col-md-12">
          <h1>Find your Dealership</h1>
        </div>
      </div>
      <div class="accordion customAccordion" id="accordionExample">
        <div class="faq">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <p-card class="question-faq">
              Eastern Cape Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapseOne" class="faq-body collapse" aria-labelledby="headingOne" data-parent="#accordionExample" >
                <table >
                  <tr>
                    <th>Trading Name</th>
                    <th>Current Vehicles</th>
                  </tr>
                  <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Eastern Cape'">
                    <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                    <td>
                      <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                        <p class="view-dealer-car-button">View All Current Vehicles</p>
                      </a>
                    </td>
                  </tr>
                </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <p-card class="question-faq">
              Free State Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapseTwo" class="faq-body collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <table #dt>
                <tr>
                  <th>Trading Name</th>
                  <th>Current Vehicles</th>
                </tr>
                <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Free State'">
                  <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                  <td>
                    <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                    </a>
                  </td>
                </tr>
              </table>
          </div>
        </div>
        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <p-card class="question-faq">
              Gauteng Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapseThree" class="faq-body collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <table >
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Gauteng'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
            <p-card class="question-faq">
              KwaZulu Natal Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse4" class="faq-body collapse" aria-labelledby="heading4" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'KwaZulu Natal'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            <p-card class="question-faq">
              Limpopo Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse5" class="faq-body collapse" aria-labelledby="heading5" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Limpopo'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>

                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            <p-card class="question-faq">
              Mpumalanga Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse6" class="faq-body collapse" aria-labelledby="heading6" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Mpumalanga'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
            <p-card class="question-faq">
              North West Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse7" class="faq-body collapse" aria-labelledby="heading7" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading  Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'North West'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
            <p-card class="question-faq">
              Northern Cape Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse8" class="faq-body collapse" aria-labelledby="heading8" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Northern Cape'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>

                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="faq">
          <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
            <p-card class="question-faq">
              Western Cape Dealers
              <fa-icon [icon]="chevronDown"></fa-icon>
            </p-card>
          </button>
          <div id="collapse9" class="faq-body collapse" aria-labelledby="heading9" data-parent="#accordionExample">
            <table>
              <tr>
                <th>Trading Name</th>
                <th>Current Vehicles</th>
              </tr>
              <tr *ngFor="let dealer of dealership | filterBy: ['region']: 'Western Cape'">
                <td>{{(dealer.tradingName === "") ? dealer.companyName : dealer.tradingName }}</td>
                <td>
                  <a class="btn-allcars"  *ngIf="dealer?.companyName"  [routerLink]="['', (dealer.tradingName === '') ? dealer.companyName : dealer.tradingName, dealer.companyOldId]">
                    <p class="view-dealer-car-button">View All Current Vehicles</p>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
