<section class="footer">
  <div class="footer-main">
    <div class="container">
      <div class="row">
        <div class="col-md-3 order-md-1 footer_nav_block">
          <h4 class="footer-heading"><span style="background-color:#007b9a;padding-right:15px;">Navigation </span></h4>
          <app-nav-links class="nav-item"></app-nav-links>
          <a (click)="showDialog()" >
            <p style="margin: 5px 0">
              VEHICLE REFERENCE SEARCH
            </p>
          </a>
          <p-dialog header="Reference Search" [(visible)]="display" [modal]="true"
                    [draggable]="false" [resizable]="false">

            <div class="reference-search">
              <p-inputNumber [(ngModel)]="companyIdRef" [useGrouping]="false"></p-inputNumber>
              <span class="search-divider"> - </span>
              <p-inputNumber [(ngModel)]="oldIdRef" [useGrouping]="false" class="old-id-ref"></p-inputNumber>
              <button class="ref-button-color"
                      (click)="this.search(); ">SEARCH
              </button>
            </div>
            <div class="reference-results" *ngIf="results.length">
              <table >
                <tr>
                  <th>Reference Number</th>
                  <th>Make</th>
                  <th>Model</th>
                </tr>
                <tr  *ngFor="let car of results" >
                  <td>
                    <a (click)="navigate(car.id, car.make); ">{{car.companyOldId}} - {{car.oldId}}</a>
                  </td>
                  <td>{{car.make}}</td>
                  <td>{{car.masterModel}}</td>
                </tr>
              </table>
            </div>
            <div *ngIf="resultEmpty" class="no-results">
              <h5>No Results Found</h5>
            </div>
            <ng-template pTemplate="footer">
              <p-button icon="pi pi-check" (click)="showDialog" label="Ok" styleClass="p-button-text" style="color: black"></p-button>
            </ng-template>
          </p-dialog>
          <a class="nav-item nav-link"
             [routerLink]="['/sell-car']">Sell My Car</a>
          <a class="nav-item nav-link"
             [routerLink]="['/find-dealership']">Find Dealership</a>
          <a class="nav-item nav-link"
             [routerLink]="['/services']">Dealership Services</a>
        </div>
        <div class="col-md-3 order-md-2 footer-contact-block">
          <h4 class="footer-heading">The CarPlace</h4>
          <a class="nav-item nav-link"
             [routerLink]="['/what-is-the-car-place']">What is the CarPlace?</a>
          <a class="nav-item nav-link"
             [routerLink]="['/dealer-faqs']">Dealer Faq's</a>
          <a class="nav-item nav-link"
             [routerLink]="['/dealer-sign-up']">Dealer Sign Up</a>
          <a class="nav-item nav-link"
             [routerLink]="['/terms-and-conditions']">TheCarPlace T&C's</a>
          <a class="nav-item nav-link"
             [routerLink]="['/privacy-policy']">Privacy Policy</a>
          <a class="nav-item nav-link"
             [routerLink]="['/news-and-advice']">News And Advice</a>
        </div>
        <div class="col-md-3 order-md-3 footer-contact-block">
          <h4 class="footer-heading">Capitec Finance Channel </h4>
          <a class="nav-item nav-link"
             [routerLink]="['/capitec-faqs']">Capitec FAQs</a>
          <a class="nav-item nav-link"
             [routerLink]="['/what-is-capitec-finance-channel']">What is Capitec Finance Channel?</a>
          <a class="nav-item nav-link"
             [routerLink]="['/rules-of-the-game']">Rules of the game</a>
          <a class="nav-item nav-link"
             [routerLink]="['/capitec-finance-channel-terms-and-conditions']">Capitec Finance Channel T&C's</a>
          <a class="nav-item nav-link"
             [routerLink]="['/what-is-vmg-webmaster']">What is VMG Webmaster?</a>
        </div>
        <div class="col-md-3 order-md-4">
          <h4 class="footer-heading">Stay In Touch</h4>
          <p style="color: #ffffff; margin: 0">SIGN UP NOW TO STAY UPDATED WITH OUR LATEST ARTICLES, VIDEOS & NEWS:</p>
          <div class = "car-enquire">
            <div class = "mailsent"
                 *ngIf = "mailSent">Your request has been sent!
            </div>
              <form [formGroup] = "newsLetter"
                    *ngIf = "newsLetterHide"
                    (ngSubmit) = "onSubmit()">

                <div class = "form-group">
            <span *ngIf = "newsLetter.controls['email'].invalid &&
        (newsLetter.controls['email'].dirty || newsLetter.controls['email'].touched || newsLetter.controls['email'].errors )"
                  class = "required">*</span>
                  <input type = "email"
                         formControlName = "email"
                         placeholder = "Email"
                         class = "form-control">
                </div>
                <div class="form-group">
                  <button *ngIf="btnShow"
                          class="submit-button"
                          [disabled] = "!newsLetter.valid">Sign Up
                  </button>
                </div>
              </form>
          </div>

          <h4 class="footer-heading" style="margin: 0">Socials</h4>
          <div class="social">
            <a href="{{store.faceBook}}" target="_blank" rel="noopener">
            <span style="display: none">facebook</span>
            <fa-icon [icon]="fbIcon"></fa-icon>
          </a>
            <a href="{{store.instaGram}}" target="_blank">
              <fa-icon [icon]="igIcon"></fa-icon>
            </a>
            <a href="{{store.youTube}}" target="_blank">
              <fa-icon [icon]="ytIcon"></fa-icon>
            </a>
            <a href="{{store.twitter}}" target="_blank">
              <fa-icon [icon]="twIcon"></fa-icon>
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div>The Car Place © <span id="date">{{footerYear}}</span>.
    </div>
    <div>Powered by <a href="https://vmgsoftware.co.za" target="_blank" rel="noopener">VMG Software</a>.</div>
  </div>

  <!-- Modal -->


</section>
