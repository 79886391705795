import { Injectable } from "@angular/core";
@Injectable()
export class Brochure {
  id: string;
  branchId: string;
  oldId: string;
  branchOldId: string;
  companyOldId: number;
  tradingName: string;
  images: [
    { imageUrl: string }
  ];
}
