<section class="page-container news-article">
  <div class="home-body-container">
    <div class="container">
      <div class="row" *ngIf="(article !== undefined) && (article !== null)">
        <div class="col-md-12">
          <div class="block article-header" >
            <h1>{{article.heading}}</h1>
            <span>Published: {{article.dateCreated}}</span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="block article-body" [innerHTML]="this.safeHtml" >
          </div>
        </div>
        <div class="col-md-4">
          <div class="color-block text-center">
            <h4>Share This Content:</h4>
            <div class="social-shares">
              <div class="row m-0">
                <div class="col-4">
                  <a [href]="facebookLink" target="_blank"><i class="pi pi-facebook"></i></a>
                </div>
                <div class="col-4">
                  <a [href]="twitterLink" target="_blank"><i class="pi pi-twitter"></i></a>
                </div>
                <div class="col-4">
                  <a [href]="whatsAppLink" target="_blank"><i class="pi pi-whatsapp"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="color-block text-center">
            <h4>Quick Links:</h4>
            <a [routerLink]="['/what-is-the-car-place']">
              <p> What is Thecarplace?</p>
            </a>
            <a [routerLink]="['/showroom']">
              <p> General Showroom</p>
            </a>
            <a [routerLink]="['/capitec-finance']">
              <p>Capitec Finance Showroom</p>
            </a>
            <a [routerLink]="['/what-is-capitec-finance-channel']">
              <p> What is capitec showroom</p>
            </a>
            <a [routerLink]="['/sell-car']">
              <p> Sell my car</p>
            </a>
            <a [routerLink]="['/find-dealership']">
              <p>find Dealership</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
