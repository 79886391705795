<section class="page-container">
  <div class="home-body-container">
  <div class="container dealer-registration">
    <div class="col-sm-12">
      <h1>WELCOME TO THE CAPITEC FINANCE CHANNEL</h1>
      <h2>DEALER REGISTRATION</h2>
      <p>We are excited and privileged to try help you move some more metal via the Capitec Finance Channel.</p>
      <p>Advertising on the platform is free of charge for any qualifying dealership. No catches, no funny business.</p>
      <p>There some strict “rules of the Game” so please be sure to read the fine print before signing up.</p>
      <p>If your dealership qualifies, and you want in, then please complete the form below, note that we’ll need some documents from you, and then click the Send Button.
      </p>
      <div class="mailsent"
           *ngIf="mailSent">Your request has been sent!
      </div>
      <form [formGroup]="dealershipRegistrationForm"
            *ngIf="dealershipRegistrationFormHide"
            (ngSubmit)="onSubmit()">
        <h2 class="form-heading">Company Details</h2>
        <div class="row">
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['registeredName'].invalid &&
       (dealershipRegistrationForm.controls['registeredName'].dirty || dealershipRegistrationForm.controls['registeredName'].touched || dealershipRegistrationForm.controls['registeredName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="registeredName"
                   placeholder="Registered Name"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['tradingName'].invalid &&
       (dealershipRegistrationForm.controls['tradingName'].dirty || dealershipRegistrationForm.controls['tradingName'].touched || dealershipRegistrationForm.controls['tradingName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="tradingName"
                   placeholder="Trading Name"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['companyVATNumber'].invalid &&
       (dealershipRegistrationForm.controls['companyVATNumber'].dirty || dealershipRegistrationForm.controls['companyVATNumber'].touched || dealershipRegistrationForm.controls['companyVATNumber'].errors )"
                  class="required">*
            </span>
            <input type="number"
                   formControlName="companyVATNumber"
                   placeholder="Company VAT Number"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['companyRegNumber'].invalid &&
       (dealershipRegistrationForm.controls['companyRegNumber'].dirty || dealershipRegistrationForm.controls['companyRegNumber'].touched || dealershipRegistrationForm.controls['companyRegNumber'].errors )"
                  class="required">*
            </span>
            <input type="number"
                   formControlName="companyRegNumber"
                   placeholder="Company Reg Number"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['companyContactNumber'].invalid &&
       (dealershipRegistrationForm.controls['companyContactNumber'].dirty || dealershipRegistrationForm.controls['companyContactNumber'].touched || dealershipRegistrationForm.controls['companyContactNumber'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="companyContactNumber"
                   placeholder="Company Contact Number"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['primaryShowroomAddress1'].invalid &&
       (dealershipRegistrationForm.controls['primaryShowroomAddress1'].dirty || dealershipRegistrationForm.controls['primaryShowroomAddress1'].touched || dealershipRegistrationForm.controls['primaryShowroomAddress1'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="primaryShowroomAddress1"
                   placeholder="Primary Showroom Address 1"
                   class="form-control">
          </div>

          <div class="form-group col-sm-4">
            <input type="text"
                   formControlName="primaryShowroomAddress2"
                   placeholder="Primary Showroom Address 2"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['suburb'].invalid &&
       (dealershipRegistrationForm.controls['suburb'].dirty || dealershipRegistrationForm.controls['suburb'].touched || dealershipRegistrationForm.controls['suburb'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="suburb"
                   placeholder="Suburb"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['city'].invalid &&
       (dealershipRegistrationForm.controls['city'].dirty || dealershipRegistrationForm.controls['city'].touched || dealershipRegistrationForm.controls['city'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="city"
                   placeholder="City"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['province'].invalid &&
       (dealershipRegistrationForm.controls['province'].dirty || dealershipRegistrationForm.controls['province'].touched || dealershipRegistrationForm.controls['province'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="province"
                   placeholder="Province"
                   class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <span *ngIf="dealershipRegistrationForm.controls['postCode'].invalid &&
       (dealershipRegistrationForm.controls['postCode'].dirty || dealershipRegistrationForm.controls['postCode'].touched || dealershipRegistrationForm.controls['postCode'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="postCode"
                   placeholder="Post Code"
                   class="form-control">
          </div>


        </div>
        <hr>
        <h2 class="form-heading">Owners Contact Details</h2>
        <h5>Details are for record keeping, service and administrative purposes only and full compliance to POPI is adhered to at all times.
          See our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        </h5>
        <div class="row">
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['ownersName'].invalid &&
       (dealershipRegistrationForm.controls['ownersName'].dirty || dealershipRegistrationForm.controls['ownersName'].touched || dealershipRegistrationForm.controls['ownersName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="ownersName"
                   placeholder="Owners Name"
                   class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['ownersLastName'].invalid &&
       (dealershipRegistrationForm.controls['ownersLastName'].dirty || dealershipRegistrationForm.controls['ownersLastName'].touched || dealershipRegistrationForm.controls['ownersLastName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="ownersLastName"
                   placeholder="Owners Surname"
                   class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['ownersEmailAddress'].invalid &&
        (dealershipRegistrationForm.controls['ownersEmailAddress'].dirty || dealershipRegistrationForm.controls['ownersEmailAddress'].touched || dealershipRegistrationForm.controls['ownersEmailAddress'].errors )"
                  class="required">*
            </span>
            <input type="email"
                   formControlName="ownersEmailAddress"
                   placeholder="Owner Email Address"
                   class="form-control">
          </div>
          <div class="form-group  col-sm-6 " >
            <span *ngIf="dealershipRegistrationForm.controls['ownersCellNumber'].invalid &&
        (dealershipRegistrationForm.controls['ownersCellNumber'].dirty || dealershipRegistrationForm.controls['ownersCellNumber'].touched || dealershipRegistrationForm.controls['ownersCellNumber'].errors )"
                  class="required">*
            </span>
            <input type="tel"
                   formControlName="ownersCellNumber"
                   placeholder="Owner Cell Number"
                   class="form-control"
                   pattern="[0-9]*">
          </div>
        </div>
        <hr>
        <h2 class="form-heading">Primary Uploader Details</h2>
        <h5>Primary Webmaster Platform user responsible for uploading vehicles Contact Details</h5>
        <label style="position: relative;padding: 5px;">
              <span style="font-size: 16px; margin-right: 5px"> Same as owner?
              </span>
          <p-checkbox type="checkbox"
                      (onChange)="autoPopulate($event)"></p-checkbox>
        </label>
        <div class="row">
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['uploaderName'].invalid &&
       (dealershipRegistrationForm.controls['uploaderName'].dirty || dealershipRegistrationForm.controls['uploaderName'].touched || dealershipRegistrationForm.controls['uploaderName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="uploaderName"
                   placeholder="Uploader Name"
                   class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['uploaderLastName'].invalid &&
       (dealershipRegistrationForm.controls['uploaderLastName'].dirty || dealershipRegistrationForm.controls['uploaderLastName'].touched || dealershipRegistrationForm.controls['uploaderLastName'].errors )"
                  class="required">*
            </span>
            <input type="text"
                   formControlName="uploaderLastName"
                   placeholder="Uploader Surname"
                   class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <span *ngIf="dealershipRegistrationForm.controls['uploaderEmailAddress'].invalid &&
        (dealershipRegistrationForm.controls['uploaderEmailAddress'].dirty || dealershipRegistrationForm.controls['uploaderEmailAddress'].touched || dealershipRegistrationForm.controls['uploaderEmailAddress'].errors )"
                  class="required">*
            </span>
            <input type="email"
                   formControlName="uploaderEmailAddress"
                   placeholder="Uploader Email Address"
                   class="form-control">
          </div>
          <div class="form-group  col-sm-6 " >
            <span *ngIf="dealershipRegistrationForm.controls['uploaderCellNumber'].invalid &&
        (dealershipRegistrationForm.controls['uploaderCellNumber'].dirty || dealershipRegistrationForm.controls['uploaderCellNumber'].touched || dealershipRegistrationForm.controls['uploaderCellNumber'].errors )"
                  class="required">*
            </span>
            <input type="tel"
                   formControlName="uploaderCellNumber"
                   placeholder="Uploader Cell Number"
                   class="form-control"
                   pattern="[0-9]*">
          </div>

        </div>
        <hr>
        <h2 class="form-heading">Qualification Criteria & Document Upload</h2>
        <div class="row">
          <div class="form-group col-sm-12">
            <ul>
              <li>Upload proof of dealer code</li>
              <li>Upload CK2 Doc</li>
              <li>Upload copy of owner ID</li>
            </ul>
          </div>
          <div class="form-group col-sm-12">
            <p-fileUpload name="sellImages[]"
                          [showUploadButton]="false"
                          [showCancelButton]="true"
                          previewWidth="110"
                          fileLimit="10"
                          mode=advanced
                          chooseLabel="Select Files"
                          (onSelect)="fileProgress($event)"
                          (onRemove)="fileRemove($event)"
                          multiple="multiple">
              <ng-template pTemplate="content">
                <div class="dragdrop">
                  <fa-icon style='margin-right:15px; font-size:4rem; vertical-align:middle;'
                           [icon]="cameraIcon"></fa-icon>
                  DRAG / DROP Files
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12 text-center">
            <label class="fb-checkbox-label active" id="checkbox-label" style="position: relative;padding: 5px;">
              <span style="font-size: 16px; margin-right: 5px">By submitting my registration to the Capitec Finance Channel on TheCarPlace.co.za, I hereby confirm that I have read, understand and accept ALL the
                <a [routerLink]="['/capitec-finance-channel-terms-and-conditions']">CAPITEC FINANCE CHANNEL T&C'S</a>
              </span>
              <p-checkbox type="checkbox" name="termsConditions" formControlName="termsConditions"
                          value="I confirm that I have read, understand and agree to the VMG Terms and Conditions"></p-checkbox>
            </label>
          </div>
        </div>

        <div class="form-group center">
          <button class="sell-button"
                  *ngIf="btnShow"
                  [disabled]="!dealershipRegistrationForm.valid">SEND
          </button>
        </div>
      </form>
      <div class="form-spinner"
           *ngIf="spinner">
        <div class="lds-dual-ring loader-container">
          <p-progressSpinner></p-progressSpinner>SENDING
        </div>
      </div>
    </div>
  </div>
</div>
</section>
