import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CarsFilterService } from '../../../../services/cars-filter.service';
import { AllCarData } from '../../../../models/all-car-data';
import { LocalDataService } from '../../../../services/local-data.service';
import { Meta, Title } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-capitec-vehicle',
  templateUrl: './capitec-vehicle.component.html',
  styleUrls: ['./capitec-vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CapitecVehicleComponent implements OnInit {
  public car: AllCarData = new AllCarData();
  public responsiveOptions: any[] = [
    {
      breakpoint: '12100px',
      numVisible: 4
    }
  ];
  public displayCustom: boolean | undefined;
  public activeIndex: number = 0;
  public facebookLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';
  public twitterLink: string = 'http://www.twitter.com/share?url=';
  public whatsAppLink: string = 'https://api.whatsapp.com/send?text='

  public loading: boolean;
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#000000';
  public enquireFormHide = true;
  public display = false;

  public slides = [];
  public carExtras = [];
  public defaultImage = '../../assets/img/nopic.png';
  public backIcon = faAngleDoubleLeft;
  public enquireForm = new UntypedFormGroup({
    referenceNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
  });

  public url = this.router.url;
  public mobileTabs: boolean;

  constructor(
    private carsFilterService: CarsFilterService,
    private routeParams: ActivatedRoute,
    private router: Router,
    public carModel: AllCarData,
    private location: Location,
    public localData: LocalDataService,
    public faLibrary: FaIconLibrary,
    private http: HttpClient,
    private meta: Meta,
    private title: Title,
  ) {
    this.loading = true;
    faLibrary.addIcons(faFacebookF, faWhatsapp, faTwitter);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  public ngOnInit() {
    this.facebookLink += window.location.href;
    this.twitterLink += window.location.href;
    this.whatsAppLink += window.location.href;
    this.routeParams.paramMap.subscribe(params => {
      this.carModel.id = params.get('id');
      this.carModel.make = params.get('make');
      this.carModel.bodyType = params.get('bodyType');
      this.carsFilterService.getCapitecCar(this.carModel).subscribe(http => {
        this.loading = false;
        this.car = http;
        this.fixAbbreviation();
        this.setMetaData();

        // this.convertDistance();
        this.convertToObject();
        this.setMetaData();

      });
      this.mobileCheckForTabs();
    });
  }

  public imageClick(clickedImage: any) {
    const index = this.slides.findIndex(image => image === clickedImage);
    this.activeIndex = index;
    this.displayCustom = true;
  }

  public convertToObject() {
    this.slides = [];
    const imagesObject = JSON.parse(this.car.images);
    imagesObject.forEach(item => {
      this.slides.push(item.image_url);
    });

    if (this.car.extras) {
      const extrasObject = JSON.parse(this.car.extras);
      extrasObject.forEach(item => {
        this.carExtras.push(item.name);
      });
    }
  }

  private fixAbbreviation(): void {
    if (this.car.stockInformationFuelType === 'D') {
      this.car.stockInformationFuelType = 'Diesel';
    }
    if (this.car.stockInformationFuelType === 'P') {
      this.car.stockInformationFuelType = 'Petrol';
    }
    if (this.car.stockInformationFuelType === 'E') {
      this.car.stockInformationFuelType = 'Electric';
    }
    if (this.car.stockInformationTransmission === 'A') {
      this.car.stockInformationTransmission = 'Automatic';
    }
    if (this.car.stockInformationTransmission === 'M') {
      this.car.stockInformationTransmission = 'Manual';
    }
    if (this.car.stockInformationCooling === 'W') {
      this.car.stockInformationCooling = 'Water';
    }
    if (this.car.stockInformationCooling === 'A') {
      this.car.stockInformationCooling = 'Antifreeze';
    }
  }

  private mobileCheckForTabs(): void {
    if (window.screen.width < 768) {
      this.mobileTabs = true;
    }
  }

  public backToShowroom(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/capitec-finance']);
    }
  }

  public scrollToTop(): void {
    window.scroll(0, 0);
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: 'exco@vmgsoftware.co.za',
        CcList: '',
        BccList: '',
        Subject: 'The CarPlace Capitec Finance Enquiry',
        Message: '\nCapitec Reference Number: ' + this.enquireForm.value.referenceNumber
          + '\nEmail: ' + this.enquireForm.value.eMail,
        MessageSubject: 'The CarPlace enquiry',
        ToName: this.localData.store.eMail.enquireMailName,
        FromName: this.enquireForm.value.eMail,
        FromEmail: this.enquireForm.value.eMail,
        Attachments: {}
      }
    };

    const dataString = JSON.stringify(this.formDetails);
    this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers}).subscribe(
      (res) => {
        this.enquireForm.reset();
        this.enquireFormHide = false;
        this.btnShow = true;
        this.mailSent = true;
        this.spinner = false;
      },
      (err) => {
        this.btnShow = false;
        this.spinner = false;
      }
    );
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant);
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'description', content: this.car.description  || 'No description available'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: this.car.description  || 'No description available'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:description', content: this.car.description  || 'No description available'},
      {property: 'og:url', content: 'https://thecarplace.co.za' + this.url},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:image', content: this.slides[0]},
      {property: 'og:image:url', content: this.slides[0]},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'twitter:description', content: this.car.description || 'No description available'},
      {name: 'twitter:image', content: this.slides[0]},
      {name: 'twitter:image:url', content: this.slides[0]},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }
}
