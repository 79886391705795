import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CarsFilterService} from '../../../services/cars-filter.service';
import {ShowroomData} from '../../../models/showroom-data';
import {AllCarData} from '../../../models/all-car-data';
import {AllBrochureData} from '../../../models/all-brochure-data';
import {Brochure} from '../../../models/brochure';
import {MenuItem} from "primeng/api";
import {Subscription} from "rxjs";
import {Meta, Title} from "@angular/platform-browser";
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-dealerships',
  templateUrl: './dealerships.component.html',
  styleUrls: ['./dealerships.component.scss']
})
export class DealershipsComponent implements OnInit, OnDestroy {

  public first: number = 0;
  public rows: number = 24;
  public totalRecords: number = 0;
  public car: Array<AllBrochureData> = [];
  public dealerCars: Array<ShowroomData> = [];
  public vehicleSortDesktop: MenuItem[] = [];
  public vehicleSort: string[] = [];
  private mainSubscription: Subscription = new Subscription();

  public loading = true;
  public spinnerColor = '#007b9a';

  public defaultImage = '../../assets/images/nopic.png';

  public mobileSort: boolean;
  public itemsPerPage: number[] = [24, 60];
  public selectedItemPerPage: number = 24;
  public pageConfig = {
    itemsPerPage: 24,
    currentPage: 1,
  };

  constructor(
    public carsFilterService: CarsFilterService,
    private routeParams: ActivatedRoute,
    public carModel: AllCarData,
    public brochureModel: Brochure,
    private meta: Meta,
    private title: Title,
  ) {
  }

  public ngOnInit() {
    this.setMetaData();
    this.vehicleSort = ['LATEST', 'PRICE: LOW TO HIGH', 'PRICE: HIGH TO LOW'];
    this.vehicleSortDesktop = [{label: 'LATEST'}, {label: 'PRICE: LOW TO HIGH'}, {label: 'PRICE: HIGH TO LOW'}];
    this.mainSubscription.add(this.routeParams.paramMap.subscribe(params => {
      this.pageConfig.currentPage = +params.get('page');
      this.carModel.id = params.get('id');
      this.carModel.branchTradingName = params.get('tradingName');
      this.carModel.oldId = params.get('oldId');
      this.carModel.branchId = params.get('branchId');

      this.brochureModel.companyOldId = Number(params.get('companyOldId'));
      this.brochureModel.tradingName = params.get('tradingName');
      this.carsFilterService.selectedCompanyOldId = this.brochureModel.companyOldId;
      this.carsFilterService.selectedTop = 0;

      this.carsFilterService.getStocksByPagination()
        .subscribe(http => {

        this.dealerCars = http['stocks'];
        this.totalRecords = this.dealerCars.length;
        this.loading = false;
      });
      this.mobileCheckForSort();
    }));
  }

  private mobileCheckForSort(): void {
    if (window.screen.width < 768) {
      this.mobileSort = true;
    }
  }

  public pageChange(newPage: PaginatorState): void {
    this.first = newPage.first;
    this.rows = newPage.rows;
    this.scrollToTop();
  }

  public getDisplayedCars(): ShowroomData[] {
    return this.dealerCars.slice(this.first, this.first + this.rows);
  }

  public changeVehiclePerPAge(){
    this.rows =this.selectedItemPerPage;
    this.pageConfig.itemsPerPage = this.selectedItemPerPage;
    this.pageConfig.currentPage = 1;
  }

  private scrollToTop(): void {
    document.querySelector('html').scrollIntoView();
  }

  public sortViewButton(event: any) {
    if (event.target?.textContent){
      this.carsFilterService.sortingVal = event.target.textContent;
    }

    switch (this.carsFilterService.sortingVal) {
      case 'LATEST':
        this.dealerCars.sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
        break;
      case 'PRICE: LOW TO HIGH':
        this.dealerCars.sort((a, b) => a.sellingPrice - b.sellingPrice);
        break;
      case 'PRICE: HIGH TO LOW':
        this.dealerCars.sort((a, b) => b.sellingPrice - a.sellingPrice);
        break;
      default:
        this.dealerCars.sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
    }

    this.scrollToTop();
  }

  public ngOnDestroy() {
    this.carsFilterService.selectedCompanyOldId = 0;
    this.mainSubscription.unsubscribe();
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace | Dealerships' );
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace | Dealerships'},
      {name: 'description', content: 'Find any of the dealerships on The Car Place here.'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: 'Find any of the dealerships on The Car Place here.'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace | Dealerships'},
      {property: 'og:description', content: 'Find any of the dealerships on The Car Place here.'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace | Dealerships'},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace | Dealerships'},
      {name: 'twitter:description', content: 'Find any of the dealerships on The Car Place here.'},
      {name: 'twitter:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }
}
