import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  logo = '../../../assets/images/new-logo.webp';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router
  ) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      // document.getElementById('address-bar').classList.add('scrolled');
      document.getElementById('navigation').classList.add('scrolled');
    } else {
      // document.getElementById('address-bar').classList.remove('scrolled');
      document.getElementById('navigation').classList.remove('scrolled');
    }
  }

}
