import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ShowroomComponent } from './components/pages/showroom/showroom.component';
import { VehicleComponent } from './components/pages/vehicle/vehicle.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { SellCarPageComponent } from './components/pages/sell-car/sell-car.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { RequestCarComponent } from './components/pages/request-car/request-car.component';
import { PoloShowroomComponent } from './components/pages/showroom/polo-showroom/polo-showroom.component';
import { BrochureComponent } from './components/pages/brochure/brochure.component';
import { DealershipsComponent } from './components/pages/dealerships/dealerships.component';
import { HiluxShowroomComponent } from './components/pages/showroom/hilux-showroom/hilux-showroom.component';
import { RangerShowroomComponent } from './components/pages/showroom/ranger-showroom/ranger-showroom.component';
import { CClassShowroomComponent } from './components/pages/showroom/c-class-showroom/c-class-showroom.component';
import { ThreeSeriesShowroomComponent } from './components/pages/showroom/three-series-showroom/three-series-showroom.component';
import { CapitecVehicleComponent } from './components/pages/capitec/capitec-vehicle/capitec-vehicle.component';
import { CapitecShowroomComponent } from './components/pages/capitec/capitec-showroom/capitec-showroom.component';
import { DealerRegistrationComponent } from './components/pages/dealer-registration/dealer-registration.component';
import { CapitecTermsConditionsComponent } from './components/pages/capitec/capitec-terms-conditions/capitec-terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { DealerFaqComponent } from './components/pages/faq/dealer-faq/dealer-faq.component';
import { DealerSignUpComponent } from './components/pages/dealer-sign-up/dealer-sign-up.component';
import { WhatIsVmgWebmasterComponent } from './components/pages/faq/what-is-vmg-webmaster/what-is-vmg-webmaster.component';
import { DealerCriteriaComponent } from './components/pages/faq/dealer-criteria/dealer-criteria.component';
import { WhatIsCapitecComponent } from './components/pages/faq/what-is-capitec/what-is-capitec.component';
import { WelcomePageComponent } from './components/pages/faq/welcome-page/welcome-page.component';
import { CapitecFaqComponent } from './components/pages/faq/capitec-faq/capitec-faq.component';
import { FindDealershipsComponent } from './components/pages/find-dealerships/find-dealerships.component';
import { NewsAdviceComponent } from './components/pages/news-advice/news-advice.component';
import {NewsAdviceArticleComponent} from './components/pages/news-advice/news-advice-article/news-advice-article.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {TransunionComponent} from './components/pages/transunion/transunion.component';
import {AuthGuard} from './auth.guard';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'showroom', component: ShowroomComponent },
  { path: 'volkswagen/polo', component: PoloShowroomComponent },
  { path: 'toyota/hilux', component: HiluxShowroomComponent },
  { path: 'ford/ranger', component: RangerShowroomComponent },
  { path: 'mercedes-benz/c-class', component: CClassShowroomComponent },
  { path: 'bmw/3-series', component: ThreeSeriesShowroomComponent },
  { path: 'vehicle/:id/:make', component: VehicleComponent },
  { path: 'capitec-finance', component: CapitecShowroomComponent },
  { path: 'capitec-finance/vehicle/:id/:make', component: CapitecVehicleComponent },
  { path: 'sell-car', component: SellCarPageComponent },
  { path: 'terms-and-conditions', component: TermsConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'capitec-finance-channel-terms-and-conditions', component: CapitecTermsConditionsComponent },
  { path: 'what-is-the-car-place', component: FaqComponent },
  { path: 'what-is-vmg-webmaster', component: WhatIsVmgWebmasterComponent },
  { path: 'what-is-capitec-finance-channel', component: WhatIsCapitecComponent },
  { path: 'welcome-vmg-software', component: WelcomePageComponent },
  { path: 'rules-of-the-game', component: DealerCriteriaComponent },
  { path: 'dealer-faqs', component: DealerFaqComponent },
  { path: 'capitec-faqs', component: CapitecFaqComponent },
  { path: 'request-car', component: RequestCarComponent },
  { path: 'dealer-registration', component: DealerRegistrationComponent },
  { path: 'dealer-sign-up', component: DealerSignUpComponent },
  { path: 'find-dealership', component: FindDealershipsComponent },
  { path: 'brochure/:branchOldId/:oldId', component: BrochureComponent },
  { path: 'news-and-advice', component: NewsAdviceComponent },
  { path: 'news-and-advice/:heading', component: NewsAdviceArticleComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'transunion', component: TransunionComponent },
  { path: '404', component: NotFoundComponent },
  { path: ':tradingName/:companyOldId', component: DealershipsComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes,
      {
        paramsInheritanceStrategy: 'always',
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
