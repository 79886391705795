<section class="page-container">
  <div class="container transunion-sign-up">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 >Welcome to the TransUnion Service.
        </h1>
        <h2>Proudly presented to you by VMG Software, theCarPlace and TransUnion.</h2>
        <p>To subscribe to the upgraded TransUnion services, please complete the 5 steps by filling in the details and
          clicking the Next button below.
        </p>
        <p>Your information will enable us to prepare your TransUnion agreement for you and once submitted, a member of
          the VMG or TheCarPlace team will be in touch with you to complete the paperwork process.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 nopadleft nopadright" id="steps">
        <p-steps  [(activeIndex)]="activeIndex" [model]="stepTitles" [readonly]="true" class="mb-3"></p-steps>
        <div class="row">
          <div class="col-sm-12 nopadleft nopadright">
            <form *ngIf="this.activeIndex === 0" #step1form="ngForm">
              <h3>Please Fill In Your Company Details</h3>
              <p style="margin-bottom: 20px" class="small">* All fields are required</p>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <label for="dealerTradingName"><span class="required">*</span> Company Trading Name</label><br/>
                  <p-autoComplete [(ngModel)]="dealerTradingName"
                                  [dropdown]="true"
                                  field=""
                                  [suggestions]="filteredNames"
                                  (completeMethod)="dealerNameFilter($event, 'dealerTradingName')"
                                  id="dealerTradingName"
                                  name="dealerTradingName"
                                  (ngModelChange)="compChange($event)"
                                  required
                                  [ngClass]="{'invalid-field': disabledClickForm1 &&
                                  step1form.controls.dealerTradingName.invalid}" >
                    <ng-template let-dealer pTemplate="item">
                      <div class="flex align-items-center">
                        <div>{{ dealer.tradingName }}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>

                  <div *ngIf="step1form?.controls.dealerTradingName?.touched &&
                  step1form?.controls.dealerTradingName?.errors?.required" class="error-message" >
                    This field is required.
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label for="dealerCompanyName"><span class="required">*</span> Company Registered Name</label><br/>
                  <p-autoComplete [(ngModel)]="dealerCompanyName"
                                  [dropdown]="true"
                                  field=""
                                  [suggestions]="filteredNames"
                                  (completeMethod)="dealerNameFilter($event, 'dealerCompanyName')"
                                  id="dealerCompanyName"
                                  name="dealerCompanyName"
                                  (ngModelChange)="compChange($event)"
                                  required
                                  [ngClass]="{'invalid-field': disabledClickForm1 &&
                                  step1form.controls.dealerCompanyName.invalid}" >
                    <ng-template let-dealer pTemplate="item">
                      <div class="flex align-items-center">
                        <div>{{ dealer.companyName }}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>

                  <div *ngIf="step1form?.controls.dealerCompanyName?.touched &&
                  step1form?.controls.dealerCompanyName?.errors?.required" class="error-message" >
                    This field is required.
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerRegNumber"><span class="required">*</span> Company Registration Number</label><br/>
                  <input pInputText id="dealerRegNumber" class="form-control" name="dealerRegNumber"
                         [(ngModel)]="dealerRegNumber" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                          step1form.controls.dealerRegNumber.invalid}">
                  <div *ngIf="step1form?.controls.dealerRegNumber?.invalid &&
                  (step1form?.controls.dealerRegNumber?.touched ||
                  step1form?.controls.dealerRegNumber?.dirty)" class="error-message" >
                    <div *ngIf="step1form?.controls.dealerRegNumber?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealerRegNumber?.errors?.minlength">
                      12 characters are required
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerVATNumber"><span class="required">*</span> Company VAT number</label><br/>
                  <input id="dealerVATNumber" class="form-control" name="dealerVATNumber"  minlength="10" maxlength="10"
                         [(ngModel)]="dealerVATNumber" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealerVATNumber.invalid}">
                  <div *ngIf="step1form?.controls.dealerVATNumber?.invalid &&
                  (step1form?.controls.dealerVATNumber?.touched || step1form?.controls.dealerVATNumber?.dirty)"
                       class="error-message" >
                    <div *ngIf="step1form?.controls.dealerVATNumber?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealerVATNumber?.errors?.minlength">
                      10 characters are required
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerTelNumber"><span class="required">*</span> Company Telephone number</label><br/>
                  <input id="dealerTelNumber" class="form-control" name="dealerTelNumber"  minlength="10" maxlength="10"
                         pattern="[0-9]*" [(ngModel)]="dealerTelNumber" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealerTelNumber.invalid}">
                  <div *ngIf="step1form?.controls.dealerTelNumber?.invalid &&
                  (step1form?.controls.dealerTelNumber?.touched || step1form?.controls.dealerTelNumber?.dirty)"
                       class="error-message" >
                    <div *ngIf="step1form?.controls.dealerTelNumber?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealerTelNumber?.errors?.minlength ||
                    step1form?.controls.dealerTelNumber?.errors?.pattern">
                      10 Digits are required
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealercontactOneEmailAddress"><span class="required">*</span> Company Email</label><br/>
                  <input type="email" id="dealercontactOneEmailAddress" class="form-control"
                         name="dealercontactOneEmailAddress" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                         [(ngModel)]="dealercontactOneEmailAddress" required email
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealercontactOneEmailAddress.invalid}">
                  <div *ngIf="step1form?.controls.dealercontactOneEmailAddress?.invalid &&
                  (step1form?.controls.dealercontactOneEmailAddress?.touched ||
                  step1form?.controls.dealercontactOneEmailAddress?.dirty)" class="error-message" >
                    <div *ngIf="step1form?.controls.dealercontactOneEmailAddress?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealercontactOneEmailAddress?.errors?.pattern">
                      Email format: myname@thecarplace.co.za
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealeraddressLine1"><span class="required">*</span> Company Address</label><br/>
                  <input id="dealeraddressLine1" class="form-control" name="dealeraddressLine1"
                         [(ngModel)]="dealeraddressLine1" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealeraddressLine1.invalid}">
                  <div *ngIf="step1form?.controls.dealeraddressLine1?.touched &&
                  step1form?.controls.dealeraddressLine1?.errors?.required" class="error-message" >
                    This field is required.
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerAuthLevel">
                    <span class="required">*</span>
                    Select Authority Level Authorised</label><br/>
                  <select name="dealerAuthLevel" id="dealerAuthLevel" class="form-control"
                          [(ngModel)]="dealerAuthLevel" required
                          [ngClass]="{'invalid-field': disabledClickForm1 &&
                          step1form.controls.dealerAuthLevel.invalid}">
                    <option value="" disabled selected>Select your option</option>
                    <option value="Person">Person</option>
                    <option value="Owner">Owner</option>
                    <option value="MD">MD</option>
                    <option value="CEO">CEO</option>
                  </select>
                  <div *ngIf="step1form?.controls.dealerAuthLevel?.touched &&
                  step1form?.controls.dealerAuthLevel?.errors?.required" class="error-message" >
                    This field is required.
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerNameSurname"><span class="required">*</span> Name and Surname</label><br/>
                  <input id="dealerNameSurname" class="form-control" name="dealerNameSurname"
                         [(ngModel)]="dealerNameSurname" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealerNameSurname.invalid}">
                  <div *ngIf="step1form?.controls.dealerNameSurname?.touched &&
                  step1form?.controls.dealerNameSurname?.errors?.required" class="error-message" >
                    This field is required.
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerContactNumber"><span class="required">*</span> Contact Number</label><br/>
                  <input id="dealerContactNumber" class="form-control" name="dealerContactNumber" minlength="10"
                         maxlength="10" pattern="[0-9]*" [(ngModel)]="dealerContactNumber" required
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealerContactNumber.invalid}">
                  <div *ngIf="step1form?.controls.dealerContactNumber?.invalid &&
                  (step1form?.controls.dealerContactNumber?.touched ||
                  step1form?.controls.dealerContactNumber?.dirty)" class="error-message" >
                    <div *ngIf="step1form?.controls.dealerContactNumber?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealerContactNumber?.errors?.minlength ||
                    step1form?.controls.dealerContactNumber?.errors?.pattern">
                      10 Digits are required
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <label for="dealerEmailAddress"><span class="required">*</span> Email Address</label><br/>
                  <input id="dealerEmailAddress" class="form-control" name="dealerEmailAddress"
                         pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="dealerEmailAddress"
                         required email
                         [ngClass]="{'invalid-field': disabledClickForm1 &&
                         step1form.controls.dealerEmailAddress.invalid}">
                  <div *ngIf="step1form?.controls.dealerEmailAddress?.invalid &&
                  (step1form?.controls.dealerEmailAddress?.touched ||
                  step1form?.controls.dealerEmailAddress?.dirty)" class="error-message" >
                    <div *ngIf="step1form?.controls.dealerEmailAddress?.errors?.required">
                      This field is required
                    </div>
                    <div *ngIf="step1form?.controls.dealerEmailAddress?.errors?.pattern">
                      Email format: myname@thecarplace.co.za
                    </div>
                  </div>
                </div>
              </div>
              <button class="nextBtn" type="submit" (click)="checkForValidForm('Step1')" >Next</button>
              <span class="spinner-border" *ngIf="spinner"></span>
            </form>
            <form class="formAnimation" *ngIf="this.activeIndex === 1" >
              <h3>Select the products you would like to activate in your VMG DMS.</h3>
              <h5>You are welcome to upgrade or add products at any time.</h5>
              <h6>Packages may be upgraded / downgraded by emailing
                <a style="color: #007b9a" href="mailto:transunion@thecarplace.co.za">transunion@thecarplace.co.za</a> or
                <a style="color: #007b9a" href="mailto:transunion@vmgsoftware.co.za">transunion@vmgsoftware.co.za</a> </h6>
              <div class="row mt-3">
                <table class="table form2table">
                  <tr>
                    <th [ngClass]="{'col-sm-4': !screenwidth550}">Product Description</th>
                    <th [ngClass]="{'col-sm-4': !screenwidth550}">Package</th>
                    <th [ngClass]="{'col-sm-2': !screenwidth550}">Pricing(VAT Excl)</th>
                  </tr>
                  <tr *ngFor="let product of transProducts;let i = index">
                    <td style="width: 33.33333%">{{ product.description }} <fa-icon class="prodIcon" [icon]="infoIcon"></fa-icon>
                      <span style="position: relative;width: 400px">
                        <p class="prodPopup" [innerHTML]="product.extras"></p>
                      </span>
                    </td>
                    <td style="width: 33.33333%">
                      <p-dropdown *ngIf="product.package.length > 0" [options]="product.package"
                                  placeholder="Select Package"
                                  optionLabel="transaction" (onChange)="onPackageChange($event, i)" ></p-dropdown>
                      <p *ngIf="product.package.length === 0">{{product.nopackage}}</p>
                      <span *ngIf="product.package.length > 0">
                        <fa-icon class="packIcon" [icon]="infoIcon"></fa-icon>
                        <span style="position: relative;width: 400px">
                        <p class="packPopup" [innerHTML]="product.packageInfo"></p>
                      </span>
                      </span>
                    </td>
                    <td style="width: 33.33333%">{{'R' + ((packagePrices[i] === undefined) ? '0.00 ' : packagePrices[i].toFixed(2)) + ' '
                    + ((i === 0) ? 'per month' : 'per check') }}
                      <span *ngIf="i === 0 && this.selectedTransunionProducts[i].package === '300 +'" style="font-size: 12px"><br/>Additional charges per transaction over 300</span></td>
                  </tr>
                </table>
              </div>
              <button class="nextBtn" type="submit" (click)="checkForValidForm('Step2')" >Next</button>
              <span class="spinner-border" *ngIf="spinner"></span>

              <span style="margin-left:20px" *ngIf="!btnShow" class="small text-danger">
                * A Package needs to be selected in order to move on.</span>
            </form>
            <form class="formAnimation" *ngIf="this.activeIndex === 2" (ngSubmit)="stepSubmit('Step3')">
              <h3>Terms and Conditions</h3>
              <h5>Click <span style="text-decoration: underline" (click)="this.popupTC = true">here</span>
                to View all the Terms and Conditions</h5>
              <div *ngIf="popupTC" class="tcPopup">
                <h3>TransUnion Terms and Conditions:</h3>
                <ol>
                  <li>TransUnion Products are furnished for the use of the party who has submitted a subscription
                    application to Zoopark Technologies (Pty) Ltd t/a TheCarPlace.co.za for the attention of TransUnion
                    Auto Information Solutions (Pty) Ltd (“TransUnion”), and which party who submitted the subscription
                    is referred to as the “Subscriber”.
                  </li>
                  <li>TransUnion Products subscriptions are subject to the terms and conditions herein, all of which
                    are material and binding upon the Subscriber, and, together with the above clause 2 of Annexure A
                    of the Agreement and Distribution Policy, is referred to as “this Subscription”.
                  </li>
                  <li>TU Products are issued only for the use of the Subscriber, and persons in the bona fide
                    employment of the Subscriber, in the ordinary course of their business as disclosed in the
                    subscription application form, and in accordance with the terms set out herein.
                  </li>
                  <li>TransUnion retains ownership of all data, forming part of the TransUnion Product range,
                    provided by it to Subscriber in terms of this Subscription or otherwise. Subscriber does
                    not acquire any rights in and to such data, save for the right, during this Subscription only, to
                    (a) use the data for lawful purposes related to its internal operations (which shall not include
                    commercialisation of the data).
                  </li>
                  <li>TransUnion is the owner of all intellectual property rights in and to the TransUnion Products,
                    including, without limitation, the following (a) the “Mead and McGrouther” codes, each of which
                    codes corresponds to a description of a vehicle; (b) the description of a vehicle (setting out
                    the make and model of the Vehicle) which corresponds to a Mead and McGrouther Code; and (c) the
                    aggregated trade and/or retail values pertaining to any vehicle generated by TransUnion; (d) the
                    methods, processes and algorithms applied and/or used by TransUnion in determining the Mead and
                    McGrouther Codes, and (e) the corresponding vehicle descriptions and the trade and retail values
                    pertaining to any vehicles; and (f) the format, design and look and feel of the Products (whether
                    in print or electronic format).
                  </li>
                  <li>Subscriber shall not copy, reverse engineer, disassemble, decompile, modify, translate or
                    otherwise decode any portion of the TransUnion Products, nor grant any other person or entity
                    the right to do so. Nor is Subscriber entitled to create any derivative works from the TransUnion Products.
                  </li>
                  <li>This Subscription together with all documents and information that form part of this
                    Subscription shall constitute confidential information. Subscriber shall, during this
                    Subscription or at any time after this Subscription is cancelled or otherwise terminates,
                    use, divulge, disclose, exploit, permit the use of or in any other manner whatsoever use
                    TransUnion’s confidential information or disclose the existence or contents of this Subscription
                    other than as provided or contemplated in this Subscription or to the extent otherwise required by
                    law (in which case Subscriber shall notify TransUnion as promptly as possible
                    (and if possible prior to making any disclosure) and shall endeavour to seek
                    confidential treatment of such confidential information.
                  </li>
                  <li>This Subscription gives rise to a right only to use the information contained in the TransUnion Products,
                    and no person shall acquire any other rights in and to the Digital Dealer Guides (i.e. the book itself).
                    At all times, ownership of the Digital Dealer Guides remains vested with TransUnion.
                  </li>
                  <li>In the event of a failure by the Subscriber to renew an annual subscription or by
                    terminating this service, TransUnion shall have no obligation to supply further access
                    to the Subscriber.</li>
                  <li>TheCarPlace and VMG Software shall adhere to all obligations and responsibilities
                    placed on it in terms of the processing of any Personal Information in accordance with
                    the Protection of Personal Information Act, No 4 of 2013 (“POPIA”), and shall only disclose
                    the subscribers Personal Information as required and provided for herein, and where necessary
                    as required for adherence to applicable law in terms of POPIA.
                  </li>
                  <li>Notwithstanding the diligence and care with which the TransUnion Products
                    have been compiled, Neither TransUnion or TheCarPlace or VMG Software shall be liable for
                    any damages or loss whatsoever (and howsoever arising) suffered by the Subscriber or any other
                    third party as a result of any information contained in or absent from the use of TransUnion Products.
                    For the avoidance of doubt any information released by TransUnion as part of the TransUnion Products
                    shall in no way be construed as an opinion of TransUnion but merely reflects a recording of
                    information received by TransUnion from various sources from time to time. Use of the
                    TransUnion Products requires the Subscriber to use its own skill and judgment.
                    The Subscriber shall be solely liable for all actions and decisions taken in
                    reliance on the information in the TransUnion Products.
                  </li>
                  <li>Should either TheCarPlace or VMG Software or TransUnion be prevented from fulfilling
                    any of its obligations in terms of this Subscription as a result of any Act of God, war,
                    fire, flood, legislation, insurrection, sanctions, trade embargo or any economic or other
                    cause beyond the reasonable control of TheCarPlace or VMG Software or TransUnion
                    ("Force Majeure") then either TheCarPlace or VMG Software or TransUnion (whomever
                    to enact the Force Majeure clause/event) will forthwith give written notice thereof
                    to the Subscriber specifying the cause and anticipated duration of the Force Majeure;
                    and promptly upon termination of the Force Majeure, stating that such Force Majeure has
                    terminated. Performance of any such obligations will be suspended from the date on which
                    notice is given of Force Majeure until the date on which notice is given of termination
                    of Force Majeure ("Suspension Period"). Neither TheCarPlace or VMG Software or TransUnion
                    will be liable for any delay or failure in the performance of any obligation hereunder,
                    or loss or damage due to or resulting from the Force Majeure during the Suspension Period;
                    and if the Force Majeure shall continue for more than 60 (sixty) consecutive days,
                    the Subscriber will be entitled to cancel this Subscription on the expiry of such period,
                    but will not be entitled to claim damages against TheCarPlace or VMG Software or TransUnion
                    as a result of the delay or failure in the performance of any obligations hereunder due to
                    or resulting from the Force Majeure.
                  </li>
                  <li>TransUnion and TheCarPlace and VMG Software, reserve the right to amend,
                    alter or vary the conditions of supply at any time as it, in their sole discretion, may determine.
                  </li>
                  <li>Subscriptions are payable monthly in advance by means of a monthly debit order.
                    All amounts paid in advance are non-refundable.</li>
                  <li>The address of TransUnion is 11 Alice Lane, Sandton, Johannesburg, 2196.
                  </li>
                  <li>The address of TheCarPlace is Ground Floor, Unit 6, Bateleur Office Park,
                    Pasita Street, Rosendal, Cape Town, 7550.</li>
                  <li>The address of VMG Software is  First Floor, Unit 6, Bateleur Office Park,
                    Pasita Street, Rosendal, Cape Town, 7550.</li>
                </ol>
                <div>
                  <button class="nextBtn" (click)="this.popupTC = false">Close</button>
                </div>
              </div>
              <div class="row" style="margin-top: 30px">
                <div class="col-sm-12">
                  <h4>A short Summary of the T&C's</h4>
                  <ul style="list-style: disc">
                    <li>If my usage exceeds the packaged signed up, I agree that I will be billed for
                      the use of the relevant package used for that month.</li>
                    <li>This is a 12-month commitment, thereafter, access will continue on a month by month basis.</li>
                    <li>I agree that should I fail to pay for the service that the service will be stopped
                      until my account is brought up to date.</li>
                    <li>Should I wish to cancel the service during the 12-month contract, I agree that I will
                      be liable to settle the balance of the agreement in full and that TheCarPlace will debit
                      my account for the full amount due.</li>
                    <li>Should I wish to cancel the service, I agree to give 60 days notice.</li>
                    <li>I agree that payment for the service will be processed via debit order.</li>
                    <li>I agree that the debit order will be processed on the 27th of each month or nearest
                      preceding working day.</li>
                    <li>I agree that usage of the service will be calculated between the 19th and 18th of each month.</li>
                  </ul>
                 <div style="margin: 20px 0">
                   <p-checkbox (click)="tcCheck()" [binary]="true" inputId="binary"></p-checkbox>
                   I agree to the Terms and Conditions
                 </div>
                </div>
              </div>
              <button class="nextBtn" type="submit" [disabled]="!form3Btn" >Next</button>
              <span class="spinner-border" *ngIf="spinner"></span>
            </form>
            <form class="formAnimation" *ngIf="this.activeIndex === 3" #step4Form="ngForm">
              <h3>Debit Order Information:</h3>
              <div class="row" >
                <div class="col-sm-12">
                  <div style="margin: 20px 0">
                    <p>Select one of the following options:</p>
                    <p-radioButton name="debit" value="Use same banking details we use for our VMG Subscription"
                                   label="Use same banking details we use for our VMG Subscription"
                                   [(ngModel)]="debitRadioSelect"
                                   (click)="radioSelect()" inputId="opt1" required></p-radioButton><br/>
                    <p-radioButton name="debit"
                                   value="Use different banking details" label="Use different banking details"
                                   [(ngModel)]="debitRadioSelect"
                                   (click)="radioSelect()" inputId="opt2"></p-radioButton>
                  </div>
                  <div class="row" *ngIf="debitOrderFillIn">
                    <div class="col-sm-12">
                      <p>
                        Please Fill in These Fields
                      </p>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitAccountName">Account Name:</label>
                      <input class="form-control" id="debitAccountName" name="debitAccountName"
                             [(ngModel)]="debitAccountName" required
                             [ngClass]="{'invalid-field': disabledClickForm4 &&
                             step4Form.controls.debitAccountName.invalid}"/>
                      <div *ngIf="step4Form.controls.debitAccountName?.invalid &&
                      step4Form.controls.debitAccountName?.touched" class="error-message" >
                        This field is required.
                      </div>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitBankName">Bank Name:</label>
                      <input class="form-control" id="debitBankName" name="debitBankName"
                             [(ngModel)]="debitBankName" required
                             [ngClass]="{'invalid-field': disabledClickForm4 &&
                             step4Form.controls.debitBankName.invalid}"/>
                      <div *ngIf="step4Form?.controls.debitBankName?.invalid &&
                      step4Form?.controls.debitBankName?.touched" class="error-message" >
                        This field is required.
                      </div>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitBranchNameTown">Branch Name and Town:</label>
                      <input class="form-control" id="debitBranchNameTown" name="debitBranchNameTown"
                             [(ngModel)]="debitBranchNameTown" required
                             [ngClass]="{'invalid-field': disabledClickForm4 &&
                             step4Form.controls.debitBranchNameTown.invalid}"/>
                      <div *ngIf="step4Form?.controls.debitBranchNameTown?.invalid &&
                      step4Form?.controls.debitBranchNameTown?.touched" class="error-message" >
                        This field is required.
                      </div>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitBranchNumber">Branch Number:</label>
                      <input class="form-control" id="debitBranchNumber" name="debitBranchNumber"
                             minlength="6" maxlength="6" pattern="[0-9]*" [(ngModel)]="debitBranchNumber" required
                             [ngClass]="{'invalid-field': disabledClickForm4 &&
                             step4Form.controls.debitBranchNumber.invalid}"/>
                      <div *ngIf="step4Form?.controls.debitBranchNumber?.invalid
                      && (step4Form?.controls.debitBranchNumber?.touched ||
                      step4Form?.controls.debitBranchNumber?.dirty)" class="error-message" >
                       <div *ngIf="step4Form?.controls.debitBranchNumber?.errors?.required">
                         This field is required
                       </div>
                        <div *ngIf="step4Form?.controls.debitBranchNumber?.errors?.minlength ||
                        step4Form?.controls.debitBranchNumber?.errors?.pattern">
                          6 Digits are required
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitAccountNumber">Account Number:</label>
                      <input class="form-control" id="debitAccountNumber" name="debitAccountNumber"
                             minlength="10" maxlength="10" pattern="[0-9]*" [(ngModel)]="debitAccountNumber" required
                             [ngClass]="{'invalid-field': disabledClickForm4 &&
                             step4Form.controls.debitAccountNumber.invalid}"/>
                      <div *ngIf="step4Form?.controls.debitAccountNumber?.invalid &&
                      (step4Form?.controls.debitAccountNumber?.touched ||
                      step4Form?.controls.debitAccountNumber?.dirty)" class="error-message" >
                        <div *ngIf="step4Form?.controls.debitAccountNumber?.errors?.required">
                          This field is required
                        </div>
                        <div *ngIf="step4Form?.controls.debitAccountNumber?.errors?.minlength ||
                        step4Form?.controls.debitAccountNumber?.errors?.pattern">
                          10 Digits are required
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label for="debitTypeAccount">Type of Account:</label>
                      <select class="form-control" name="debitTypeAccount"
                              id="debitTypeAccount" [(ngModel)]="debitTypeAccount" required
                              [ngClass]="{'invalid-field': disabledClickForm4 &&
                              step4Form.controls.debitTypeAccount.invalid}">
                        <option value="" disabled selected>Select your option</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Savings">Savings</option>
                        <option value="Transmission">Transmission</option>
                      </select>
                      <div *ngIf="step4Form?.controls.debitTypeAccount?.invalid &&
                       step4Form?.controls.debitTypeAccount?.touched" class="error-message" >
                        This field is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="nextBtn" type="submit" (click)="checkForValidForm('Step4')" >Next</button>
              <span class="spinner-border" *ngIf="spinner"></span>
            </form>
            <div class="formAnimation" *ngIf="this.activeIndex === 4">
              <h2>CONGRATULATIONS</h2>
              <p>Thank you for sending us your TransUnion subscription application. A member of TheCarPlace team will
                get your paperwork ready and email your agreement to you.
              </p>
              <p>Once you have received your agreement, please print, initial, sign the agreement. Once complete,
                please scan your signed agreement and email it to
                <a href="mailto:TransUnion@TheCarplace.co.za">TransUnion@TheCarplace.co.za</a>.</p>
              <p>Once your signed copy has been verified, your team at VMG Software and TheCarPlace will activate
                your Transunion products for you and notify you once they are ready for use in
                your VMG DMS and VMG Mobile.</p>
              <p>Thank you again for your application. We look forward to being of excellent
                service to you and your team.</p>
              <button class="nextBtn" routerLink="/services" >Complete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
