<section class="page-container">
    <div class="container dealer-sign-up bg-white" style="padding: 50px 30px">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 style="margin-bottom: 30px">Welcome to Dealership Services at TheCarPlace</h1>
          <p>TheCarPlace.co.za in proud partnership with VMG Dealership Management Software,
            are working hard to bring leading industry services to your VMG DMS. To avoid any billing confusion,
            TheCarPlace.co.za is managing the administration for any products used in VMG that were not built by VMG.
          </p>
            <p>Below you will find services currently available for activation in your VMG DMS. Kindly note that these services are exclusively available to VMG DMS customers.</p>
            <p>Click on the tiles below to read more on the VMG Software website or, click on the register button to sign up for the service.</p>

        </div>
        <div class="col-md-12">
          <div class="row" style="margin-left: 0;margin-right: 0">
            <h2 class="text-center">Available Services</h2>
            <div class="col-md-12 step-images justify-content-center row m-0">
              <div class="col-xs-6 col-md-2 justify-content-center row m-0" *ngFor="let service of services">
                <span class="text-center" style="color: #007B9A;font-size: 10px">Click the tile below to learn more</span>
                <div class="service-block text-center align-items-center" style="display: flex" (click)="window.location.href = service.learnMoreUrl">
                  <img style="padding: 1px" src="{{service.image}}" alt="step1">
                </div>
                <button class="servicesRegisterBtn" [routerLink]="service.regUrl" >Register Here</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
