import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sell-car',
  templateUrl: './sell-car.component.html',
  styleUrls: ['./sell-car.component.scss']
})
export class SellCarPageComponent implements OnInit {

  constructor(
    private meta: Meta,
    private title: Title
  ) { }

  public ngOnInit() {
    this.setMetaData();
  }
  private setMetaData() {
    this.title.setTitle('Sell Your Car | The Car Place');
    this.meta.addTags([
      {name: 'title', content: 'Sell Your Car | The Car Place'},
      {name: 'description', content: 'The Car Place is able to evaluate your vehicle and offer you a competitive price when you are ready to sell your car. We consider all makes and models. Fill in your details and we will get back to you with a great offer on your vehicle. Sell your car online to The Car Place. '},
      {name: 'keywords', content: 'the car place, sell your car, car value, value of your used car, free vehicle valuation, car retail value, great price for your vehicle, sell my car online, sell car'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {rel: 'canonical', href: 'https://thecarplace.co.za/sell-car'},

      {property: 'og:title', content: 'Sell Your Car | The Car Place'},
      {property: 'og:description', content: 'The Car Place is able to evaluate your vehicle and offer you a competitive price when you are ready to sell your car. We consider all makes and models. Fill in your details and we will get back to you with a great offer on your vehicle. Sell your car online to The Car Place. '},
      {property: 'og:url', content: 'https://thecarplace.co.za/sell-car'},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'Sell Your Car | The Car Place'},
      {name: 'twitter:description', content: 'The Car Place is able to evaluate your vehicle and offer you a competitive price when you are ready to sell your car. We consider all makes and models. Fill in your details and we will get back to you with a great offer on your vehicle. Sell your car online to The Car Place. '},
      {name: 'twitter:domain', content: 'thecarplace.co.za/sell-car'},
    ]);
  }

}
