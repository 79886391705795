<div class = "car-enquire brochure_tab">
  <div class = "mailsent"
       *ngIf = "mailSent">Your request has been sent!
  </div>
  <div class = "container form-container">
    <form [formGroup] = "enquireForm"
          *ngIf = "enquireFormHide"
          (ngSubmit) = "onSubmit()"
          class="mt-2">
      <div class = "row">
        <div class="col-md-12">
          <div class = "form-group">
            <span *ngIf = "enquireForm.controls['name'].invalid &&
        (enquireForm.controls['name'].dirty || enquireForm.controls['name'].touched || enquireForm.controls['name'].errors )"
                  class = "required">*</span>
            <input type = "text"
                   formControlName = "name"
                   placeholder = "Name"
                   class = "form-control">
          </div>
        </div>
        <div class="col-md-12">
          <div class = "form-group">
            <span *ngIf = "enquireForm.controls['contactNumber'].invalid &&
        (enquireForm.controls['contactNumber'].dirty || enquireForm.controls['contactNumber'].touched || enquireForm.controls['contactNumber'].errors )"
                  class = "required">
               *
            </span>
            <input type = "tel"
                   formControlName = "contactNumber"
                   placeholder = "Contact Number"
                   class = "form-control"
                   pattern="[0-9]*">
          </div>
        </div>
      </div>
      <div class = "form-group">
            <span *ngIf = "enquireForm.controls['eMail'].invalid &&
        (enquireForm.controls['eMail'].dirty || enquireForm.controls['eMail'].touched || enquireForm.controls['eMail'].errors )"
                  class = "required">
               *
            </span>
        <input type = "email"
               formControlName = "eMail"
               placeholder = "Email"
               class = "form-control">
      </div>
      <div class = "form-group">
        <button *ngIf = "btnShow"
                [disabled] = "!enquireForm.valid">SEND&nbsp;&nbsp;<fa-icon [icon]="arrowIcon"></fa-icon>
        </button>
      </div>
    </form>
    <div class = "form-spinner"
         *ngIf = "spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</div>

