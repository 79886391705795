import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {faCamera} from '@fortawesome/free-solid-svg-icons';

import {LocalDataService} from '../../../services/local-data.service';

@Component({
  selector: 'app-sell-car-enquiry',
  templateUrl: './sell-car.component.html',
  styleUrls: ['./sell-car.component.scss']
})
export class SellCarComponent implements OnInit {
  public attachments = {};
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#ffffff';
  public sellCarFormHide = true;
  public validForm = false;

  public sellCarForm = new UntypedFormGroup({
    make: new UntypedFormControl('', [Validators.required]),
    masterModel: new UntypedFormControl('', [Validators.required]),
    year: new UntypedFormControl('', [Validators.required, Validators.min(999), Validators.max(9999)]),
    mileage: new UntypedFormControl('', [Validators.required]),
    color: new UntypedFormControl('', [Validators.required]),
    condition: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    contactNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    province: new UntypedFormControl('', [Validators.required]),
    suburb: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('')
  });

  public fileName: string;
  public filePreview: string;
  public cameraIcon = faCamera;

  constructor(
    private http: HttpClient,
    public localData: LocalDataService
  ) { }

  public ngOnInit() {
    this.formListener();
  }

  public formListener(): void {
    this.sellCarForm.valueChanges.subscribe(() => {
      this.validForm = this.sellCarForm.valid;
    });
  }

  public async fileProgress(event) {
    if (event.files && event.files.length > 0) {
      for (const item of event.files) {
        const reader = new FileReader();
        const file = item;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name;
          this.filePreview = 'data:image/jpg' + ';base64,' + reader.result.toString().split(',')[1];
          this.attachments[this.fileName] = this.filePreview;
        };
      }

    }
  }

  public fileRemove(event) {
    const key = event.file.name;
    delete this.attachments[key];
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: this.localData.store.eMail.contactMail,
        CcList: 'info@thecarplace.co.za',
        BccList: 'webmaster@vmgsoftware.co.za',
        Subject: 'I would like to sell my ' + this.sellCarForm.value.make + ' ' + this.sellCarForm.value.masterModel,
        Message: this.sellCarForm.value.message
          + '\n\nMake: ' + this.sellCarForm.value.make
          + '\nModel: ' + this.sellCarForm.value.masterModel
          + '\nYear: ' + this.sellCarForm.value.year
          + '\nMileage: ' + this.sellCarForm.value.mileage
          + '\nColor: ' + this.sellCarForm.value.color
          + '\nCondition: ' + this.sellCarForm.value.condition
          + '\n\nCurrent location: '
          + '\nSuburb: ' + this.sellCarForm.value.suburb
          + '\nProvince: ' + this.sellCarForm.value.province
          + '\nContact me on: ' + this.sellCarForm.value.contactNumber,
        MessageSubject: 'Sell My Car',
        ToName: this.localData.store.eMail.contactMailName,
        FromName: this.sellCarForm.value.name,
        FromEmail: this.sellCarForm.value.eMail,
        Attachments: this.attachments
      }
    };
    const dataString = JSON.stringify(this.formDetails);
    return this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers, reportProgress: true}).subscribe(
      (res) => {
        this.sellCarForm.reset();
        this.sellCarFormHide = false;
        this.btnShow = true;
        this.mailSent = true;
        this.spinner = false;
        document.querySelector('html').scrollIntoView();
      },
      (err) => {
        this.btnShow = false;
        this.spinner = false;
      }
    );
  }

}
