import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {CarsFilterService} from '../../../services/cars-filter.service';
import {QueryParamHelper} from '../../../shared/helpers/query-param-helper';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public capitecCountHasLoaded: boolean = false;
  public autoCompleteCtrl = new UntypedFormControl();
  public filteredVehicles: Observable<string[]>;
  public selectedVehicles: string[] = [];
  public allVehicles: string[] = [];
  public vehicleAutoComplete: number;
  public capitecVehicleAutoComplete: number;
  public viewportHeight: boolean;
  public queryParamHelper: QueryParamHelper;

  public truckImage = '../../assets/images/trucks.webp';
  public convertibleImage = '../../assets/images/convertibles.webp';
  public suvImage = '../../assets/images/suv.webp';
  public hatchImage = '../../assets/images/hatchbacks.webp';
  public sedanImage = '../../assets/images/sedans.webp';
  public wagonImage = '../../assets/images/wagons.webp';
  public bikeImage = '../../assets/images/bikes.webp';
  public coupeImage = '../../assets/images/coupes.webp';

  @ViewChild('vehicleInput') vehicleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private carsFilterService: CarsFilterService,
    private meta: Meta,
    private title: Title
  ) {
    this.filteredVehicles = this.autoCompleteCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allVehicles.slice()));
  }
  public ngOnInit() {
    this.checkViewportHeight();
    this.setMetaData();

    this.carsFilterService.getStocksByPagination().subscribe(res => {
      this.vehicleAutoComplete = res['count'];
    });

    this.queryParamHelper = new QueryParamHelper();
  }

  public getCountOfCapitecShowroom(event: any){
    if (!this.capitecCountHasLoaded && event.target.innerHTML === 'CAPITEC FINANCE SHOWROOM'){
      this.carsFilterService.getStocksByPaginationForCapitec().subscribe(res => {
        this.capitecVehicleAutoComplete  = res['count'];
      });
      this.capitecCountHasLoaded = true;
    }
  }

  public remove(fruit: string): void {
    const index = this.selectedVehicles.indexOf(fruit);
    if (index >= 0) {
      this.selectedVehicles.splice(index, 1);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allVehicles.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  private checkViewportHeight(): void {
    if (window.screen.width < 800) {
      this.viewportHeight = true;
    }
  }

  private setMetaData() {
    this.title.setTitle('The Car Place | Used Cars for Sale');
    this.meta.addTags([
      {name: 'title', content: 'The Car Place | Used Cars for Sale'},
      {
        name: 'description',
        content: 'The Car Place offers the best deals on a wide range of premium quality new and used cars for sale. Browse used cars for sale by a collection of the best car dealerships in South Africa. '
      },
      {
        name: 'keywords',
        content: 'the car place, used cars for sale in western cape, car dealerships, car deals, bakkies, convertibles, coupes, hatchbacks, minivans, sedans, station wagons, suvs, cars for sale'
      },
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {rel: 'canonical', href: 'https://thecarplace.co.za/home'},

      {property: 'og:title', content: 'The Car Place | Used Cars for Sale'},
      {
        property: 'og:description',
        content: 'The Car Place offers the best deals on a wide range of premium quality new and used cars for sale. Browse used cars for sale by a collection of the best car dealerships in South Africa. '
      },
      {property: 'og:url', content: 'https://thecarplace.co.za/home'},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'The Car Place | Used Cars for Sale'},
      {
        name: 'twitter:description',
        content: 'The Car Place offers the best deals on a wide range of premium quality new and used cars for sale. Browse used cars for sale by a collection of the best car dealerships in South Africa. '
      },
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }
}
