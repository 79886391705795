import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { LocalDataService } from '../../../services/local-data.service';
import {AllCarData} from '../../../models/all-car-data';

@Component({
  selector: 'app-brochure-finance-enquiry',
  templateUrl: './brochure-finance-enquiry.component.html',
  styleUrls: ['./brochure-finance-enquiry.component.scss']
})
export class BrochureFinanceEnquiryComponent {
  @Input() car: AllCarData;
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#000000';
  public financeFormHide = true;
  public deposit: number;
  public credit: number;
  public tradeIn: number;
  public newsSpecials: any;
  public financeForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    contactNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    myId: new UntypedFormControl('', [Validators.required, Validators.min(999999999999), Validators.max(9999999999999)]),
    message: new UntypedFormControl(''),
    creditCheck: new UntypedFormControl(''),
    credit: new UntypedFormControl(''),
    deposit: new UntypedFormControl(''),
    depositAmount: new UntypedFormControl(''),
    tradeIn: new UntypedFormControl(''),
    tradeInDetails: new UntypedFormControl(''),
    newsSpecials: new UntypedFormControl('')
  });
  public arrowIcon = faCarSide;

  constructor(
    private http: HttpClient,
    public localData: LocalDataService
  ) {
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: this.car.branchEmailAddress,
        CcList: '',
        BccList: 'leads@thecarplace.co.za, webmaster@vmgsoftware.co.za',
        Subject: 'Website enquiry for ' + this.car.make + ' ' + this.car.masterModel,
        Message: this.financeForm.value.message
          + '\n\nMake: ' + this.car.make
          + '\nModel: ' + this.car.masterModel
          + '\nstockId: ' + this.car.id
          + '\nPrice: ' + this.car.sellingPrice
          + '\nContact Number: ' + this.financeForm.value.contactNumber
          + '\nID Number: ' + this.financeForm.value.myId
          + '\nCredit Check: ' + this.financeForm.value.creditCheck
          + '\nDeposit?: ' + this.financeForm.value.deposit
          + '\nDeposit Value: ' + this.financeForm.value.depositAmount
          + '\nTrade-In?: ' + this.financeForm.value.tradeIn
          + '\nTrade-In Details: ' + this.financeForm.value.tradeInDetails
          + '\nSpecials Sign-up: ' + this.financeForm.value.newsSpecials || 'No',
        MessageSubject: 'Finance Enquiry',
        ToName: this.localData.store.eMail.enquireMailName,
        FromName: this.financeForm.value.name,
        FromEmail: this.financeForm.value.eMail,
        Attachments: {}
      }
    };
    const dataString = JSON.stringify(this.formDetails);
    this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers}).subscribe(
        {
          next: () => {
            this.financeForm.reset();
            this.financeFormHide = false;
            this.btnShow = true;
            this.mailSent = true;
            this.spinner = false;
            document.querySelector('html').scrollIntoView();
          },
          error: () => {
            this.btnShow = false;
            this.spinner = false;
          }
        }
    );
  }
}
