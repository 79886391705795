import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DealershipData } from '../../../models/dealership-data';
import { HttpClient } from '@angular/common/http';
import { LocalDataService } from '../../../services/local-data.service';
import { CarsFilterService } from '../../../services/cars-filter.service';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-dealer-sign-up',
  templateUrl: './dealer-sign-up.component.html',
  styleUrls: ['./dealer-sign-up.component.scss']
})
export class DealerSignUpComponent implements OnInit {
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#ffffff';
  public dealershipSignUpFormHide = true;
  public checked: boolean;

  public dealershipSignUpForm = new UntypedFormGroup({
    tradingName: new UntypedFormControl('', [Validators.required]),
    leadsName: new UntypedFormControl('', [Validators.required]),
    leadsEmail: new UntypedFormControl('', [Validators.required]),
    leadsNumber: new UntypedFormControl('', [Validators.required]),
    uploadName: new UntypedFormControl('', [Validators.required]),
    uploadEmail: new UntypedFormControl('', [Validators.required]),
    uploadNumber: new UntypedFormControl('', [Validators.required]),
    fiName: new UntypedFormControl('', [Validators.required]),
    fiNumber: new UntypedFormControl('', [Validators.required]),
    fiEmail: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    termsConditions: new UntypedFormControl('', [Validators.required]),
  });

  public allDealerships: DealershipData[] = [];

  constructor(
    private http: HttpClient,
    public localData: LocalDataService,
    public carsFilterService: CarsFilterService,
    private meta: Meta,
    private title: Title,
  ) { }

  public ngOnInit() {
    this.setMetaData();
    this.carsFilterService.getDealershipDetails().subscribe(http => {
      this.allDealerships = http['stocks'];
      this.allDealerships = this.allDealerships.filter((item: any) => item.tradingName !== '');
    });
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: 'customercare@thecarplace.co.za',
        CcList: 'info@thecarplace.co.za',
        BccList: 'webmaster@vmgsoftware.co.za',
        Subject: 'Dealership Sign Up',
        Message: 'Dealership Name: ' + this.dealershipSignUpForm.value.tradingName
          + '\n\nWhere must the leads go?'
          + '\nName: ' + this.dealershipSignUpForm.value.leadsName
          + '\nEmail: ' + this.dealershipSignUpForm.value.leadsEmail
          + '\nCell Number: ' + this.dealershipSignUpForm.value.leadsNumber
          + '\n\nWho uploads cars?'
          + '\nName: ' + this.dealershipSignUpForm.value.uploadName
          + '\nEmail: ' + this.dealershipSignUpForm.value.uploadEmail
          + '\nCell Number: ' + this.dealershipSignUpForm.value.uploadNumber
          + '\n\nWho is your F&I?'
          + '\nName: ' + this.dealershipSignUpForm.value.fiName
          + '\nEmail: ' + this.dealershipSignUpForm.value.fiEmail
          + '\nCell Number: ' + this.dealershipSignUpForm.value.fiNumber
          + '\nI ' + this.dealershipSignUpForm.value.name + ', confirm that I am duly authorised to sign up to TheCarPlace and ' +
          'Capitec Finance Channel and I super promise that I have read, understand, and very gladly accept the wonderful Terms and Conditions' +
          'to advertise my vehicles on TheCarPlace.co.za and The Capitec Finance Channel Showrooms.',
        MessageSubject: 'Dealership Sign Up',
        ToName: this.localData.store.eMail.contactMailName,
        FromName: this.dealershipSignUpForm.value.tradingName,
        FromEmail: this.dealershipSignUpForm.value.tradingName,
        Attachments: {}
      }
    };
    const dataString = JSON.stringify(this.formDetails);
    return this.http.post(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers, reportProgress: true}).subscribe(
        {
          next: () => {
            this.dealershipSignUpForm.reset();
            this.btnShow = true;
            this.mailSent = true;
            this.spinner = false;
            document.querySelector('html').scrollIntoView();
          },
          error: () => {
            this.btnShow = false;
            this.spinner = false;
          }
        }
    );
  }

  public populateDealerDetails(event: any) {
    if (event.value) {
      this.dealershipSignUpForm.patchValue({
        tradingName: event.value[0].tradingName
      });
    }
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace | Dealer Sign Up' );
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace | Dealer Sign Up'},
      {name: 'description', content: 'Welcome to TheCarPlace.co.za. Free advertising to tons of leads… No catches, no nonsense, just simple win-win. Happy days!' +
        'Registering to advertise on TheCarPlace.co.za and the Capitec Finance Channel showroom pages couldn’t be easier for a valued VMG DMS Customer.'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: 'Welcome to TheCarPlace.co.za. Free advertising to tons of leads… No catches, no nonsense, just simple win-win. Happy days!' +
          'Registering to advertise on TheCarPlace.co.za and the Capitec Finance Channel showroom pages couldn’t be easier for a valued VMG DMS Customer.'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace | Dealer Sign Up'},
      {property: 'og:description', content: 'Welcome to TheCarPlace.co.za. Free advertising to tons of leads… No catches, no nonsense, just simple win-win. Happy days!' +
          'Registering to advertise on TheCarPlace.co.za and the Capitec Finance Channel showroom pages couldn’t be easier for a valued VMG DMS Customer.'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace | Dealer Sign Up'},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace | Dealer Sign Up'},
      {name: 'twitter:description', content: 'Welcome to TheCarPlace.co.za. Free advertising to tons of leads… No catches, no nonsense, just simple win-win. Happy days!' +
          'Registering to advertise on TheCarPlace.co.za and the Capitec Finance Channel showroom pages couldn’t be easier for a valued VMG DMS Customer.'},
      {name: 'twitter:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }
}
