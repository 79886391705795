<section class="page-container">
  <div class="home-body-container">
    <div class="container dealer-sign-up">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>THECARPLACE WELCOMES VMG DEALERS</h1>
          <p>Welcome to TheCarPlace.co.za. Free advertising to tons of leads… No catches, no nonsense, just simple win-win. Happy days!</p>
          <p>Registering to advertise on TheCarPlace.co.za and the Capitec Finance Channel showroom pages couldn’t be easier for a valued VMG DMS Customer.</p>
        </div>
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12 step-images">
              <img src="../../../../assets/images/dealer-signup/SignUp_Step1.webp" alt="step1">
              <img src="../../../../assets/images/dealer-signup/SignUp_Step2.webp" alt="step2">
              <img src="../../../../assets/images/dealer-signup/SignUp_Step3.webp" alt="step3">
              <img src="../../../../assets/images/dealer-signup/SignUp_Step4.webp" alt="step4">
            </div>
          </div>

          <h2 style="margin-top: 25px">1. Select Your Dealership</h2>

        <p-dialog [(visible)]="mailSent" class="email-popup" [modal]="true" [draggable]="false" [resizable]="false">
          <p class="text-center">
            <strong>Wooohooo! Congratulations</strong> on Signing Up to TheCarPlace.co.za and Capitec Finance Channel Showrooms
            and <strong>Thank You</strong> for allowing us the opportunity to be of service to you and your dealership.
            <br>
            Your service ambassador from TheCarPlace.co.za will be in touch via email and we look forward to being of exceptional service to your and your team.
            <br>
            Onwards and Upwards we go. Happy Selling!!!
          </p>
        </p-dialog>

        <form [formGroup]="dealershipSignUpForm"
              *ngIf="dealershipSignUpFormHide"
              (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-12">
              <p>Find your super dealership from the list below:</p>
              <p-multiSelect [options]="allDealerships | orderBy: ''"
                             [virtualScroll]="true"
                             [filter]="true"
                             [scrollHeight]="'62vh'"
                             (onChange)="populateDealerDetails($event)"
                             [selectionLimit]="1"
                             itemSize="8"
                             optionLabel="tradingName"
                             placeholder="Select Dealership"
                             class="multiselect-custom-virtual-scroll series-filter dealer-signup-multiselect">
                <ng-template let-value>
                  <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                    <span>
                      {{val}}
                    </span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
          <br>
          <p style="color: #212529; margin-top: 15px">Can’t find your dealership?
            <a href="mailto:customercare@thecarplace.co.za?subject=Sign Up Enquiry&body=Hello TheCarPlace.co.za, I am unable to find my dealership on TheCarPlace.co.za Sign Up Page.%0D%0A Please Help.">
              Click Here
            </a> and don’t worry, we’ll get it sorted!
          </p>
          <br>
          <br>
          <h2>2. Tell us who is who</h2>

          <div class="row">
            <div class="form-group col-md-3">

              <p>Where must the leads go?</p>
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['leadsName'].invalid &&
       (dealershipSignUpForm.controls['leadsName'].dirty || dealershipSignUpForm.controls['leadsName'].touched || dealershipSignUpForm.controls['leadsName'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="leadsName"
                     placeholder="Full Name"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['leadsEmail'].invalid &&
       (dealershipSignUpForm.controls['leadsEmail'].dirty || dealershipSignUpForm.controls['leadsEmail'].touched || dealershipSignUpForm.controls['leadsEmail'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="leadsEmail"
                     placeholder="Email"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['leadsNumber'].invalid &&
       (dealershipSignUpForm.controls['leadsNumber'].dirty || dealershipSignUpForm.controls['leadsNumber'].touched || dealershipSignUpForm.controls['leadsNumber'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="leadsNumber"
                     placeholder="Cell Number"
                     class="form-control">
            </div>

          </div>
          <div class="row">
            <div class="form-group col-md-3">

              <p>Who uploads cars?</p>
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['uploadName'].invalid &&
       (dealershipSignUpForm.controls['uploadName'].dirty || dealershipSignUpForm.controls['uploadName'].touched || dealershipSignUpForm.controls['uploadName'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="uploadName"
                     placeholder="Full Name"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['uploadEmail'].invalid &&
       (dealershipSignUpForm.controls['uploadEmail'].dirty || dealershipSignUpForm.controls['uploadEmail'].touched || dealershipSignUpForm.controls['uploadEmail'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="uploadEmail"
                     placeholder="Email"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['uploadNumber'].invalid &&
       (dealershipSignUpForm.controls['uploadNumber'].dirty || dealershipSignUpForm.controls['uploadNumber'].touched || dealershipSignUpForm.controls['uploadNumber'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="uploadNumber"
                     placeholder="Cell Number"
                     class="form-control">
            </div>

          </div>
          <div class="row">
            <div class="form-group col-md-3">
              <p>Who is your F&I?</p>
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['fiName'].invalid &&
       (dealershipSignUpForm.controls['fiName'].dirty || dealershipSignUpForm.controls['fiName'].touched || dealershipSignUpForm.controls['fiName'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="fiName"
                     placeholder="Full Name"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['fiEmail'].invalid &&
       (dealershipSignUpForm.controls['fiEmail'].dirty || dealershipSignUpForm.controls['fiEmail'].touched || dealershipSignUpForm.controls['fiEmail'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="fiEmail"
                     placeholder="Email"
                     class="form-control">
            </div>
            <div class="form-group col-md-3">
            <span *ngIf="dealershipSignUpForm.controls['fiNumber'].invalid &&
       (dealershipSignUpForm.controls['fiNumber'].dirty || dealershipSignUpForm.controls['fiNumber'].touched || dealershipSignUpForm.controls['fiNumber'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="fiNumber"
                     placeholder="Cell Number"
                     class="form-control">
            </div>
          </div>


          <h2 style="margin-top: 25px">3. Terms & Conditions</h2>
          <div class="row">
            <div class="col-md-12">
              <div>
                <label class="fb-checkbox-label active" id="checkbox-label" >
                  <span style="font-size: 16px; margin-right: 5px; display: flex; flex-direction: row; flex-wrap: wrap;">I
                    <span *ngIf="dealershipSignUpForm.controls['name'].invalid &&
                              (dealershipSignUpForm.controls['name'].dirty || dealershipSignUpForm.controls['name'].touched || dealershipSignUpForm.controls['name'].errors )"
                          class="required">*
                    </span>
                    <input type="text"
                           formControlName="name"
                           placeholder="Enter Name"
                           class="form-control"
                           style="width: 200px; height: auto; padding: 0 5px; margin: 0 5px;">
                    <span>
                    , confirm that I am duly authorised to sign up to TheCarPlace and Capitec Finance Channel and
                    </span>
                      I super promise that I have read, understand, and very gladly accept the wonderful
                    <a [routerLink]="['/terms-and-conditions']" target="_blank" style="display: contents; margin-left: 5px; margin-right: 5px;"> Terms and Conditions</a>
                    to advertise my vehicles on TheCarPlace.co.za and The Capitec Finance Channel Showrooms.
                  </span>
                </label>
                <div class="agree-button">
                    <img src="../../../../assets/images/dealer-signup/SignUp_Agree.webp" alt="agree-box" >
                    <p-checkbox name="termsConditions" formControlName="termsConditions"
                                value="I confirm that I have read, understand and agree to the VMG Terms and Conditions"></p-checkbox>
                    <p>I happily agree!</p>
                  </div>

              </div>

            </div>
          </div>

          <div class="closing-footer">
            <img src="../../../../assets/images/dealer-signup/SignUp_Lines_Left.webp" alt="lines-left" class="left">
            <div class="form-group center">
              <h2>You are ready!</h2>
              <button  class="signup-button"
                      *ngIf="btnShow"
                      [disabled]="!dealershipSignUpForm.valid">Sign Up
              </button>
              <p>Need Support?
                <br>
                Email <a href="mailto:customercare@thecarplace.co.za">customercare@thecarplace.co.za</a> or call us on <a href="tel:087 152 5002">087 152 5002</a>
              </p>
            </div>
            <img src="../../../../assets/images/dealer-signup/SignUp_Lines_Right.webp" alt="lines-right" class="right">
          </div>
        </form>
        <div class="form-spinner"
             *ngIf="spinner">
          <div class="lds-dual-ring loader-container">
            <p-progressSpinner></p-progressSpinner>SENDING
          </div>
        </div>

      </div>
      </div>
    </div>
  </div>
</section>
