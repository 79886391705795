import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor( private meta: Meta,
               private title: Title) { }

  public ngOnInit() {
    this.setMetaData();
  }
  private setMetaData() {
    this.title.setTitle('The Car Place | What Is TheCarPlace?');
    this.meta.addTags([
      {name: 'title', content: 'The Car Place | What Is TheCarPlace?'},
      {name: 'description',
        content: 'TheCarPlace.co.za is a vehicle buying and selling platform designed for the exclusive benefit of the ' +
          'South African Motoring public. It is a platform built by South Africans for South Africans.'},
      {
        name: 'keywords',
        content: 'the car place, used cars for sale in western cape, car dealerships, car deals, bakkies, ' +
          'convertibles, coupes, hatchbacks, minivans, sedans, station wagons, suvs, cars for sale'
      },
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {rel: 'canonical', href: 'https://thecarplace.co.za/what-is-the-car-place'},

      {property: 'og:title', content: 'The Car Place | What Is TheCarPlace?'},
      {
        property: 'og:description',
        content: 'TheCarPlace.co.za is a vehicle buying and selling platform designed for the exclusive benefit of the ' +
          'South African Motoring public. It is a platform built by South Africans for South Africans.'},
      {property: 'og:url', content: 'https://thecarplace.co.za/what-is-the-car-place'},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/articles/What-Is-TheCarPlace.webp'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/articles/What-Is-TheCarPlace.webp'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:type', content: 'article'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'The Car Place | What Is TheCarPlace?'},
      {
        name: 'twitter:description',
        content: 'TheCarPlace.co.za is a vehicle buying and selling platform designed for the exclusive benefit of the ' +
          'South African Motoring public. It is a platform built by South Africans for South Africans.'
      },
      {name: 'twitter:domain', content: 'thecarplace.co.za/what-is-the-car-place'},
    ]);
  }

}
