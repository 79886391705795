export interface Product {
  description: string;
  extras: string;
  packageInfo: string;
  package: Package[];
  nopackage: string;
  additionalTransActions? :string;
}

export interface Package {
  transaction: string;
  price: number;
}

export const transProducts: Product[] = [
  {description: 'Digital Dealer Guide',
    extras: 'Based on monthly fee subject to a twelve-month subscription.<br/><br/>' +
      'The service will provide access to vehicle trade and retail value estimates.<br/><br/>' +
      'Is subject to specific usage tiers and pricing per tier<br/><br/>',
    packageInfo: 'These are the <strong>Total Evaluations</strong> per month. <br/><br/>' +
      'Should the usage result in a new tier level being reached, then the monthly amount for the new tier will apply for that month.',
    package: [
      { transaction: 'No package', price: 0 },
      { transaction: '0 - 25', price: 322 },
      { transaction: '26 - 50', price: 575},
      { transaction: '51 - 100', price: 862.50},
      { transaction: '101 - 150', price: 1725},
      { transaction: '151 - 200', price: 2300},
      { transaction: '201 - 300', price: 2875},
      { transaction: '300 +', price: 2875}
    ],
    nopackage: 'This product has no packages',
    additionalTransActions: 'Additional Charges Per Transactions over 300'
  },
  {description: 'Verification',
    extras: 'The report provides access to in-depth information about vehicles to prevent the sale,' +
      ' auction, registration, financing and insuring of an invalid or stolen vehicle.',
    packageInfo: 'These are the <strong>Transaction Count</strong> per month',
    package: [
      { transaction: 'No package', price: 0 },
      { transaction: '0 - 100', price: 80.50 },
      { transaction: '101 - 200', price: 69},
      { transaction: '201 - 300', price: 57.50},
      { transaction: '301 - 400', price: 46},
      { transaction: '500 +', price: 34.50}
    ],
    nopackage: 'This product has no packages'
  }
];
