<section class="page-container">
  <div class="body-container">
    <div class="col-xl-9 col-lg-11 col-md-12 center-block less-padding">
      <h1 style="display: none">QUALITY NEW & USED CARS FOR SALE </h1>
      <div class="row">
        <div class="col-md-12 showroom-filter">
          <div class="sidebar-filter row m-0 mb-3"
               *ngIf="!mobileFilter">

            <p-multiSelect [options]="regions"
                           placeholder="Region"
                           virtualScrollItemSize="25"
                           [filter]="true"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('regions', 0)"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedRegionKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedRegions">

              <ng-template let-value pTemplate="selectedItems" >
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedRegionKeeper.length">
                    {{carsFilterService.selectedRegionKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedRegionKeeper.length"> All</span>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                 Region
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px;">{{model.name}}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[0]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>

            <p-multiSelect [options]="makes"
                           placeholder="Makes"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onChange)="onFilterChange($event)"
                           (onPanelShow)="this.populateFilterOnPanelOpen('makes', 2)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedMakeKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedMakes">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <div class="row">
                    <div class="col-md-6">
                      <span *ngIf="carsFilterService.selectedMakeKeeper.length">
                        {{carsFilterService.selectedMakeKeeper.length}} Selected
                      </span>
                      <span *ngIf="!carsFilterService.selectedMakeKeeper.length"> All</span>
                    </div>
                  </div>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                  Makes
                </span>
              </ng-template>
              <ng-template let-car pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px">{{car.name}}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[2]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>

            <p-multiSelect [options]="models"
                           placeholder="Models"
                           [virtualScroll]="true"
                           [filter]="true"
                           virtualScrollItemSize="12"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('masterModels', 3)"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedSeriesKeeper');"
                           class="multiselect-custom-virtual-scroll series-filter col-md-4"
                           [(ngModel)]="selectedModels">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedSeriesKeeper.length">
                    {{carsFilterService.selectedSeriesKeeper.length | unique}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedSeriesKeeper.length"> All</span>

                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                  Models
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px">{{model.name}}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[3]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>
            <p-multiSelect [options]="priceRanges"
                           placeholder="Selling Price"
                           virtualScrollItemSize="25"
                           [filter]="true"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('priceRanges', 1)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedPriceKeeper');"
                           (onChange)="onFilterChange($event)"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedSellingPrice">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedPriceKeeper.length">
                    {{carsFilterService.selectedPriceKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedPriceKeeper.length"> All</span>
                </div>

                <span class="ui-multiselected-empty-token ui-corner-all">
                 Selling Price
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px" *ngIf="model.name !== 'R1500000'">
                  {{ model.name}}
                </div>
                <div style="font-size:14px;float:right;margin-top:4px" *ngIf="model.name === 'R1500000'">
                  R1500000+
                </div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[1]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>
            <p-multiSelect [options]="bodyTypes"
                           placeholder="Body Type"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onChange)="onFilterChange($event)"
                           (onPanelShow)="this.populateFilterOnPanelOpen('customBodyTypes', 4);"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedBodyTypeKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedBodyTypes">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedBodyTypeKeeper.length">
                    {{carsFilterService.selectedBodyTypeKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedBodyTypeKeeper.length"> All</span>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                  Body Type
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">

                <div style="font-size:14px;float:right;margin-top:4px">{{model.name}}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[4]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>
            <p-multiSelect [options]="transmissions"
                           placeholder="Transmission"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('transmissions', 5);"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedTransmissionKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedTransmission">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedTransmissionKeeper.length">
                    {{carsFilterService.selectedTransmissionKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedTransmissionKeeper.length"> All</span>

                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                 Transmission
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px">
                  {{ model.name === 'A' ? 'Automatic' : 'Manual' }}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[5]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>
            <p-multiSelect [options]="modelYears | orderBy: '-'"
                           placeholder="Year"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('modelYears', 6);"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedYearKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedModelYears">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedYearKeeper.length">
                    {{carsFilterService.selectedYearKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedYearKeeper.length"> All</span>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                 Year
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div style="font-size:14px;float:right;margin-top:4px">{{model.name }}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[6]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>
            <p-multiSelect [options]="colors"
                           placeholder="Colour"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('colours', 7);"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedColorKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedColors">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedColorKeeper.length">
                    {{carsFilterService.selectedColorKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedColorKeeper.length"> All</span>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                Colour
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div *ngIf="model.name === 'Other'">
                  <img class="colour-block"
                       src="/assets/images/color-wheel.png">
                </div>
                <div *ngIf="model.name !== 'Other' && model.name !== 'BRONZE'">
                  <span class="colour-block" [ngStyle]="{'background':  model.name }"></span>
                </div>
                <div *ngIf="model.name === 'BRONZE'">
                  <span class="colour-block-bronze"></span>
                </div>
                <div style="font-size:14px;float:right;margin-top:4px; text-transform: uppercase">{{model.name }}</div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[7]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>

            <p-multiSelect [options]="mileages"
                           placeholder="Mileage"
                           [filter]="true"
                           virtualScrollItemSize="25"
                           filterBy="name,value"
                           (onPanelShow)="this.populateFilterOnPanelOpen('mileages', 8);"
                           (onChange)="onFilterChange($event)"
                           (onPanelHide)="populateSelectedKeeperForPayload('selectedMileageKeeper');"
                           class="multiselect-custom-virtual-scroll col-md-4"
                           [(ngModel)]="selectedMileage">
              <ng-template let-value pTemplate="selectedItems">
                <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedMileageKeeper.length">
                    {{carsFilterService.selectedMileageKeeper.length}} Selected
                  </span>
                  <span *ngIf="!carsFilterService.selectedMileageKeeper.length"> All</span>
                </div>
                <span class="ui-multiselected-empty-token ui-corner-all">
                 Mileage
                </span>
              </ng-template>
              <ng-template let-model pTemplate="item">
                <div *ngIf="model.name !== '250000 KM'" style="font-size:14px;float:right;margin-top:4px">
                  {{ model.name }}
                </div>
                <div *ngIf="model.name === '250000 KM'" style="font-size:14px;float:right;margin-top:4px">
                  250000+ KM
                </div>
              </ng-template>
              <p-header style="display: flex; justify-content: center">
                <div *ngIf="showFilterLoader[8]">
                  <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
                </div>
              </p-header>
            </p-multiSelect>

            <div class="col-md-12 text-center">
              <button (click)="filterReset();"
                      class="btn btn-link">
                Clear all Filters
              </button>
            </div>

          </div>
        </div>
        <div class="col-md-12 showroom-list">
          <div *ngIf="noStockInSearch"
               class="col-lg-12 noVehiclesInSearch">
            <br>
            <h4>No Results Found</h4>
            <p class="text-black">No vehicles match your search criteria. Clearing All Filters.</p>
          </div>

          <p-card class=""
                    *ngIf="!mobileFilter">
            <div class="col-md-12">
              <div class="showroom-top-filter">
                <div class="row m-0">
                  <div class="col-md-12 text-end p-1 px-2" >
                    <span>Sort Vehicles By:  </span>
                    <p-dropdown [options]="vehicleSort"
                                [(ngModel)]="this.carsFilterService.sortingVal"
                                (onChange)="sortViewButton($event)"
                                [filterPlaceholder]="this.carsFilterService.sortingVal"
                                class="customSortDropdown">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
          <div class="row align-items-baseline">
            <div *ngIf="stockCount > 0"
                 class="col-md-6">
              <h5 class="currently-showing">Currently showing <strong>{{stockCount}}</strong> vehicles</h5>
            </div>
            <div class="col-md-6 justify-content-end vehicle-per-page top-paging-container">
              <p-paginator *ngIf="stockCount > 0" (onPageChange)="pageChange($event)"
                           [first]="first"
                           [rows]="rows"
                           [totalRecords]="stockCount"
                           [showJumpToPageDropdown]="true"
                           [showPageLinks]="false"
                           styleClass="justify-content-end" >
              </p-paginator>
            </div>
          </div>
          <div *ngIf="loading"
               class="showroom-loader">
            <img src="assets/images/TCP_Gif.gif" alt="theCarPlace">
          </div>
          <div *ngIf="!loading">
            <div class="row" *ngIf="listView;else list_content">
              <div *ngFor="let car of limitCars; let inx = index"
                   class="col-md-12" >
                <a [routerLink]="['/vehicle', car.id, car.make]" >

                <div class="row car-list-view">
                    <div class="col-sm-3" style="padding-left: 0;position: relative">
                      <div class="car-preview-img-container">
                        <img [lazyLoad]="car?.imageUrl"
                             [defaultImage]="defaultImage"
                             class="img-fluid" style="position: absolute; width: 100%; height: 100%; object-fit: cover;top: 0"
                             alt="{{car.make}}, {{car.masterModel}}">
                      </div>
                      <div class="capitec-approved-logo" *ngIf="car.sellingPrice > 1000 && car.sellingPrice <= 500000">
                        <img src="../../../../../assets/images/CapitecStamp.png" alt="capitec-approved-stamp">
                      </div>
                    </div>
                    <div class="col-sm-9">
                      <div class="car-header">
                        <div class="row">
                          <div class="col-md-8">
                            <h5>{{car.make}}
                              <span class="car-variant">{{car.variant}}</span>
                            </h5>
                          </div>
                          <div class="col-md-4 text-end">
                            <h4 class="price" *ngIf="car.sellingPrice > 1000">{{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}</h4>
                            <h4 class="price" *ngIf="car.sellingPrice < 1000">POA</h4>
                          </div>
                        </div>
                      </div>
                      <div class="details">
                        <div class="row">
                          <div class="col-md-6">
                            <table>
                              <tbody>
                              <tr>
                                <td>
                                  <fa-icon [icon]="mileageIcon"></fa-icon> Mileage
                                </td>
                                <td>
                                  <strong>{{car.mileage | number}} Km</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <fa-icon [icon]="yearIcon" style="margin-right: 18px;margin-left: 2px"></fa-icon>Year

                                </td>
                                <td>
                                  <strong>{{car.modelYear}}</strong>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-6">
                            <table>
                              <tbody>
                              <tr>
                                <td>
                                  <fa-icon [icon]="colourIcon"></fa-icon>  Colour
                                </td>
                                <td>
                                  <strong>{{car.baseColor | ucfirst }}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <fa-icon [icon]="provinceIcon" style="margin-right: 13px;margin-left: 2px"></fa-icon>  Province
                                </td>
                                <td>
                                  <strong>{{car.region}} </strong>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                        <p class="btn more-details-button">View Details</p>
                    </div>
                </div>
                </a>

              </div>
            </div>
            <ng-template #list_content class="row">
              <div *ngFor="let car of limitCars; let inx = index"
                   class=" col-md-4 col-lg-3">
                <a [routerLink]="['/vehicle', car.id, car.make]" >
                  <figure class="car-preview-block">
                    <div class="car-preview-img-container">
                      <img [lazyLoad]="car?.imageUrl"
                           [defaultImage]="defaultImage"
                           class="img-fluid" style="position: absolute;"
                           alt="{{car.make}}, {{car.masterModel}}">
                      <div class="capitec-approved-logo" *ngIf="car.sellingPrice <= 500000">
                        <img src="../../../../../assets/images/CapitecStamp.png" alt="capitec-approved-stamp">
                      </div>
                    </div>
                    <div *ngIf="car.sellingPrice > 1000" class="price">{{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}</div>
                    <div *ngIf="car.sellingPrice < 1000" class="price">POA</div>
                    <figcaption>
                      <h4>{{car.make}}</h4>
                      <p>
                        <strong>{{car.variant | shorten:34:'...'}}</strong>
                        <br>
                        {{car.modelYear}}
                        <br>
                        <img src="/assets/images/speed.svg" alt="mileage" width="20px" height="100%" style="margin-right: 5px" class="img-fluid">
                        {{car.mileage}} km
                        <br style="margin-bottom: 15px">
                        <span style="font-weight: bold">
                          {{car.region}}
                        </span>
                      </p>

                      <a [routerLink]="['/vehicle', car.id, car.make]">More Details</a>
                    </figcaption>
                  </figure>
                </a>
              </div>
            </ng-template>
            <div *ngFor="let cars of allCars"
                 class="col-md-4 col-lg-3">
            </div>

            <div class="paging-container col-lg-12">
              <p-paginator *ngIf="stockCount > 0"
                           (onPageChange)="pageChange($event)"
                           [first]="first"
                           [rows]="rows"
                           [totalRecords]="stockCount"
                           [showJumpToPageDropdown]="true"
                           [showPageLinks]="false">
              </p-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-filter"
       *ngIf="mobileFilter"
       [ngClass]="{'mobile-filter-toggle': this.carsFilterService.filterDisplay}">
    <h4 style="color:#007b9a; margin-bottom:10px; text-align:center;">Refine Search
      <span (click)="displayMobileFilter()"
            style="position:absolute; right:15px;">
        <fa-icon [icon]="closeIcon">
        </fa-icon>
      </span>
    </h4>

    <div class="filter-make">
      <p-multiSelect [options]="regions"
                     placeholder="Region"
                     virtualScrollItemSize="25"
                     [filter]="true"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('regions', 0)"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedRegionKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedRegions">

        <ng-template let-value pTemplate="selectedItems" >
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedRegionKeeper.length">
                    {{carsFilterService.selectedRegionKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedRegionKeeper.length"> All</span>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                 Region
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px">{{model.name }}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[0]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="priceRanges"
                     placeholder="Selling Price"
                     virtualScrollItemSize="25"
                     [filter]="true"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('priceRanges', 1)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedPriceKeeper');"
                     (onChange)="onFilterChange($event)"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedSellingPrice">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedPriceKeeper.length">
                    {{carsFilterService.selectedPriceKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedPriceKeeper.length"> All</span>
          </div>

          <span class="ui-multiselected-empty-token ui-corner-all">
                 Selling Price
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px" *ngIf="model.name !== 'R1500000'">
            {{ model.name}}
          </div>
          <div style="font-size:14px;float:right;margin-top:4px" *ngIf="model.name === 'R1500000'">
            R1500000+
          </div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[1]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="makes"
                     placeholder="Makes"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onChange)="onFilterChange($event)"
                     (onPanelShow)="this.populateFilterOnPanelOpen('makes', 2)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedMakeKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedMakes">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
            <div class="row">
              <div class="col-md-6">
                      <span *ngIf="carsFilterService.selectedMakeKeeper.length">
                        {{carsFilterService.selectedMakeKeeper.length}} Selected
                      </span>
                <span *ngIf="!carsFilterService.selectedMakeKeeper.length"> All</span>
              </div>
            </div>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                  Makes
                </span>
        </ng-template>
        <ng-template let-car pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px">{{car.name}}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[2]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>

      <p-multiSelect [options]="models"
                     placeholder="Models"
                     [virtualScroll]="true"
                     [filter]="true"
                     virtualScrollItemSize ="25"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('masterModels', 3)"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedSeriesKeeper');"
                     class="multiselect-custom-virtual-scroll series-filter"
                     [(ngModel)]="selectedModels">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedSeriesKeeper.length">
                    {{carsFilterService.selectedSeriesKeeper.length | unique}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedSeriesKeeper.length"> All</span>

          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                  Models
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px">{{model.name}}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[3]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="bodyTypes"
                     placeholder="Body Type"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onChange)="onFilterChange($event)"
                     (onPanelShow)="this.populateFilterOnPanelOpen('customBodyTypes', 4);"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedBodyTypeKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedBodyTypes">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedBodyTypeKeeper.length">
                    {{carsFilterService.selectedBodyTypeKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedBodyTypeKeeper.length"> All</span>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                  Body Type
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">

          <div style="font-size:14px;float:right;margin-top:4px">{{model.name}}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[4]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="transmissions"
                     placeholder="Transmission"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('transmissions', 5);"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedTransmissionKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedTransmission">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedTransmissionKeeper.length">
                    {{carsFilterService.selectedTransmissionKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedTransmissionKeeper.length"> All</span>

          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                 Transmission
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px">
            {{ model.name === 'A' ? 'Automatic' : 'Manual' }}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[5]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="modelYears | orderBy: '-'"
                     placeholder="Year"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('modelYears', 6);"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedYearKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedModelYears">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedYearKeeper.length">
                    {{carsFilterService.selectedYearKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedYearKeeper.length"> All</span>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                 Year
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div style="font-size:14px;float:right;margin-top:4px">{{model.name }}</div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[6]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>
      <p-multiSelect [options]="colors"
                     placeholder="Colour"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('colours', 7);"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedColorKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedColors">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedColorKeeper.length">
                    {{carsFilterService.selectedColorKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedColorKeeper.length"> All</span>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                Colour
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">

          <div *ngIf="model.name === 'Other'" style="display:flex">
            <img class="colour-block"
                 src="/assets/images/color-wheel.png">
            <div style="font-size:14px;float:right;margin-top:4px; text-transform: uppercase">Other</div>
          </div>

          <div *ngIf="model.name !== 'Other' && model.name !== 'BRONZE'" style="display:flex">
            <span class="colour-block" [ngStyle]="{'background':  model.name }"></span>
            <div style="font-size:14px;float:right;margin-top:4px; text-transform: uppercase">{{model.name }}</div>
          </div>

          <div *ngIf="model.name === 'BRONZE'" style="display:flex">
            <span class="colour-block-bronze"></span>
            <div style="font-size:14px;float:right;margin-top:4px; text-transform: uppercase">Bronze</div>
          </div>

        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[7]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>

      <p-multiSelect [options]="mileages"
                     placeholder="Mileage"
                     [filter]="true"
                     virtualScrollItemSize="25"
                     filterBy="name,value"
                     (onPanelShow)="this.populateFilterOnPanelOpen('mileages', 8);"
                     (onChange)="onFilterChange($event)"
                     (onPanelHide)="populateSelectedKeeperForPayload('selectedMileageKeeper');"
                     class="multiselect-custom-virtual-scroll"
                     [(ngModel)]="selectedMileage">
        <ng-template let-value pTemplate="selectedItems">
          <div class="ui-multiselected-item-token ui-corner-all">
                  <span *ngIf="carsFilterService.selectedMileageKeeper.length">
                    {{carsFilterService.selectedMileageKeeper.length}} Selected
                  </span>
            <span *ngIf="!carsFilterService.selectedMileageKeeper.length"> All</span>
          </div>
          <span class="ui-multiselected-empty-token ui-corner-all">
                 Mileage
                </span>
        </ng-template>
        <ng-template let-model pTemplate="item">
          <div *ngIf="model.name !== '250000 KM'" style="font-size:14px;float:right;margin-top:4px">
            {{ model.name }}
          </div>
          <div *ngIf="model.name === '250000 KM'" style="font-size:14px;float:right;margin-top:4px">
            250000+ KM
          </div>
        </ng-template>
        <p-header style="display: flex; justify-content: center">
          <div *ngIf="showFilterLoader[8]">
            <img src="assets/images/TCP_Filter_Gif.gif" alt="loading Filter Data">
          </div>
        </p-header>
      </p-multiSelect>

    </div>
      <br>
      <div class="sticky-div" style="margin-left: -10px;z-index: 9999999999">
        <div class="container mobile-filter-buttons">
          <div class="row" style="padding: 5px 0;">
            <div class="col-6 align-self-center" style="padding-right: 5px;">
              <button (click)="filterReset()"
                      class="btn btn-link clear-filter">Clear Filter
              </button>
            </div>
            <div class="col-6 align-self-center" style="padding-left: 5px;">
              <button (click)="displayMobileFilter()"
                      class="btn showroom-filter-button">Apply Filters
              </button>
            </div>

          </div>
        </div>
      </div>
  </div>
  <br>
  <div class="sticky-div"
              *ngIf="mobileFilter">
          <div class="container showroom-top-filter">
            <div class="row" style="border-bottom: 2px solid #007b9a;">
              <div class="col-6 sort">
                <div style="display: flex; flex-wrap: nowrap; flex-direction: row; justify-content: center;position: relative;top: -2px;" >
                  <p-dropdown [options]="vehicleSort" [(ngModel)]="this.carsFilterService.sortingVal" (onChange)="sortViewButton($event)" placeholder="SORT">
                  </p-dropdown>
                </div>
              </div>
              <div class="vl" (click)="displayMobileFilter()"></div>
              <div class="col-6 filter align-self-center">
                <div style="display: flex; flex-wrap: nowrap; flex-direction: row; justify-content: center;" >
                  <a class="filter-icon" style="display: contents" >
                    <p>SEARCH</p>
                    <fa-icon style="color: grey; margin-left: 30px;" [icon]="angleDownIcon"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
        </div>
    </div>
</section>
