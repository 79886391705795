import { Component } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-capitec-faq',
  templateUrl: './capitec-faq.component.html',
  styleUrls: ['./capitec-faq.component.scss']
})
export class CapitecFaqComponent {
  public chevronDown = faChevronDown;
}
