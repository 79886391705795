<section class="page-container">
  <div class="home-body-container">
    <div class="container what-is-capitec">
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom: 15px">WHAT IS THE CAPITEC FINANCE CHANNEL</h1>

          <p>The Capitec Finance Channel is a service provided by Capitec Bank via TheCarPlace.co.za to customers
            across SA.</p>
          <p>The Capitec Financial Channel helps potential car buyers get access to unsecured finance for vehicles
            that traditionally were not financeable via traditional vehicle finance from other banks.</p>
          <p>Prospective customers received by your dealership from the Capitec Finance Channel are customers that
            have been pre-approved for finance on a vehicle that your dealership advertised on TheCarPlace.co.za.</p>
          <p>Vehicles sold through this channel will generally focus on late year models and “cheapies”, however any
            vehicle under R 500 000.00 will be entertained by the Capitec Finance Channel.</p>
          <p>The Capitec Finance Channel enables Sellers to list any vehicle where the invoice total for such vehicle
            will be below R 500 000.00 (five hundred thousand rand).</p>
          <p>As long as the invoice total billed by your dealership is equal to or less than the approved finance
            amount, your dealership is welcome to add any value-added products that may serve in the customers
            best interests.</p>

          <h2>HOW DOES IT WORK?</h2>
          <div class="desktop">
            <div class="white-background">
              <h3 class="heading text-center">ONLINE PROCESS</h3>
              <img src="/assets/images/ONLINE-Desktop.webp" alt="thecarplace">
            </div>
            <div class="white-background">
              <h3 class="heading text-center">IN BRANCH PROCESS</h3>
              <img src="/assets/images/INBRANCH-Desktop.webp" alt="thecarplace">
            </div>
          </div>
          <div class="mobile">
            <div class="white-background">
              <h3 class="heading text-center">ONLINE PROCESS</h3>
              <img src="/assets/images/ONLINE_Mobile.webp" alt="thecarplace">
            </div>
            <div class="white-background">
              <h3 class="heading text-center">IN BRANCH PROCESS</h3>
              <img src="/assets/images/INBRANCH_Mobile.webp" alt="thecarplace">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
