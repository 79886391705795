<section class="page-container">
  <div class="home-body-container">
    <div class="container dealer-criteria">
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom: 15px">Rules of the Game</h1>
          <h2>Capitec Finance Channel</h2>
          <p>This page displays a summary of the small print. Please be sure to read the
            <a [routerLink]="['/capitec-finance-channel-terms-and-conditions']">legal mumbo jumbo</a> for the nitty gritty small print.
          </p>
          <div class="accordion customAccordion" id="accordionExample">
            <div class="faq">
              <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <p-card class="question-faq">
                  What is this?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseOne" class="faq-body collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" >
                <ul>
                  <li>In short, it’s a simple method of advertising your vehicles at no cost to you, to potential customers who are given a wallet to buy your vehicle from Capitec.</li>
                  <li>It’s focused on vehicles that other banks don’t want to finance.</li>
                  <li>It’s something different. We will use any means in our power to add more and more value to your dealership and the SA Motor Trade.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <p-card class="question-faq">
                  Advantages
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapseTwo" class="faq-body collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <ul>
                  <li>If you qualify, it’s <strong>FREE</strong> to advertise. No catches, no funny business.
                    You move some extra metal, Capitec gets another client and TheCarPlace gets a small commission from Capitec. Easy and win-win.</li>
                  <li>You can widen your stock portfolio and alleviate some of the brutal stock challenges our trade faces.</li>
                  <li>You can retail your “late model and cheapie” Buy-ins and Trade-ins that you may have previously sought covers for.</li>
                  <li>The process is super simple and seamless. Capitec client chooses a vehicle, they get approval, you get the sale, we verify your invoice and documents, you get paid. Simple.</li>
                  <li>You get to use the brilliant VMG Webmaster eco system at no charge for this specific feature
                    (VMG hopes you might love them so much you might consider using some of their other products, if you don’t already).</li>
                  <li>Existing VMG customers also get the Capitec Finance Channel feature for <strong>FREE</strong>
                    (TheCarPlace pays VMG a small fee for any successful sales).</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <p-card class="question-faq">
                  Disadvantages
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapseThree" class="faq-body collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <ul>
                  <li>We can’t see any and if we had to nitpick,
                    maybe we could say that this channel requires the best from your team to help look after it in a manner that brings more and more clients to you.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                <p-card class="question-faq">
                  What about DIC?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse4" class="faq-body collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                <ul>
                  <li>Not through this channel yet, but, you can add VAPS to your invoices so there’s still 2nd gross opportunities.</li>
                  <li>The vehicles we are focused on would have been cash or trade deals anyway and
                    hence no DIC but now you have an alternate channel to sell vehicles you might have others wise sought covers for.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                <p-card class="question-faq">
                  What!!!?? I have to Qualify?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse5" class="faq-body collapse" aria-labelledby="heading5" data-parent="#accordionExample">
                <p>Yebo! You only qualify if...</p>
                <ul>
                  <li>Your dealership has at least one bank code from another major financial services provider</li>
                  <li>You agree to sell only roadworthy cars</li>
                  <li>You agree to posting quality adverts with good descriptions and images.</li>
                  <li>You agree to keeping your dealerships name held in high regard</li>
                  <li>You agree to play fair</li>
                  <li>You agree to play honestly</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                <p-card class="question-faq">
                  What is expected of my Dealership?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse6" class="faq-body collapse" aria-labelledby="heading6" data-parent="#accordionExample">
                <ul>
                  <li>We need to ensure that attention to detail and quality control are in your control at all times.</li>
                  <li>If we send you a Capitec customer, that they will be treated well.</li>
                  <li>If we send you a Capitec customer, that they will be processed quickly so we can send the next one.</li>
                  <li>We need to ensure that vehicles are priced right (We’re relying on TransUnion pricing data)</li>
                  <li>We need to ensure that VAPS and Invoice Extras are priced right. They have to be verified.</li>
                  <li>We need to ensure that financed clients have valid driver licenses.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                <p-card class="question-faq">
                  What do you need from us?
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapse7" class="faq-body collapse" aria-labelledby="heading7" data-parent="#accordionExample">
                <ul>
                  <li>We work for your dealership. Let us know what you need and how we can do more for you by emailing
                    <a href="mailto:customercare@thecarplace.co.za">customercare@thecarplace.co.za</a> or calling us on
                    <a href="tel:087 152 5004">087 152 5004</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
