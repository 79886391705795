import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LocalDataService } from '../../../services/local-data.service';

@Component({
  selector: 'app-request-car-enquiry',
  templateUrl: './request-car-enquiry.component.html',
  styleUrls: ['./request-car-enquiry.component.scss']
})
export class RequestCarEnquiryComponent {
  public attachments = {};
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#ffffff';
  public requestCarFormHide = true;

  public requestCarForm = new UntypedFormGroup({
    make: new UntypedFormControl('', [Validators.required]),
    masterModel: new UntypedFormControl('', [Validators.required]),
    year: new UntypedFormControl('', [Validators.required, Validators.min(999), Validators.max(9999)]),
    mileage: new UntypedFormControl('', [Validators.required]),
    color: new UntypedFormControl('', [Validators.required]),
    condition: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    contactNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    message: new UntypedFormControl('')
  });

  constructor(
    private http: HttpClient,
    public localData: LocalDataService
  ) { }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: this.localData.store.eMail.contactMail,
        CcList: 'info@thecarplace.co.za',
        BccList: '',
        Subject: 'I would like to Request a Car from ' + this.requestCarForm.value.name + '.',
        Message: this.requestCarForm.value.message
          + '\n\nMake: ' + this.requestCarForm.value.make
          + '\nModel: ' + this.requestCarForm.value.masterModel
          + '\nYear: ' + this.requestCarForm.value.year
          + '\nMileage: ' + this.requestCarForm.value.mileage
          + '\nColor: ' + this.requestCarForm.value.color
          + '\nCondition: ' + this.requestCarForm.value.condition
          + '\nContact me on: ' + this.requestCarForm.value.contactNumber,
        MessageSubject: 'Request a Car',
        ToName: this.localData.store.eMail.contactMailName,
        FromName: this.requestCarForm.value.name,
        FromEmail: this.requestCarForm.value.eMail,
        Attachments: this.attachments
      }
    };

    const dataString = JSON.stringify(this.formDetails);
    return this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers, reportProgress: true}).subscribe(
        {
          next: () => {
            this.requestCarForm.reset();
            this.requestCarFormHide = false;
            this.btnShow = true;
            this.mailSent = true;
            this.spinner = false;
            document.querySelector('html').scrollIntoView();
          },
          error: () => {
            this.btnShow = false;
            this.spinner = false;
          }
        }
    );
  }

}
