<section class="page-container">
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>DEALER FAQ's</h1>
          <div class="accordion customAccordion" id="accordionExample">
            <div class="faq">
              <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <p-card class="question-faq">
                  What Makes TheCarPlace.co.za different?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseOne" class="faq-body collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" >
                <ul>
                  <li>Our difference is our pricing model.</li>
                  <li><strong>Advertising on TheCarPlace is FREE</strong> for any valued <strong>VMG DMS</strong> customer in good standing</li>
                  <li>Instead of charging your dealership to advertise, TheCarPlace only charges a small “Introduction
                    Commission” – aka “spotters fee” upon the successful conclusion of a sale of a vehicle
                    generated to TheCarPlace.co.za Customer.</li>
                  <li>See fees below</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <p-card class="question-faq">
                  Qualification Criteria
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseTwo" class="faq-body collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <ul>
                  <li>Only VMG DMS Customers in good standing will be permitted to advertise their vehicles on
                    TheCarPlace.co.za.</li>
                  <li>Any South African dealership who meets the stringent qualification criteria may advertise their
                    vehicles on the Capitec Finance Channel Showroom.</li>
                  <li>The Capitec Finance Channel Showroom is a stand-alone product, independent of
                    TheCarPlace.co.za general showroom pages.</li>
                  <li>VMG DMS Customers are required to pass a DMS health check score of 60% in random quality
                    control checks conducted by our partner VMG Software.</li>
                  <li>See below for Health Check criteria.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <p-card class="question-faq">
                  How Much Does It Cost?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseThree" class="faq-body collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <ul>
                  <li>Listing vehicles on TheCarPlace.co.za is <strong>FREE OF CHARGE</strong> for qualifying dealerships.</li>
                  <li>Thereafter, an “Introduction Commission” is charged upon the successful sale of a vehicle to a
                    customer that was achieved via TheCarPlace.co.za.</li>
                  <li>All prices displayed are inclusive of VAT and the breakdown is as follows.
                    <ul>
                      <li>R 575 per vehicle sold up to R 100 000.00</li>
                      <li>R 865 per vehicle sold between R 100 000.01 and R 150 000.00</li>
                      <li>R 1 150 per vehicle sold between R 150 000.01 and R 225 000.00</li>
                      <li>R 1 725 per vehicle sold between R 225 000.00 and R 400 000.00</li>
                      <li>R 2 300 per vehicle sold for R 400 000.01 and higher.</li>
                    </ul>
                  </li>
                  <li>The is <strong>NO CHARGE</strong> to your dealership for any vehicle sold via the
                    <a [routerLink]="['/capitec-finance']">Capitec Finance Channel.</a></li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                <p-card class="question-faq">
                  How Does “TheCarPlace” know I’ve sold a Vehicle?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse4" class="faq-body collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                <ul>
                  <li>TheCarPlace.co.za is a VMG Software developed product and integrates seamlessly into the
                    VMG DMS eco system.</li>
                  <li>Vehicles are exclusively uploaded to TheCarPlace.co.za via the VMG Webmaster platform. Each
                    vehicle is provided with a unique Stock ID.</li>
                  <li>Customer enquiries are sent to your dealership via email or to your VMG CRM module.
                    Customers are also given a unique Customer ID, which is then linked to the relevant Stock ID.</li>
                  <li>When the Customer is invoiced, the system links the two ID’s and TheCarPlace bills your
                    dealership as per the pricing schedule displayed above.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                <p-card class="question-faq">
                  When do I get Billed?
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapse5" class="faq-body collapse" aria-labelledby="heading5" data-parent="#accordionExample">
                <ul>
                  <li>The total units sold are calculated from the 23rd to the 22nd of each month.</li>
                  <li>Invoicing for successful transactions takes place on the 27th of each month or nearest preceding
                    working day.</li>
                  <li>Invoices are due for payment within 3 working days from date of invoice.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                <p-card class="question-faq">
                  What if I don’t pay?
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapse6" class="faq-body collapse" aria-labelledby="heading6" data-parent="#accordionExample">
                <ul>
                  <li><strong>Advertising is Free</strong> and the Introduction Commission is small and is only applicable if a customer
                    buys a vehicle from your dealership.</li>
                  <li>If invoices are not paid, TheCarPlace.co.za reserves the right to remove that Dealers vehicles
                    from TheCarPlace.co.za until billing challenges are resolved.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                <p-card class="question-faq">
                  Can I be blacklisted from advertising on the TheCarPlace?
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapse7" class="faq-body collapse" aria-labelledby="heading7" data-parent="#accordionExample">
                <ul>
                  <li>Yes. Whilst it is free to list your vehicles, the platform does rely on quality data and integrity. See
                    the reasons below</li>
                  <li>Listings of poor quality are removed from the platform to protect everyone’s best interests.</li>
                  <li>If your dealership losing it’s bank codes</li>
                  <li>Scoring less than 3 out of 5 in a VMG DMS database health check and not rectifying the issues
                    (Poor admin quality generally indicates a lack of attention to detail).</li>
                  <li>Having a CSI score below 7 out of 10. The Customer Satisfaction Index is a randomly created
                    survey sent to TheCarPlace.co.za customers. In this survey we ask questions related to their
                    buying experience and quality of service.</li>
                  <li>If it is confirmed that dishonest or illegal activity is present, listing access for the offending
                    dealership is revoked permanently.</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                <p-card class="question-faq">
                  What is a VMG DMS Health Check
                  <fa-icon [icon]="chevronDown"></fa-icon>

                </p-card>
              </button>
              <div id="collapse8" class="faq-body collapse" aria-labelledby="heading8" data-parent="#accordionExample">
                <ul>
                  <li>The health check is a quality control tool to protect the Dealership owners’ best interests.</li>
                  <li>The health check alerts your dealership to inconsistencies in admin processing.
                    <ul>
                      <li>Inconsistencies in admin processing expose the owners to possible fraudulent activity,
                        substandard admin processing and incorrect reporting.</li>
                    </ul>
                  </li>
                  <li>Owners with perfect health checks scored can rest assured that the administration processing in
                    their multi-million-rand organization is being expertly maintained. It gives the owner peace of
                    mind.</li>
                  <li>The health check is a quick subjective view of 5 reports. The reports assessed are…
                    <ul>
                      <li>Indicator Report on Invoiced vehicles</li>
                      <li>Full Profit Report by Finance House</li>
                      <li>2nd Gross Profit Report by Type</li>
                      <li>Stock List with Estimated Profit</li>
                      <li>Stock Cost by Type Report</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
