<section class="page-container">
  <div class="body-container">
    <div class="container privacy-policy">
      <div class="row">
        <div class="col-lg-12">
          <div id="privacy_notice">
            <div class="text-center">
              <h2>TheCarPlace.co.za Privacy Policy</h2>
              <p> This website privacy policy describes how TheCarPlace (“we” or “us”) process information it collects and/or
                receives from you.</p>
            </div>
            <p>Read this policy carefully before browsing this website or using any of the services. Your consent to this policy, and
              your continued use of this website, indicates that you have both read and agree to the terms of this privacy policy.
              You cannot use this website if you do not accept this policy. All sections of this policy are applicable to all users,
              unless specifically stipulated otherwise.</p>
            <ol>
              <li>
                <h5>Information we collect and receive</h5>
                <ol>
                  <li>We collect and receive information about you in the following ways:</li>
                  <li>Information you give us
                    <ol>
                      <li>This includes any information that you provide to us directly:</li>
                      <li>when you sign-up to utilise our services</li>
                      <li>by filling in forms on our websites, or those provided to you;</li>
                      <li>when you enter a competition, promotion or complete a survey;</li>
                      <li>by posting comments or content on our social media pages; or</li>
                      <li>when you contact us, or, we contact you and you provide information directly to us.</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h5>What personal information we collect</h5>
                <ol>
                  <li>"Personal Information" (Pl) shall mean the race, gender, sex, pregnancy, marital status, national or ethnic
                    origin, colour, sexual orientation, age, physical or mental health, disability, religion, conscience, belief,
                    culture, language and birth of a person; information relating to the education or the medical, financial,
                    criminal or employment history of the person; any identifying number, symbol, email address, physical
                    address, telephone number, location information, online identifier or other particular assignment to the
                    person; the biometric information of the person; the personal opinions, views or preferences of the
                    person; correspondence sent by the person that is implicitly or explicitly of a private or confidential
                    nature or further correspondence that would reveal the contents of the original correspondence; the
                    views or opinions of another individual about the person whether the information is recorded
                    electronically or otherwise.</li>
                  <li>TheCarPlace.co.za also subscribes to the principles for electronically collecting personal information
                    outlined in the Protection of Personal Information Act 4 of 2013, and the further legislation referred to
                    therein. We use all reasonable measures to ensure the quality, accuracy, and confidentiality of Personal
                    Information in our possession.</li>
                  <li>When you use our website or services, you may be required to provide us with the following personal
                    information,
                    <ol>
                      <li>name and surname</li>
                      <li>company name</li>
                      <li>contact number</li>
                      <li>email address;</li>
                      <li>physical address;</li>
                      <li>Vehicle information (year, make, model, colour, mileage and the like)</li>
                      <li>Company name, registration number and VAT registration number.</li>
                    </ol>
                  </li>
                  <li>We will attempt to limit the types of Personal Information we process to only that which is absolutely
                    necessary and that to which you consent to.</li>
                </ol>
              </li>

              <li>
                <h5>We collect information when you use websites or social media platforms by using cookies, and other
                  technologies. Cookies are defined as follows.</h5>
                <ol>
                  <li>A cookie is a small piece of information stored on your computer or smart phone by the web browser.
                    The three types of cookies used on the Website are described below:
                    <ol>
                      <li>“Session cookies”: These are used to maintain a so-called ‘session state’ and only lasts for the
                        duration of your use of the Website. A session cookie expires when you close your browser, or if
                        you have not visited the server for a certain period of time. Session cookies are required for the
                        Platform to function optimally but are not used in any way to identify you personally.</li>
                      <li>“Third Party cookies”: Third party vendors with whom we contract may use cookies to serve a user
                        adverts based on a User’s prior visits to the Website or User based browser analytics.</li>
                      <li>“Permanent cookies”: These cookies permanently store a unique code on your computer or smart
                        device hard drive in order to identify you as an individual User. No Personal Information is stored in
                        permanent cookies. You can view permanent cookies by looking in the cookies directory of your
                        browser installation. These permanent cookies are not required for the Website to work, but may
                        enhance your browsing experience.</li>
                    </ol>
                  </li>
                  <li>Depending on how you access and use websites, we may receive,
                    <ol>
                      <li>Log information;</li>
                      <li>Information we infer about you based on your interaction with products and services;</li>
                      <li>Device information (for example the type of device you are using, how you access platforms, your
                        browser or operating system and your Internet Protocol ("IP") address);</li>
                      <li>Location information.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <h5>We may receive additional information about you that is publicly or commercially available and combine that
                  with the information we have collected or received about you in other ways.</h5>
              </li>

              <li>
                <h5>We use the information we collect and receive for the following general purposes:</h5>
                <ol>
                  <li>to provide you with information, products or services you request from us;</li>
                  <li>in order to refer you to an appropriate third-party service provider;</li>
                  <li>to communicate with you;</li>
                  <li>to provide you with support; and</li>
                  <li>to provide effective advertising (for example to be provide you with news, special offers and general
                    information about other goods, services and events which we offer, that are similar to those that you
                    have already purchased or enquired about).</li>
                  <li>Conducting market research to measure user experience, dealer performance, vehicle preferences and
                    consumer trends.</li>
                  <li>Developing a more direct and substantial relationship with Users for the purposes described in this
                    clause;</li>
                  <li>Developing an online User profile;</li>
                  <li>If you are a Seller (as defined in the Terms of Service) of a vehicle, making your submitted Personal
                    Information available to prospective Purchasers;</li>
                  <li>If you are a Dealer or Group (as defined in the Terms of Service), making your submitted Personal
                    Information available to prospective Purchasers;</li>
                  <li>If you are a Purchaser (as defined in the Terms of Service), making available your submitted Personal
                    Information to Sellers once you have shown interest in a prospective vehicle in an Advert (as defined in
                    the Terms of Service) on the Website;</li>
                  <li>Understanding general User trends and patterns so that we can develop and support existing and
                    ongoing marketing strategies;</li>
                  <li>For security, administrative and legal purposes;</li>
                  <li>The creation and development of market data profiles which will be compiled and retained in aggregated
                    form, but shall not be used in any way which may comprise the identity of a User</li>
                </ol>
              </li>

              <li>
                <h5>We do not sell your personal information to third parties for their marketing purposes.</h5>
              </li>

              <li>
                <h5>We may share your personal information with:</h5>
                <ol>
                  <li>a limited number of our employees and third party service providers (other than those who we refer you
                    to), who we assist you to interact with;</li>
                  <li>our business partners. We may share non-personally identifiable information with select business
                    partners;</li>
                  <li>other parties in response to legal processes or when necessary to conduct or protect our legal rights;</li>
                  <li>companies that provide services to us. Companies that provide services to us or act on our behalf may
                    have access to information about you. These companies are limited in their ability to use information
                    they receive in the course of providing services to us or you; and</li>
                  <li>third-parties where you provide consent. In some cases, third-parties (often advertisers) may wish to
                    attain information about you in order to promote their products to you, or for whatever other reason.
                    We may share information with third-parties where you provide consent in the form of an explicit opt-in.
                    Before we ask you to opt-in, we will endeavour to provide you with a clear description of what personal
                    information would be shared with the third-party. Remember that once you have opted in to allow us to
                    send your information to the third-party, we cannot control what they do with your personal
                    information; therefore, be sure to investigate their privacy policies before providing permission for us to
                    share your information.</li>
                </ol>
              </li>

              <li>
                <h5>You have the right to</h5>
                <ol>
                  <li>ask us not to contact you for marketing purposes. You can exercise this right at any time by using any of
                    the various "opt-out" options that we will always provide to you when we communicate with you. We
                    will not send you marketing messages if you tell us not to but we will still need to send you servicerelated
                    messages.</li>
                  <li>If you wish to reject our cookies, you can configure your browser to do so.</li>
                  <li>We want to make sure that any personal information we hold about you is up to date. So, if you think
                    your personal information is inaccurate, you can ask us to correct or remove it.</li>
                </ol>
              </li>

              <li>
                <h5>We will retain your personal information only for as long as is necessary for the purposes set out in this privacy
                  policy or to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</h5>
              </li>

              <li>
                <h5>Our commitment to security</h5>
                <ol>
                  <li>The security of your personal information is important to us. While we strive to use commercially
                    acceptable means to protect your personal information, we cannot guarantee its absolute security.</li>
                  <li>We do employ a number of safeguards intended to mitigate the risk of unauthorized access or disclosure
                    of your information. We will do our best to protect your personal information and we will use up-to-date
                    technology that will help us to do this. We will at all times comply with our obligation under applicable
                    law.</li>
                </ol>
              </li>

              <li>
                <h5>Transfer of personal information</h5>
                <ol>
                  <li>We are based in and operate from South Africa. Your information, including personal information, may
                    be transferred to and maintained on servers located outside of your country of residence, where the
                    data privacy laws, regulations and standards, may not be equivalent to the laws in your country of
                    residence.</li>
                  <li>We might transfer your personal information to places outside of South Africa and store it there, where
                    our suppliers might process it. If that happens, your personal information will only be transferred to and
                    stored in a country that has equivalent, or better, data protection legislation than South Africa or with a
                    service provider which is subject to an agreement requiring it to comply with data protection
                    requirements equivalent or better than those applicable in South Africa.</li>
                  <li>Your use of our website, followed by your submission of information to us, represents your consent to
                    such transfer.</li>
                  <li>We will take all steps reasonably necessary to ensure that your personal information is treated securely
                    and in accordance with this privacy policy.</li>
                </ol>
              </li>

              <li>
                <h5>Links to other websites</h5>
                <ol>
                  <li>Our website or social media platforms may contain links to and from websites, mobile applications or
                    services of third parties, advertisers or affiliates. Please note that we are not responsible for the privacy
                    practices of such other parties and advise you to read the privacy statements of each website you visit
                    which collects personal information.</li>
                </ol>
              </li>

              <li>
                <h5>We may update this privacy policy from time to time. Any changes that we may make to our privacy policy will
                  be posted on our website and will be effective from the date of posting.</h5>
              </li>
              <li>
                <h5>You may at any time request:</h5>
                <ol>
                  <li>confirmation that we hold your personal information;</li>
                  <li>access to your personal information;</li>
                  <li>the identities or categories of third parties to whom we have disclosed your personal information; or</li>
                  <li>that we correct or delete any personal information that is incomplete, misleading, inaccurate, excessive
                    or out of date.</li>
                </ol>
              </li>

              <li>
                <h5>Requests may be made in writing to <a href="mailto:customercare@thecarplace.co.za" target="_blank">customercare@thecarplace.co.za.</a></h5>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
