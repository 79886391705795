<section class="page-container">
  <div class="home-body-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>News and Advice</h1>
        </div>
        <div class="col-md-8">
          <div class="block article-list">
            <div class="article" *ngFor="let article of articles">
              <a [routerLink]="['/news-and-advice/', this.getUrl(article.heading)]" [queryParams]="{id: article.id}" >
                <div class="row">
                  <div class="col-md-5">
                    <img src="{{article.imageUrl}}" alt="article-image">
                  </div>
                  <div class="col-md-7">
                    <h5>{{article.heading}}</h5>
                    <p>{{article.description}}</p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
        <div class="col-md-4">

          <div class="color-block text-center">
            <h4>Quick Links:</h4>
            <a [routerLink]="['/what-is-the-car-place']">
              <p> What is Thecarplace?</p>
            </a>
            <a [routerLink]="['/showroom']">
              <p> General Showroom</p>
            </a>
            <a [routerLink]="['/capitec-finance']">
              <p>Capitec Finance Showroom</p>
            </a>
            <a [routerLink]="['/what-is-capitec-finance-channel']">
              <p> What is capitec showroom</p>
            </a>
            <a [routerLink]="['/sell-car']">
              <p> Sell my car</p>
            </a>
            <a [routerLink]="['/find-dealership']">
              <p>find Dealership</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
