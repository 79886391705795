import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-request-car',
  templateUrl: './request-car.component.html',
  styleUrls: ['./request-car.component.scss']
})
export class RequestCarComponent implements OnInit {

  constructor(
    private meta: Meta,
    private title: Title
  ) { }

  public ngOnInit() {
    this.setMetaData();
  }

  private setMetaData() {
    this.title.setTitle('Request A Car');
    this.meta.addTags([
      {name: 'title', content: 'Request A Car'},
      {name: 'description', content: 'To make things easier for you, you can let us know what vehicle you are looking for and we will contact you with a possible deal – it’s that easy. Fill in our form below and we’ll find it for you. \n'},
      {name: 'keywords', content: 'the car place, request a car, car deals, car sales, used cars for sale, cars for sale western cape, used car dealerships'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {rel: 'canonical', href: 'https://thecarplace.co.za/request-car'},

      {property: 'og:title', content: 'Request A Car'},
      {property: 'og:description', content: 'To make things easier for you, you can let us know what vehicle you are looking for and we will contact you with a possible deal – it’s that easy. Fill in our form below and we’ll find it for you. \n'},
      {property: 'og:url', content: 'https://thecarplace.co.za/request-car'},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/meta-image.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/meta-image.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'Request A Car'},
      {name: 'twitter:description', content: 'To make things easier for you, you can let us know what vehicle you are looking for and we will contact you with a possible deal – it’s that easy. Fill in our form below and we’ll find it for you. \n'},
      {name: 'twitter:domain', content: 'thecarplace.co.za/request-car'},
    ]);
  }
}
