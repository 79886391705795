import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public services: any = [];
  constructor() { }

  public ngOnInit() {
    this.services = [
      {title: 'TransUnion',
      image: '../../../../assets/images/transunion/TRANSUNION.webp',
      regUrl: '/transunion',
      learnMoreUrl: 'https://www.vmgsoftware.co.za/transunion'}
    ];
  }

  protected readonly window = window;
}
