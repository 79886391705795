import { Component } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dealer-criteria',
  templateUrl: './dealer-criteria.component.html',
  styleUrls: ['./dealer-criteria.component.scss']
})
export class DealerCriteriaComponent {
  public chevronDown = faChevronDown;
}
