import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {
  public store = {
    name: 'The Car Place',
    email: 'info@thecarplace.co.za',
    tel: '087 152 5004',
    fax: '',
    faceBook: 'https://www.facebook.com/thecarplaceza/',
    instaGram: 'https://www.instagram.com/thecarplaceza/',
    linkedIn: '',
    twitter: 'https://twitter.com/TheCarPlace2',
    youTube: 'https://www.youtube.com/channel/UC2_t66bU7QRBtGI09c03R1Q',
    eMail: {
      contactMail: 'leads@thecarplace.co.za',
      contactMailName: 'The Car Place',
      enquireMail: 'leads@thecarplace.co.za',
      enquireMailName: 'The Car Place',
    },
    tradingHours: {
      monFri: '08:00 to 17:00',
      sat: '',
      sun: ''
    },
    logos: {
      logoOne: '',
      logoTwo: '',
      logoThree: '',
      logoFour: ''
    },
    serverMailSellApi: environment.emailOtherEnquiriesUrl,
    headers: new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json'}),
    defaultLogo: '../assets/images/default_logo.png'
  };

  constructor() { }
}
