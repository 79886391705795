import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {faFacebookF, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {CarsFilterService} from '../../../services/cars-filter.service';
import {AllCarData} from '../../../models/all-car-data';
import {ShowroomData} from '../../../models/showroom-data';
import {LocalDataService} from '../../../services/local-data.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleComponent implements OnInit, OnDestroy {
  public car: any;
  public responsiveOptionsRecommendedCars: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '768px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  public displayCustom: boolean | undefined;
  public activeIndex: number = 0;
  public facebookLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';
  public twitterLink: string = 'https://www.twitter.com/share?url=';
  public whatsAppLink: string = 'https://api.whatsapp.com/send?text=';

  public recommendedCars: Array<ShowroomData> = [];
  public display = false;
  public loading: boolean;
  public slides = [];
  public carExtras = [];
  public defaultImage = '../../assets/images/nopic.png';
  public backIcon = faAngleDoubleLeft;
  public mobileTabs: boolean;

  constructor(
    private carsFilterService: CarsFilterService,
    private routeParams: ActivatedRoute,
    private router: Router,
    public carModel: AllCarData,
    public localData: LocalDataService,
    public faLibrary: FaIconLibrary,
    private meta: Meta,
    private title: Title,
  ) {
    this.loading = true;
    faLibrary.addIcons(faFacebookF, faWhatsapp, faTwitter);
  }

    public url = this.router.url;

  public ngOnInit() {
    this.facebookLink += window.location.href;
    this.twitterLink += window.location.href;
    this.whatsAppLink += window.location.href;
    this.routeParams.paramMap.subscribe(params => {
      this.carModel.id = params.get('id');
      this.carModel.make = params.get('make');
      this.carModel.bodyType = params.get('bodyType');
      this.carsFilterService.getCar(this.carModel).subscribe(http => {

        this.car = new AllCarData();
        this.car = http;

        this.fixAbbreviation();

        this.convertToObject();

        this.getRecommendedCars();

        this.loading = false;

        this.setMetaData();
      });

      this.mobileCheckForTabs();
    });
  }

  public imageClick(clickedImage: any) {
    this.activeIndex = this.slides.findIndex(image => image === clickedImage);
    this.displayCustom = true;
  }

  public convertToObject() {
    this.slides = [];
    const imagesObject = JSON.parse(this.car.images);
    imagesObject.forEach((item: any) => {
      this.slides.push(item.image_url);
    });

    this.carExtras = [];
    if (this.car.extras) {
      const extrasObject = JSON.parse(this.car.extras);
      extrasObject.forEach((item: any) => {
        this.carExtras.push(item.name);
      });
    }
  }

  public addSpace(num: number, separator: any) {
    if (num != null) {
      let mainString = num.toString();
      mainString = mainString.split('').reverse().join('');

      mainString = mainString.match(/.{1,3}/g).join(separator);
      mainString = mainString.split('').reverse().join('');

      return mainString;
    }
  }

  private getRecommendedCars() {

    this.carsFilterService.selectedSkip = 0;
    this.carsFilterService.selectedTop = 12;
    this.carsFilterService.selectedBodyTypeKeeper = [this.car.customBodyType];

    this.carsFilterService.getStocksByPagination()
      .subscribe(http => {
        this.recommendedCars = http['stocks'];
        const index = this.recommendedCars.findIndex(car => car.id === this.carModel.id);
        this.recommendedCars.splice(index, 1);
      });
  }


  private fixAbbreviation(): void {
    if (this.car.stockInformationFuelType === 'D') {
      this.car.stockInformationFuelType = 'Diesel';
    }
    if (this.car.stockInformationFuelType === 'P') {
      this.car.stockInformationFuelType = 'Petrol';
    }
    if (this.car.stockInformationFuelType === 'E') {
      this.car.stockInformationFuelType = 'Electric';
    }
    if (this.car.stockInformationTransmission === 'A') {
      this.car.stockInformationTransmission = 'Automatic';
    }
    if (this.car.stockInformationTransmission === 'M') {
      this.car.stockInformationTransmission = 'Manual';
    }
    if (this.car.stockInformationCooling === 'W') {
      this.car.stockInformationCooling = 'Water';
    }
    if (this.car.stockInformationCooling === 'A') {
      this.car.stockInformationCooling = 'Antifreeze';
    }
  }

  private mobileCheckForTabs(): void {
    if (window.screen.width < 768) {
      this.mobileTabs = true;
    }
  }

  public backToShowroom(): void {
    this.carsFilterService.selectedBodyTypeKeeper =[];
    if (window.history.length > 1) {
      window.history.go(-1);
    } else {
      this.router.navigate(['/showroom']);
    }
  }

  public onImgError(event: any) {
    event.target.src = '../../assets/images/nopic.png';
  }

  public scrollToTop(): void {
    window.scroll(0, 0);
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant);
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'description', content: this.car.description || 'No description available'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: this.car.description || 'No description available'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:description', content: this.car.description || 'No description available'},
      {property: 'og:url', content: 'https://thecarplace.co.za' + this.url},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:image', content: this.slides[0]},
      {property: 'og:image:url', content: this.slides[0]},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'twitter:description', content: this.car.description || 'No description available'},
      {name: 'twitter:image', content: this.slides[0]},
      {name: 'twitter:image:url', content: this.slides[0]},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }

  public ngOnDestroy() {
    this.carsFilterService.selectedBodyTypeKeeper = [];
  }

}
