import { Injectable } from "@angular/core";
@Injectable()
export class AllCarData {
  id: string;
  oldId: string;
  companyName: number;
  companyOldId: string;
  code: number;
  mmCode: number;
  makeId: string;
  model: string;
  vehicleType: string;
  used: string;
  driveType: string;
  interiorColor: string;
  condition: string;
  description: string;
  subRegion: string;
  location: string;
  vinNumber: string;
  registrationNumber: string;
  engineNumber: string;
  totalImages: number;
  archived: string;
  dateUpdated: string;
  deleted: string;
  baseColor: string;
  premium: number;
  branchId: string;
  vehicleDetailsId: number;
  make: string;
  masterModel: string;
  variant: string;
  bodyType: string;
  customBodyType: string;
  modelYear: string;
  sellingPrice: number;
  mileage: number;
  color: string;
  region: string;
  dateCreated: string;
  images: string;
  tradingName: string;
  branchTradingName: string;
  branchAddressLine1: string;
  branchAddressLine2: string;
  branchAddressLine3: string;
  branchCity: string;
  branchLatitude: string;
  branchLongitude: string;
  branchSuburb: string;
  branchName: string;
  branchCompanyName: string;
  branchRegion: string;
  branchCountry: string;
  branchPostalCode: string;
  branchLogoUrl: string;
  branchWebsiteUrl: string;
  branchFinanceUrl: string;
  branchShowroomUrl: string;
  branchContactUrl: string;
  branchWhatsappNumber: string;
  branchDateCreated: string;
  branchDateUpdated: string;
  branchTradingHours: string;
  branchContactNumber: string;
  branchEmailAddress: string;
  extras: string;
  stockInformationId: string;
  stockInformationHeight: number;
  stockInformationWidth: number;
  stockInformationLength: number;
  stockInformationFuelType: string;
  stockInformationTransmission: string;
  stockInformationFuelTankSize: number;
  stockInformationCooling: string;
  stockInformationGears: number;
  stockInformationSeats: number;
  stockInformationDoors: string;
  stockInformationCubicCapacity: number;
  stockInformationKilowatts: number;
  stockInformationGcm: number;
  stockInformationGvm: number;
  stockInformationTares: number;
  stockInformationCo2: number;
  stockInformationFrontTyreSize: string;
  stockInformationRearTyreSize: string;
  stockInformationAxleConfiguration: string;
  stockInformationAxelTransmission: string;
  stockInformationDateCreated: string;
  stockInformationDateUpdated: string;
  stockInformationDeleted: string;

  valueInKms?: string;
}
