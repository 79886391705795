import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  faTimes,
  faCalendarAlt,
  faTachometerAlt,
  faAngleDown,
  faMapMarkerAlt,
  faPaintBrush,
} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from "rxjs";
import {Location} from '@angular/common';

import {CarsFilterService} from '../../../services/cars-filter.service';
import {ShowroomData} from '../../../models/showroom-data';
import {QueryParamHelper} from '../../../shared/helpers/query-param-helper';

import {Meta, Title} from '@angular/platform-browser';
import {MenuItem, SelectItem} from "primeng/api";
import {Cars} from '../../../models/cars';
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.scss']
})
export class ShowroomComponent implements OnInit, OnDestroy {

  public first: number = 0;
  public rows: number = 24;
  // list of  items to populate dropdown
  public makes: Cars<string>[] = [];
  public models: Cars<string>[] = [];
  public bodyTypes: Cars<string>[] = [];
  public modelYears: Cars<number>[] = [];
  public colors: Cars<string>[] = [];
  public transmissions: Cars<string>[] = [];
  public regions: Cars<string>[] = [];
  public priceRanges: Cars<string>[] = [];
  public mileages: Cars<string>[] = [];
  // used for data binding ([ngModel])
  public selectedMakes: string[] = [];
  public selectedModels: string[] = [];
  public selectedBodyTypes: string[] = [];
  public selectedModelYears: string[] = [];
  public selectedColors: string[] = [];
  public selectedTransmission: string[] = [];
  public selectedRegions: string[] = [];
  public selectedSellingPrice: string[] = [];
  public selectedMileage: string[] = [];
  public checkboxEvent: string[] = [];
  public callApi = false;
  private subscription: Subscription = new Subscription();
  public showFilterLoader = [false, false, false, false, false, false, false, false, false];

  public vehicleSort: string[] = [];
  public vehicleSortDesktop: MenuItem[] = [];
  public sortActiveItem: MenuItem | undefined;

  public series: SelectItem[];
  public allCars: Array<number> = new Array<number>();
  public limitCars: ShowroomData[] = [];
  public carModel: ShowroomData = new ShowroomData();
  public allFilterValues: Array<string> = [];
  public queryParamHelper: QueryParamHelper;
  public term: string;
  public loading = true;
  public checked: boolean;
  public spinnerColor = '#007b9a';

  public pageConfig = {
    itemsPerPage: 24,
    currentPage: 1,
  };

  public closeIcon = faTimes;
  public yearIcon = faCalendarAlt;
  public mileageIcon = faTachometerAlt;
  public angleDownIcon = faAngleDown;
  public provinceIcon = faMapMarkerAlt;
  public colourIcon = faPaintBrush;
  public defaultImage = '../../assets/images/nopic.png';
  public mobileFilter: boolean;

  public priceRange: Array<{ low: number, high: number }> = [];
  public mileageRange: Array<{ low: number, high: number }> = [];

  public id: string;
  public page: number;
  public stockCount = 0;

  public listedColors: string[] = [
    'BLACK',
    'BLUE',
    'BROWN',
    'BRONZE',
    'GREEN',
    'GREY',
    'ORANGE',
    'RED',
    'SILVER',
    'WHITE',
    'YELLOW',
    'PURPLE'
  ];
  public primaryColorList = [];
  public otherColorsList = [];
  public listView = true;

  public noStockInSearch: boolean = false;

  constructor(
      public carsFilterService: CarsFilterService,
      private router: Router,
      private routeParams: ActivatedRoute,
      private location: Location,
      private meta: Meta,
      private title: Title,
  ) {
  }

  public async ngOnInit() {
    this.vehicleSort = ['LATEST', 'PRICE: LOW TO HIGH', 'PRICE: HIGH TO LOW'];
    this.vehicleSortDesktop = [{label: 'LATEST'}, {label: 'PRICE: LOW TO HIGH'}, {label: 'PRICE: HIGH TO LOW'}];
    let sortIndex = this.vehicleSort.indexOf(this.carsFilterService.sortingVal);
    if (sortIndex === -1){
      sortIndex = 0;
    }
    this.sortActiveItem = this.vehicleSortDesktop[sortIndex];

    this.clearCheckboxes();

    this.routeParams.queryParamMap.subscribe(params => {
      this.pageConfig.currentPage = +params.get('page');

      if (this.pageConfig.currentPage) {
        this.first = this.pageConfig.currentPage * this.pageConfig.itemsPerPage - this.pageConfig.itemsPerPage;
      }

      const page = (this.pageConfig.currentPage === 0 ? 1 : this.pageConfig.currentPage);
      const perPage = 24;
      const top = perPage;
      const skip = perPage * (page - 1);
      const order = this.sortView();

      this.carsFilterService.selectedTop = top;
      this.carsFilterService.selectedSkip = skip;
      this.carsFilterService.selectedOrderBy = order;

      if (params.keys.length !== 0) {
        this.createNewQueryParamInstance(params);

        this.getMakeParamForModel(params);
        this.getSeriesParamForModel(params);
        this.getBodyTypeParamForModel(params);
        this.getTransmissionParamForModel(params);
        this.getColorParamForModel(params);
        this.getYearParamForModel(params);
        this.getRegionParamForModel(params);
        this.getSellingParamForModel(params);
        this.getMileageParamForModel(params);

        this.carsFilterService.getStocksByPagination().subscribe(http => {
          this.limitCars = http['stocks'];
          this.stockCount = http['count'];
          this.allCars = Array.from(Array(http['count']).keys());
          if (this.stockCount === 0){
            this.filterReset();
            this.noStockInSearch = true;
            setTimeout(()=> {
              this.noStockInSearch = false;
            }, 5000)

          }
          this.sortView();
          this.loading = false;
          this.fixHttpImages(this.limitCars[0]);
        });
      } else {
        this.createNewQueryParamInstance(params);

        this.carsFilterService.getStocksByPagination().subscribe(http => {
          this.limitCars = http['stocks'];
          this.stockCount = http['count'];
          this.allCars = Array.from(Array(http['count']).keys());
          this.sortView();
          this.loading = false;
          this.fixHttpImages(this.limitCars[0]);
        });
      }
    });
    this.mobileCheckForFilter();
    this.checkShowroom();
    this.sortView();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clearSearch();
    this.clearFilterDropdownValues();
  }

  private clearCheckboxes(): void {
    this.carsFilterService.selectedMakeKeeper = [];
    this.carsFilterService.selectedSeriesKeeper = [];
    this.carsFilterService.selectedBodyTypeKeeper = [];
    this.carsFilterService.selectedTransmissionKeeper = [];
    this.carsFilterService.selectedColorKeeper = [];
    this.carsFilterService.selectedColorChipsKeeper = [];
    this.carsFilterService.selectedYearKeeper = [];
    this.carsFilterService.selectedPriceKeeper = [];
    this.carsFilterService.selectedMileageKeeper = [];
    this.carsFilterService.selectedRegionKeeper = [];
  }

  public async populateFilterOnPanelOpen(filterType: string, loaderIndex: number) {
    this.allFilterValues = [];
    this.showFilterLoader[loaderIndex] = true;

    this.subscription.add(this.carsFilterService.getListAllFilterValuesForDataKeeper(filterType).subscribe(async http => {

        this.showFilterLoader[loaderIndex] = false;

        switch (filterType) {
          case 'makes':
            this.makes = [];
            const makes = this.removeDuplicatesInArray(http);
            this.populateFilterDropdown(makes, this.makes);
            break;
          case
          'masterModels':
            this.models = [];
            this.populateFilterDropdown(http, this.models);
            break;
          case
          'modelYears':
            this.modelYears = [];
            const modelYears: Array<string> = this.removeDuplicatesInArray(http);
            this.populateFilterDropdown(modelYears, this.modelYears);
            break;
          case
          'customBodyTypes':
            this.bodyTypes = [];
            this.populateFilterDropdown(http, this.bodyTypes);
            break;
          case
          'transmissions':
            this.transmissions = [];
            this.fixAbbreviation(this.carsFilterService.selectedTransmissionKeeper);
            this.populateFilterDropdown(http, this.transmissions);
            break;
          case
          'regions':
            this.regions = [];
            this.populateFilterDropdown(http, this.regions);
            break;
          case
          'priceRanges':
            this.priceRanges = [];
            this.populatePriceMileageFilterDropdown(http, this.priceRanges);
            break;
          case
          'mileages':
            this.mileages = [];
            this.fixAbbreviation(this.carsFilterService.selectedMileageKeeper);
            this.populatePriceMileageFilterDropdown(http, this.mileages);
            break;
          case
          'colours':
            this.colors = [];
            this.primaryColorList = [];
            const colors = this.removeDuplicatesInArray(http);
            this.otherColorsList = colors;

            for (const col in this.listedColors) {
              for (const list in this.otherColorsList) {
                if (this.otherColorsList[list] === this.listedColors[col]) {
                  this.primaryColorList.push(this.listedColors[col]);
                }
              }
            }
            this.primaryColorList.sort();
            this.primaryColorList.push('Other');

            // tslint:disable-next-line:forin
            for (const col in this.listedColors) {
              for (const res in this.otherColorsList) {
                if (this.listedColors[col] === this.otherColorsList[res]) {
                  this.otherColorsList.splice(this.otherColorsList.indexOf(this.otherColorsList[res]), 1);
                }
              }
            }
            this.primaryColorList.forEach(value => {
              this.colors.push({
                name: value,
                value: this.toProperCase(value).toUpperCase()
              });
            });
            break;
        }
      }
    ));
  }

  public removeDuplicatesInArray(data: Array<string>): Array<string> {
    let result = data.map((item) => {
      return typeof item === 'string' ? item.toString().toUpperCase().trim() : item;
    });
    result = [...new Set(result)];
    return result;
  }

  public populateFilterDropdown(carModel: Array<any>, carModelForUI: Array<Cars<any>>) {
    carModel.forEach((async value => {
      carModelForUI.push({
        name: value,
        value
      });
      carModelForUI.sort((a, b) => a.name.toString().localeCompare(b.name));
    }));
  }

  public populatePriceMileageFilterDropdown(carModel: Array<any>, carModelForUI: Array<Cars<any>>) {
    carModel.forEach((async value => {
      carModelForUI.push({
        name: value,
        value
      });
    }));
  }

  // change text from uppercase to normal case
  public toProperCase(textUpperCase: string) {
    return textUpperCase.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  public clearSearch() {
    this.term = null;
  }

  public onFilterChange(event: any) {
    this.callApi = true;
    this.checkboxEvent = event.value;
  }

  private clearDropdownData(selectedKeeper: string) {
    switch (selectedKeeper) {
      case 'selectedMakeKeeper':
        this.regions = [];
        this.models = [];
        this.priceRanges = [];
        this.bodyTypes = [];
        this.transmissions = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        break;
      case 'selectedSeriesKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.bodyTypes = [];
        this.transmissions = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        break;
      case 'selectedBodyTypeKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.transmissions = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        this.models = [];
        break;
      case 'selectedTransmissionKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        this.models = [];
        this.bodyTypes = [];
        break;
      case 'selectedRegionKeeper':
        this.makes = [];
        this.priceRanges = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        this.models = [];
        this.transmissions = [];
        this.bodyTypes = [];
        break;
      case 'selectedPriceKeeper':
        this.regions = [];
        this.makes = [];
        this.transmissions = [];
        this.modelYears = [];
        this.colors = [];
        this.mileages = [];
        this.models = [];
        this.bodyTypes = [];
        break;
      case 'selectedMileageKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.transmissions = [];
        this.modelYears = [];
        this.colors = [];
        this.models = [];
        this.bodyTypes = [];
        break;
      case 'selectedYearKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.transmissions = [];
        this.colors = [];
        this.models = [];
        this.mileages = [];
        this.bodyTypes = [];
        break;
      case 'selectedColorKeeper':
        this.regions = [];
        this.makes = [];
        this.priceRanges = [];
        this.transmissions = [];
        this.mileages = [];
        this.modelYears = [];
        this.models = [];
        this.bodyTypes = [];
    }
  }

  // method populates the selectedKeepers for the payload
  public async populateSelectedKeeperForPayload(selectedKeeper: string): Promise<void> {

    if (this.callApi) {
      this.limitCars = [];
      this.loading = true;
      switch (selectedKeeper) {
        case 'selectedMakeKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedMakeKeeper = [];
          this.carsFilterService.selectedMakeKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedSeriesKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedSeriesKeeper = [];
          this.carsFilterService.selectedSeriesKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedBodyTypeKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedBodyTypeKeeper = [];
          this.carsFilterService.selectedBodyTypeKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedTransmissionKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedTransmissionKeeper = [];
          this.carsFilterService.selectedTransmissionKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedRegionKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedRegionKeeper = [];
          this.carsFilterService.selectedRegionKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedPriceKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedPriceKeeper = [];
          this.carsFilterService.selectedPriceKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedMileageKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedMileageKeeper = [];
          this.carsFilterService.selectedMileageKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedYearKeeper':
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedYearKeeper = [];
          this.carsFilterService.selectedYearKeeper.push(...this.checkboxEvent);
          break;
        case 'selectedColorKeeper':
          this.carsFilterService.selectedColorKeeper = [];
          const checkIfOtherExist = this.checkboxEvent.find(x => x === 'Other');
          let otherColorString = '';
          if (checkIfOtherExist) {
            this.carsFilterService.selectedColorChipsKeeper = [...new Set([...this.carsFilterService.selectedColorChipsKeeper, ...this.checkboxEvent])];
            this.checkboxEvent.push(...this.otherColorsList);

            this.carsFilterService.isOtherSelected = true;
            otherColorString = ',null';
            this.carsFilterService.otherColorString = otherColorString;
          } else {
            this.carsFilterService.selectedColorChipsKeeper = [...new Set([...this.carsFilterService.selectedColorChipsKeeper, ...this.checkboxEvent])];
            this.carsFilterService.isOtherSelected = false;
          }
          this.clearDropdownData(selectedKeeper);
          this.carsFilterService.selectedColorKeeper.push(...this.checkboxEvent);
          break;
        default:
          break;
      }

      await this.filterCarsSubmit();

      this.callApi = false;
      this.checkShowroom();
      this.loadFirstPage();
    }
  }

  // method adds query parameters and runs the http post to fetch the data
  public async filterCarsSubmit(): Promise<void> {
    try {

      const cleanUrl = this.router.url.replace(/\?.*/g, '');

      this.queryParamHelper.upsertParams('page', []);

      this.buildMakeHttpAndQueryParams();
      this.buildSeriesHttpAndParams();
      this.buildBodyHttpAndQueryParams();
      this.buildTransmissionHttpAndQueryParams();
      this.buildColorHttpAndQueryParams();
      this.buildYearHttpAndQueryParams();
      this.buildRegionHttpAndQueryParams();
      this.buildPriceHttpAndParams();
      this.buildMileageHttpAndParams();

      this.location.go(cleanUrl, this.queryParamHelper.uriQueryParams);

      const page = 1;
      const perPage = 24;
      const top = perPage;
      const skip = perPage * (page - 1);
      const order = this.sortView();

      this.carsFilterService.selectedTop = top;
      this.carsFilterService.selectedSkip = skip;
      this.carsFilterService.selectedOrderBy = order;

      this.carsFilterService.getStocksByPagination().subscribe(http => {
        this.limitCars = http['stocks'];
        this.stockCount = http['count'];
        this.allCars = Array.from(Array(http['count']).keys());
        this.loading = false;
        this.pageConfig.currentPage = 1;
        this.showFilterLoader = [false, false, false, false, false, false, false, false];
        if (this.stockCount === 0){
          this.filterReset();
          this.noStockInSearch = true;
          setTimeout(()=> {
            this.noStockInSearch = false;
          }, 5000)
        }
      });
    } catch (e) {
      throw e.message;
    }
  }

  // HTTP PARAMS SECTION START
  private buildMakeHttpAndQueryParams(): void {
    this.queryParamHelper.upsertParams('make', this.carsFilterService.selectedMakeKeeper);

    if (this.carsFilterService.selectedMakeKeeper.length !== 0) {
      this.selectedMakes = this.carsFilterService.selectedMakeKeeper;
      this.carModel.make = this.carsFilterService.selectedMakeKeeper.join();
    } else {
      delete this.carModel.make;
    }
  }

  private buildSeriesHttpAndParams(): void {
    this.queryParamHelper.upsertParams('series', this.carsFilterService.selectedSeriesKeeper);

    if (this.carsFilterService.selectedSeriesKeeper.length !== 0) {
      this.selectedModels = this.carsFilterService.selectedSeriesKeeper;
      this.carModel.masterModel = this.carsFilterService.selectedSeriesKeeper.join();
    } else {
      delete this.carModel.masterModel;
    }
  }

  private buildBodyHttpAndQueryParams(): void {
    this.queryParamHelper.upsertParams('bodyType', this.carsFilterService.selectedBodyTypeKeeper);
    if (this.carsFilterService.selectedBodyTypeKeeper.length !== 0) {
      this.selectedBodyTypes = this.carsFilterService.selectedBodyTypeKeeper;
      this.carModel.customBodyType = this.carsFilterService.selectedBodyTypeKeeper.join();
    } else {
      delete this.carModel.customBodyType;
    }
  }

  private buildTransmissionHttpAndQueryParams(): void {
    this.queryParamHelper.upsertParams('transmissions', this.carsFilterService.selectedTransmissionKeeper);
    if (this.carsFilterService.selectedTransmissionKeeper.length !== 0) {
      this.selectedTransmission = this.carsFilterService.selectedTransmissionKeeper;
      this.carModel.transmission = this.carsFilterService.selectedTransmissionKeeper.join();
    } else {
      delete this.carModel.transmission;
    }
  }

  private buildColorHttpAndQueryParams(): void {
    const index = this.carsFilterService.selectedColorKeeper.findIndex(item => item === 'Other');

    if (index >= 0) {
      const colors = [];
      for (const color of this.listedColors) {

        const color1 = this.carsFilterService.selectedColorKeeper.find(item => item.toUpperCase() === color);
        if (color1) {
          colors.push(color1);
        }
      }
      this.carsFilterService.selectedColorChipsKeeper = colors;
      this.carsFilterService.selectedColorChipsKeeper.push('Other');
    }

    this.queryParamHelper.upsertParams('baseColor', this.carsFilterService.selectedColorKeeper);

    if (this.carsFilterService.selectedColorKeeper.length !== 0) {
      this.selectedColors = this.carsFilterService.selectedColorKeeper;

      this.carModel.baseColor = this.carsFilterService.selectedColorKeeper.join().toUpperCase();
    } else {
      delete this.carModel.baseColor;
    }
  }

  private buildYearHttpAndQueryParams(): void {
    this.queryParamHelper.upsertParams('modelYear', this.carsFilterService.selectedYearKeeper);

    if (this.carsFilterService.selectedYearKeeper.length !== 0) {
      this.selectedModelYears = this.carsFilterService.selectedYearKeeper;
      this.carModel.modelYear = this.carsFilterService.selectedYearKeeper.join();
    } else {
      delete this.carModel.modelYear;
    }
  }

  private buildRegionHttpAndQueryParams(): void {
    this.queryParamHelper.upsertParams('region', this.carsFilterService.selectedRegionKeeper);
    this.queryParamHelper.removeAllWhitespaces('region');

    if (this.carsFilterService.selectedRegionKeeper.length !== 0) {
      this.carModel.region = this.carsFilterService.selectedRegionKeeper.join();
    } else {
      delete this.carModel.region;
    }

    this.selectedRegions = this.carsFilterService.selectedRegionKeeper;
  }

  private buildPriceHttpAndParams(): void {
    const price: Array<any> = [];

    for (const i of this.carsFilterService.selectedPriceKeeper) {
      price.push(i.toString().replace(/\R/g, '').replace(/\s/g, '').split('-'));
    }

    if (this.carsFilterService.selectedPriceKeeper.length > 0) {
      const priceValuesLow: Array<string> = [];
      const priceValuesHigh: Array<string> = [];
      this.priceRange = [];

      price.forEach(e => {
        priceValuesLow.push(e[0].toString());
        priceValuesHigh.push(e[1]);
      });

      for (let i = 0; i < price.length; i++) {
        this.priceRange.push({
          low: +priceValuesLow[i],
          high: +priceValuesHigh[i]
        });
      }

      this.queryParamHelper.upsertParams('sellingMin', priceValuesLow.sort((a: string, b: string) => Number(a) - Number(b)));
      this.queryParamHelper.upsertParams('sellingMax', priceValuesHigh.sort((a: string, b: string) => Number(a) - Number(b)));

      this.selectedSellingPrice = this.carsFilterService.selectedPriceKeeper;
      this.carModel.selling = this.priceRange;

    } else {
      this.queryParamHelper.upsertParam('sellingMin', '');
      this.queryParamHelper.upsertParam('sellingMax', '');
      delete this.carModel.selling;
    }
  }

  private buildMileageHttpAndParams(): void {

    const mileage: Array<any> = [];

    for (const i of this.carsFilterService.selectedMileageKeeper) {
      mileage.push(i.replace(/\KM/g, '').replace(/\s/g, '').split('-'));
    }

    if (this.carsFilterService.selectedMileageKeeper.length > 0) {
      const mileageValuesLow: Array<string> = [];
      const mileageValuesHigh: Array<string> = [];
      this.mileageRange = [];

      mileage.forEach(e => {
        mileageValuesLow.push(e[0].toString());
        mileageValuesHigh.push(e[1]);
      });

      for (let i = 0; i < mileage.length; i++) {
        this.mileageRange.push({
          low: +mileageValuesLow[i],
          high: +mileageValuesHigh[i]
        });
      }
      this.queryParamHelper.upsertParams('mileMin', mileageValuesLow.sort((a: string, b: string) => Number(a) - Number(b)));
      this.queryParamHelper.upsertParams('mileMax', mileageValuesHigh.sort((a: string, b: string) => Number(a) - Number(b)));

      this.selectedMileage = this.carsFilterService.selectedMileageKeeper;

      this.carModel.miles = this.mileageRange;

    } else {
      this.queryParamHelper.upsertParam('mileMin', '');
      this.queryParamHelper.upsertParam('mileMax', '');
      delete this.carModel.miles;
    }
  }

  private getMakeParamForModel(params: any): void {
    if (params.has('make')) {
      this.carsFilterService.selectedMakeKeeper = params.get('make').split(',');
      this.buildMakeHttpAndQueryParams();
    }
  }

  private getSeriesParamForModel(params: any): void {
    if (params.has('series')) {
      this.carsFilterService.selectedSeriesKeeper = params.get('series').split(',');

      if (this.carsFilterService.selectedSeriesKeeper[0] === '') {
        this.carsFilterService.selectedSeriesKeeper.splice(0, 1);
      }
      this.buildSeriesHttpAndParams();
    }
  }

  private getBodyTypeParamForModel(params: any): void {
    if (params.has('bodyType')) {
      this.carsFilterService.selectedBodyTypeKeeper = params.get('bodyType').split(',');
      this.buildBodyHttpAndQueryParams();
    }
  }

  private getTransmissionParamForModel(params: any): void {
    if (params.has('transmissions')) {
      this.revertTransmissionAbbreviation(this.carsFilterService.selectedTransmissionKeeper);
      this.carsFilterService.selectedTransmissionKeeper = params.get('transmissions').split(',');
      this.buildTransmissionHttpAndQueryParams();
    }
  }

  private getColorParamForModel(params: any): void {
    if (params.has('baseColor')) {
      this.carsFilterService.selectedColorKeeper = params.get('baseColor').split(',');
      this.carsFilterService.selectedColorChipsKeeper = params.get('baseColor').split(',');
      this.buildColorHttpAndQueryParams();
    }
  }

  private getYearParamForModel(params: any): void {
    if (params.has('modelYear')) {
      this.carsFilterService.selectedYearKeeper = params.get('modelYear').split(',').map(Number);
      this.buildYearHttpAndQueryParams();
    }
  }

  private getRegionParamForModel(params:any): void {
    if (params.has('region')) {
      this.carsFilterService.selectedRegionKeeper = params.get('region').split(',');
      this.buildRegionHttpAndQueryParams();
    }
  }

  private getSellingParamForModel(params: any): void {
    let sellingMin: number[] =[];
    let sellingMax: number[] = [];
    if (params.has('sellingMin') && params.has('sellingMax')) {
      sellingMin = params.get('sellingMin').split(',');
      sellingMax = params.get('sellingMax').split(',');

      sellingMin.forEach((price, index) => {
        if (sellingMax[index] === undefined){
          sellingMax[index] = 500000000
        }
        if (!this.carsFilterService.selectedPriceKeeper.includes(`R${price} - R${+sellingMax[index]}`)){
          this.carsFilterService.selectedPriceKeeper.push(`R${price} - R${+sellingMax[index]}`);
        }
      });
      this.buildPriceHttpAndParams();

    } else if (params.has('sellingMin')) {
      const sellingMin = params.get('sellingMin').split(',');

      sellingMin.forEach((price: number) => {
        if (!this.carsFilterService.selectedPriceKeeper.includes(`R${price}`)){
          this.carsFilterService.selectedPriceKeeper.push(`R${price}`);
        }
      });
      this.buildPriceHttpAndParams();
    }
  }

  private getMileageParamForModel(params: any): void {
    let mileMin: number[] = [];
    let mileMax: number[] = [];
    if (params.has('mileMin') && params.has('mileMax')) {
      mileMin = params.get('mileMin').split(',');
      mileMax = params.get('mileMax').split(',');

      mileMin.forEach((mileage, index) => {
        if (mileMax[index] === undefined){
          mileMax[index] = 10000000;
        }
        if (!this.carsFilterService.selectedMileageKeeper.includes(`${+mileage} KM - ${+mileMax[index]} KM`)){
          this.carsFilterService.selectedMileageKeeper.push(`${+mileage} KM - ${+mileMax[index]} KM`);
        }
      });
      this.buildMileageHttpAndParams();
    } else if(params.has('mileMin')){
      mileMin = params.get('mileMin').split(',');

      mileMin.forEach((mileage) => {
        this.carsFilterService.selectedMileageKeeper.push(`${+mileage} KM`);
      });
      this.buildMileageHttpAndParams();
    }
  }

  // HTTP PARAMS SECTION END
  public pageChange(newPage: PaginatorState): void {

    this.first = newPage.first;
    this.rows = newPage.rows;
    const cleanUrl = this.router.url.replace(/\?.*/g, '');
    const page = newPage.page + 1;
    const perPage = 24;
    const top = perPage;
    const skip = perPage * (page - 1);
    const order = this.sortView();

    this.carsFilterService.selectedTop = top;
    this.carsFilterService.selectedSkip = skip;
    this.carsFilterService.selectedOrderBy = order;

    this.getPaginatedStock();
    let pageToString = newPage.page + 1;

    this.queryParamHelper.upsertParam('page', pageToString.toString());
    this.location.go(cleanUrl, this.queryParamHelper.uriQueryParams);

    this.pageConfig.currentPage = newPage.page;
    this.scrollToTop();
  }

  private scrollToTop(): void {
    document.querySelector('html').scrollIntoView();
  }


  private mobileCheckForFilter(): void {
    if (window.screen.width < 768) {
      this.mobileFilter = true;
      this.listView = false;
    }
  }

  public displayMobileFilter(): void {
    this.carsFilterService.filterDisplay = !this.carsFilterService.filterDisplay;
  }

  private createNewQueryParamInstance(params: any): void {

    if (params.keys.length !== 0) {
      const queryParamString = window.location.search.replace(/[?]/g, '');
      this.queryParamHelper = new QueryParamHelper(queryParamString);
    } else {
      this.carsFilterService.selectedMakeKeeper = [];
      this.carsFilterService.selectedColorKeeper = [];
      this.carsFilterService.selectedColorChipsKeeper = [];
      this.queryParamHelper = new QueryParamHelper(); // new empty httpParams instance
    }
  }

  public sortView() {

    switch (this.carsFilterService.sortingVal) {
      case 'LATEST' || 'imageDsc':
        this.carsFilterService.selectedOrderBy = 0;
        return 0;
        break;
      case 'PRICE: LOW TO HIGH':
        this.carsFilterService.selectedOrderBy = 1;
        return 1;
        break;
      case 'PRICE: HIGH TO LOW':
        this.carsFilterService.selectedOrderBy = 2;
        return 2;
        break;
      default:
        this.carsFilterService.selectedOrderBy = 0;
        return 0;
    }
    this.sortActiveItem = this.vehicleSortDesktop[0];

  }

  public sortViewButton(event: any) {
    if (event.target?.textContent){
      this.carsFilterService.sortingVal = event.target.textContent;
    }

    this.page = 1;
    const perPage = 24;
    const top = perPage;
    const skip = perPage * (this.page - 1);

    this.carsFilterService.selectedTop = top;
    this.carsFilterService.selectedSkip = skip;

    this.sortView();

    this.getPaginatedStock();
    this.scrollToTop();
    this.pageConfig.currentPage = 1;
    this.loadFirstPage();
  }

  public loadFirstPage(): void{
    this.first = 0;
    this.rows = 24;
  }

  private getPaginatedStock(): void {
    this.carsFilterService.getStocksByPagination().subscribe(http => {
      this.limitCars = http['stocks'];
    });
  }

  public remove<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    const index2 = this.carsFilterService.selectedSeriesKeeper.indexOf(toRemove['series']);
    if (index !== -1) {
      array.splice(index, 1);
    }
    if (index2 !== -1) {
      this.carsFilterService.selectedSeriesKeeper.splice(index2, 1);
    }
  }

  public checkShowroom() {
    const pathName = location.pathname;
    if (pathName.search('/showroom') >= 0) {
      this.setMetaData();
    }
  }

  private fixHttpImages(car: any) {
    for (const prop in car) {
      if (prop.startsWith('url')) {
        this.limitCars.push(car[prop].replace(/^http:\/\//i, 'https://'));
      }
    }
  }

  public async filterReset(): Promise<void> {
    this.loading = true;
    this.clearSearch();

    this.loadFirstPage();

    this.clearFilterDropdownValues();
    this.location.replaceState(this.location.path().split('?')[0], '');
    await this.router.navigate(['/showroom'], {});

    const page = 1;
    const perPage = 24;
    const top = perPage;
    const skip = perPage * (page - 1);
    const order = this.sortView();

    this.carsFilterService.selectedTop = top;
    this.carsFilterService.selectedSkip = skip;
    this.carsFilterService.selectedOrderBy = order;

    this.carsFilterService.getStocksByPagination().subscribe(http => {
      this.limitCars = http['stocks'];
      this.stockCount = http['count'];
      this.allCars = Array.from(Array(http['count']).keys());
      this.loading = false;
    });

    this.checkShowroom();

  }

  public clearFilterDropdownValues() {

    this.carsFilterService.selectedMakeKeeper = [];
    delete this.carModel.make;

    this.carsFilterService.selectedSeriesKeeper = [];
    delete this.carModel.masterModel;

    this.carsFilterService.selectedBodyTypeKeeper = [];
    delete this.carModel.customBodyType;

    this.carsFilterService.selectedTransmissionKeeper = [];
    delete this.carModel.transmission;

    this.carsFilterService.selectedColorKeeper = [];
    this.carsFilterService.selectedColorChipsKeeper = [];
    delete this.carModel.baseColor;

    this.carsFilterService.selectedYearKeeper = [];
    delete this.carModel.modelYear;

    this.carsFilterService.selectedRegionKeeper = [];
    delete this.carModel.region;

    this.carsFilterService.selectedMileageKeeper = [];
    delete this.carModel.selling;

    this.carsFilterService.selectedPriceKeeper = [];

    this.selectedMakes = [];
    this.selectedModels = [];
    this.selectedBodyTypes = [];
    this.selectedTransmission = [];
    this.selectedModelYears = [];
    this.selectedColors = [];
    this.selectedRegions = [];

    this.selectedSellingPrice = [];
    this.selectedMileage = [];

    delete this.carModel.selling;

    delete this.carModel.miles;
  }

  private fixAbbreviation(values: Array<string>): Array<string> {
    const newArray = [];
    for (const item of values) {
      if (item === 'A') {
        newArray.push('Automatic');
      } else if (item === 'M') {
        newArray.push('Manual');
      }
    }
    return newArray;
  }

  private revertTransmissionAbbreviation(values: Array<string>) {
    const newArray = [];
    for (const item of values) {
      if (item === 'Automatic') {
        newArray.push('A');
      } else {
        newArray.push('M');
      }
    }
    return newArray;
  }

  private setMetaData() {
    this.title.setTitle('New and Used Cars for Sale');
    this.meta.addTags([
      {name: 'title', content: 'New and Used Cars for Sale'},
      {
        name: 'description',
        content: 'Browse through over 9000 premium new and used cars for sale in the Western Cape. ' +
          'The Car Place only sells quality cars from trustworthy dealerships. Shop the best deals now.'
      },
      {
        name: 'keywords',
        content: 'the car place, used cars for sale, new cars for sale, western cape, car dealerships, low prices on cars for sale, ' +
          'car deals, audi, mercedes benz, toyota, suzuki, citroen, ford, bmw, honda, peugeot, isuzu, volkswagen'
      },
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {property: 'og:title', content: 'New and Used Cars for Sale'},
      {
        property: 'og:description',
        content: 'Browse through over 9000 premium new and used cars for sale in the Western Cape. ' +
          'The Car Place only sells quality cars from trustworthy dealerships. Shop the best deals now.'
      },
      {rel: 'canonical', href: 'https://thecarplace.co.za/showroom'},

      {property: 'og:url', content: window.location.href},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'New and Used Cars for Sale'},
      {
        name: 'twitter:description',
        content: 'Browse through over 9000 premium new and used cars for sale in the Western Cape. ' +
          'The Car Place only sells quality cars from trustworthy dealerships. Shop the best deals now.'
      },
      {name: 'twitter:domain', content: window.location.href},
    ]);
  }
}
