<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand"
    [routerLink]="['/home']">
    <img [src]="logo"
      class="logo img-fluid"
      width="400"
      height="119"
      alt="the car place">
  </a>
  <span class="nav_buttons">
    <button type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            class="navbar-toggler"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <fa-icon [icon]="menuIcon"
          style="font-size:1.9rem;color:#007b9a;"></fa-icon>
      </span>
    </button>
  </span>
  <div class="collapse navbar-collapse"
       id="navbarNav">
    <app-nav-links></app-nav-links>
  </div>
</nav>
