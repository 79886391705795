import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from "primeng/api";
import {SelectItem} from "primeng/api";
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {CarsFilterService} from '../../../services/cars-filter.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalDataService} from '../../../services/local-data.service';
import {DealershipData} from '../../../models/dealership-data';
import {transProducts, Product} from '../../../models/transunion-products';
import {NgForm} from "@angular/forms";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-transunion',
  templateUrl: './transunion.component.html',
  styleUrls: ['./transunion.component.scss']
})

export class TransunionComponent implements OnInit {

  @ViewChild('step1form') step1form!: NgForm;
  @ViewChild('step4Form') step4Form!: NgForm;

  public stepTitles: MenuItem[];
  public activeIndex = 0;
  public infoIcon = faInfoCircle;
  public screenwidth550 = false;
  public spinner = false;
  public allDealerships: DealershipData[];
  public alldealersCompnames: SelectItem[] = [];
  public alldealersTradingNames: SelectItem[] = [];

// form 1
// Company Details
  public dealerRegNumber = '';
  public dealerAuthLevel = '';
  public dealerVATNumber = '';
  public dealerTradingName: any;
  public dealerTelNumber = '';
  public dealercontactOneEmailAddress = '';
  public dealeraddressLine1 = '';
  public dealerCompanyName = '';
  public dealerNameSurname = '';
  public dealerContactNumber = '';
  public dealerEmailAddress = '';

// form 2
  public transProducts: Product[];
  public packagePrices: number[] = [];
  public btnShow = false;
  public formDetails = {};

// form 2 - packages form
  public selectedTransunionProducts = [];
  public myPriceList = [0, 0, 0, 0, 0];
  public totalSelectedPrices = '0';
  public checkboxDisabledStates: boolean[] = [];

// form 3
  public popupTC = false;
  public form3Btn = false;

// form 4
  public debitRadioSelect: string = '';
  public debitOrderFillIn = false;
  public debitAccountName = '';
  public debitBankName = '';
  public debitBranchNameTown = '';
  public debitBranchNumber = '';
  public debitAccountNumber = '';
  public debitTypeAccount = '';
  public disabledClickForm1: boolean;
  public disabledClickForm4: boolean;
  public filteredNames: any[];

  constructor(public carsFilterService: CarsFilterService,
              public localData: LocalDataService,
              public http: HttpClient) {
  }

  public async ngOnInit() {
    this.carsFilterService.getDealershipDetails().subscribe((dealerhsip) => {
      this.allDealerships = dealerhsip['stocks'];


      this.alldealersTradingNames = [
        {label: 'Select a Trading Name',
          value: null,
          disabled: true}
      ];

      this.alldealersCompnames = [
        {label: 'Select a Company Name',
          value: null,
          disabled: true}
      ];


      for (const item of this.allDealerships) {
        this.alldealersCompnames.push({
          label: item.companyName,
          value: item.companyName
        });

        this.alldealersTradingNames.push({
          label: item.tradingName,
          value: item.tradingName
        });
      }
    });

    if (window.screenX <= 550) {
      this.screenwidth550 = true;
    }

    this.selectedTransunionProducts = [
      {checked: false, description: 'Digital Dealer Guide', package: '', price: 0},
      {checked: false, description: 'Verification & Accident Report', package: '', price: 0}
    ];

    this.transProducts = transProducts;

    this.transProducts.forEach(() => {
      this.checkboxDisabledStates.push(true);
    });
    this.stepTitles = [
      {label: 'Company Details'},
      {label: 'TransUnion Products'},
      {label: 'Legal'},
      {label: 'Debit order Info'},
      {label: 'Congratulations'}
    ];
  }

  public async compChange(event: any) {
    if (typeof event === "object"){
      this.dealerCompanyName = event.companyName;
      this.dealerTradingName = event.tradingName;
      this.dealerTelNumber = event.contactOneWorkNumber.split(' ').join('');
      this.dealercontactOneEmailAddress = event.contactOneEmailAddress.toLowerCase();
      this.dealeraddressLine1 = event.addressLine1;
    }

    if (this.dealerCompanyName === '' || this.dealerTradingName === ''){
      this.dealerCompanyName = '';
      this.dealerTradingName ='';
      this.dealerTelNumber = '';
      this.dealercontactOneEmailAddress = '';
      this.dealeraddressLine1 = '';
    }

  }

  public checkForValidForm(formName: string){
    switch (formName){
      case 'Step1':

        if(!this.step1form.valid){
          this.disabledClickForm1 = true
        } else{
          this.stepSubmit('Step1').then();
        }
        break;
      case 'Step2':
        let hasPackage = false;
        this.selectedTransunionProducts.forEach(item => {
          if (item.package !== '' &&
            item.package !== 'No package' &&
            item.package !== 'Do not Add VAR' &&
            item.package !== 'Do not Add MPI'
          ){
            hasPackage = true;
          }
        })
        if (hasPackage){
          this.stepSubmit('Step2').then();
        } else {
          alert('Please select a package');
        }
        break;
      case 'Step4':
        if(!this.step4Form.valid){
          this.disabledClickForm4 = true
        } else{
          this.stepSubmit('Step4').then();
        }
    }
  }



  public async stepSubmit(event: string) {

    switch (event) {
      case 'Step1':
        this.formDetails = {
          notification: {
            Subject: 'TransUnion Step 1 Completed - ' + this.dealerCompanyName,
            Message:
              '<div style="font-family: Poppins, sans-serif;font-size: 18px;margin:30px">'
              + '<strong style="font-family: Poppins, sans-serif;color: #1a87a2;font-size: 20px">' +
              'Dealership Details:</strong>'
              + '<br/> Company Name: ' + this.dealerCompanyName
              + '<br/> Company Trading Name: ' + this.dealerTradingName
              + '<br/> Company Registration Number: ' + this.dealerRegNumber
              + '<br/> Company VAT Number: ' + this.dealerVATNumber
              + '<br/> Company Tel Number: ' + this.dealerTelNumber
              + '<br/> Company Email: ' + this.dealercontactOneEmailAddress
              + '<br/> Company Address: ' + this.dealeraddressLine1
              + '<br/><br/> ' +
              ' <strong style="font-family: Poppins, sans-serif;color: #1a87a2;font-size: 20px">' +
              'Authorised Person Details:</strong>'
              + '<br/> Auth Level: ' + this.dealerAuthLevel
              + '<br/> Name and Surname: ' + this.dealerNameSurname
              + '<br/> Contact Number: ' + this.dealerContactNumber
              + '<br/> Email Address: ' + this.dealerEmailAddress
              + '</div>',
          }
        };
        break;
      case 'Step2':
        this.formDetails = {
          notification: {
            Subject: 'TransUnion Step 2 Completed - ' + this.dealerCompanyName,
            Message: '<div style="font-family: Poppins, sans-serif;font-size: 18px;margin:30px">' +
              '<strong style="color: #1a87a2;font-size: 20px">Product Details:</strong>' +
              '<table cellspacing="0" style="font-family: Poppins, sans-serif;font-size: 18px;width: 100%;"><tr>' +
              '<th style="border: 2px solid black;padding:3px;width:250px">Product</th>' +
              '<th style="border: 2px solid black;padding:3px">Package</th>' +
              '<th style="border: 2px solid black;padding:3px;width:200px">Price</th>' +
              '</tr>' +
              this.selectedTransunionProducts.filter(item => item.package !== '' &&
                item.package !== 'No package' &&
              item.package !== 'Do not Add VAR' &&
              item.package !== 'Do not Add MPI')
                .map(item =>
                  `<tr><td style="border: 1px solid black;padding:2px">${item.description}</td>
                <td style="border: 1px solid black;padding:2px">${item.package}</td>
                <td style="border: 1px solid black;padding:2px">R ${item.price}</td></tr>`
                ).join('') +
              '</table>' +
              '</div>',
          }
        };
        break;
      case 'Step3':
        this.formDetails = {
          notification: {
            Subject: 'TransUnion Step 3 Completed - ' + this.dealerCompanyName,
            Message: '<div style="font-family: Poppins, sans-serif;font-size: 18px;margin:30px">' +
              '<strong style="color: #1a87a2;font-size: 20px">Terms and Conditions:</strong><br/>' +
              this.dealerNameSurname + ' has accepted the Terms and Conditions' +
              '</div>',
          }
        };
        break;
      case 'Step4':
        let message: string;
        if (this.debitRadioSelect.includes('Use different banking details')) {
          message =
            '<div style="font-family: Poppins, sans-serif;font-size: 18px;margin:30px">'
            + '<strong style="color: #1a87a2;font-size: 20px">Debit Account Information:</strong>'
            + '<br/> Debit Account Name: ' + this.debitAccountName
            + '<br/> Debit Bank Name: ' + this.debitBankName
            + '<br/> Debit Branch Name & Town: ' + this.debitBranchNameTown
            + '<br/> Debit Branch Number: ' + this.debitBranchNumber
            + '<br/> Debit Account Number: ' + this.debitAccountNumber
            + '<br/> Debit Account Type: ' + this.debitTypeAccount
            + '</div>';
        } else {
          message = '<div style="font-family: Poppins, sans-serif;font-size: 18px;margin:30px">'
            + '<strong style="color: #1a87a2;font-size: 20px">Debit Account Information:</strong>'
            + '<br/> Use same banking details we use for our VMG Subscription'
            + '</div>';
        }
        this.formDetails = {
          notification: {
            Subject: 'TransUnion Step 4 Completed - ' + this.dealerCompanyName,
            Message: message,
          }
        };
        break;
    }
    this.spinner = true;
    const dataString = JSON.stringify(this.formDetails);
    return this.http.post(`${environment.emailVehicleEnquiriesBaseUrl}/3`, dataString, {
      headers: this.httpHeaders()})
      .subscribe(() => {
        this.nextStep();
        this.spinner = false;
        const topSteps = document.getElementById('steps');
        topSteps.scrollIntoView();
      }, error => {
        this.spinner = false;
        alert('Could not continue to next step. Please get in contact with vmg for further instructions');

      })
  }

  public httpHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
  }

  public dealerNameFilter(event: any, field: string){
    let dealerQuery = event.query;
    let filteredDealers = [];

    if (field === 'dealerTradingName'){
      this.filteredNames = [];

      this.allDealerships.forEach(item => {
        if (item.tradingName.toLowerCase().includes(dealerQuery.toLowerCase())){
          filteredDealers.push(item);
        }
      })
    }
    else if (field === 'dealerCompanyName'){
      this.filteredNames = [];

      this.allDealerships.forEach(item => {
        if (item.companyName.toLowerCase().includes(dealerQuery.toLowerCase())){
          filteredDealers.push(item);
        }
      })
    }

    this.filteredNames = filteredDealers;
  }

  public nextStep() {
    this.activeIndex += 1;
    let items = document.querySelectorAll('p-steps .p-steps-item');

    // Find the index of the list item with the "current" class
    let currentIndex = -1;
    items.forEach((item, index) => {

      if (item.classList.contains('p-steps-current')) {
        currentIndex = index + 1;
      }
    });

    // Change classes of items before the current one
    if (currentIndex !== -1) {
      for (let i = 0; i < currentIndex; i++) {
        items[i].classList.add('completed-item');
      }
    }
  }

  public async onPackageChange(event:any, rowIndex: number) {
    const checkedPrices = [];
    this.selectedTransunionProducts[rowIndex].package = event.value.transaction;
    this.selectedTransunionProducts[rowIndex].price = event.value.price;
    this.packagePrices[rowIndex] = event.value.price;
    this.checkboxDisabledStates[rowIndex] = false;
    this.myPriceList[rowIndex] = this.packagePrices[rowIndex];
    this.selectedTransunionProducts.forEach(prices => {
        checkedPrices.push(prices.price);
    });
    this.totalSelectedPrices = checkedPrices.reduce((a, b) => a + b, 0);
  }

  public tcCheck() {
    this.form3Btn = !this.form3Btn;
  }

  public radioSelect() {

    this.debitOrderFillIn = this.debitRadioSelect === 'Use different banking details';
  }

}
