import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ShowroomData} from '../models/showroom-data';
import {DealershipData} from '../models/dealership-data';
import {AllCarData} from '../models/all-car-data';
import {Observable, retry} from 'rxjs';
import { delay, map, take} from 'rxjs/operators';
import * as _ from 'underscore';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CarsFilterService {

  public selectedMakeKeeper: string[] = [];
  public selectedSeriesKeeper: string[] = [];
  public selectedBodyTypeKeeper: string[] = [];
  public selectedTransmissionKeeper: string[] = [];
  public selectedColorKeeper: string[] = [];
  public selectedRegionKeeper: string[] = [];
  public selectedYearKeeper: string[] = [];
  public selectedPriceKeeper: string[] = [];
  public selectedMileageKeeper: string[] = [];
  public selectedTop: number;
  public selectedOrderBy: number;
  public selectedCompanyOldId = 0;
  public selectedSkip: number;
  public editedColorKeeper: string[] = [];
  public selectedColorChipsKeeper: string[] = [];
  public filterDisplay: boolean;
  public isOtherSelected: boolean;
  public otherColorString: string;
  public sortingVal = 'imageDsc';

  constructor(
    private http: HttpClient
  ) {
  }

  //cfc Base URL
  public capitecSearchCompany(): string {
    return `${this.capitecBaseUrl()}/Search?`;
  }
  public capitecFilterValues(): string {
    return `${this.capitecBaseUrl()}/Filter/`;
  }
  public capitecCarApiODATA(): string {
    return `${this.capitecBaseUrl()}/VehicleDetails/`;
  }

  //tcp Base URL
  public carplaceSearchCompany(): string {
    return `${this.carplaceBaseUrl()}/Search?`;
  }
  public carApiODATA(): string {
    return `${this.carplaceBaseUrl()}/VehicleDetails/`;
  }
  public dealershipApiODATA(): string {
    return `${this.carplaceBaseUrl()}/Branches`;
  }

  //carplace Stock Url and Filters
  public carplaceStock(): string {
    return `${this.carplaceBaseUrl()}`;
  }
  public filterValues(): string {
    return `${this.carplaceBaseUrl()}/filter/`;
  }

  //capitec cars ODATA
  public capitecCarsApiODATA(): string {
    return `${this.capitecBaseUrl()}`;
  }

  public capitecBaseUrl(): string {
    return environment.cfcBaseUrl;
  }
  public carplaceBaseUrl(): string {
    return environment.tcpBaseUrl;
  }


  public getListAllFilterValuesForDataKeeper(query: string): Observable<Array<string>> {

    this.editedColorKeeper = _.clone(this.selectedColorKeeper);
    this.selectedRegionKeeper.toString().replace(/^\s+|\s+$/g, '');
    this.selectedYearKeeper.reduce((acc, val) => acc.concat(val), []);

    const params = new URLSearchParams();
    params.set('query', query);

    for (const i in this.editedColorKeeper) {
      if (this.editedColorKeeper[i] === 'Other') {
        this.editedColorKeeper.splice(this.editedColorKeeper.indexOf(i), 1);
      }
    }

    return this.http.post<Array<string>>(this.filterValues() + query,
      {
        makes: this.selectedMakeKeeper,
        masterModels: this.selectedSeriesKeeper,
        customBodyTypes: this.selectedBodyTypeKeeper,
        modelYears: this.selectedYearKeeper,
        colours: this.selectedColorKeeper,
        regions: this.selectedRegionKeeper,
        transmissions: this.selectedTransmissionKeeper,
        priceRanges: this.selectedPriceKeeper,
        mileages: this.selectedMileageKeeper
      },
    ).pipe(map(response => response));
  }

  public getStocksByPagination(): Observable<Array<ShowroomData>> {

    this.editedColorKeeper = _.clone(this.selectedColorKeeper);

    this.selectedRegionKeeper.toString().replace(/^\s+|\s+$/g, '');
    this.selectedYearKeeper.reduce((acc, val) => acc.concat(val), []);

    for (const i in this.editedColorKeeper) {
      if (this.editedColorKeeper[i] === 'Other') {
        this.editedColorKeeper.splice(this.editedColorKeeper.indexOf(i), 1);
      }
    }

    return this.http.post<Array<ShowroomData>>(this.carplaceStock(),
      {
        makes: this.selectedMakeKeeper,
        masterModels: this.selectedSeriesKeeper,
        customBodyTypes: this.selectedBodyTypeKeeper,
        modelYears: this.selectedYearKeeper,
        colours: this.selectedColorKeeper,
        regions: this.selectedRegionKeeper,
        transmissions: this.selectedTransmissionKeeper,
        priceRanges: this.selectedPriceKeeper,
        mileages: this.selectedMileageKeeper,
        skip: this.selectedSkip,
        top: this.selectedTop,
        orderBy: this.selectedOrderBy,
        companyOldId: this.selectedCompanyOldId
      },
    ).pipe(map(response => response), retry({
        delay: (errors) => errors.pipe(delay(5000), take(5))
    }) );
  }

  public getStocksByPaginationForCapitec(): Observable<Array<ShowroomData>> {

    this.editedColorKeeper = _.clone(this.selectedColorKeeper);

    this.selectedRegionKeeper.toString().replace(/^\s+|\s+$/g, '');
    this.selectedYearKeeper.reduce((acc, val) => acc.concat(val), []);

    for (const i in this.editedColorKeeper) {
      if (this.editedColorKeeper[i] === 'Other') {
        this.editedColorKeeper.splice(this.editedColorKeeper.indexOf(i), 1);
      }
    }

    return this.http.post<Array<ShowroomData>>(this.capitecCarsApiODATA(),
      {
        makes: this.selectedMakeKeeper,
        masterModels: this.selectedSeriesKeeper,
        customBodyTypes: this.selectedBodyTypeKeeper,
        modelYears: this.selectedYearKeeper,
        colours: this.selectedColorKeeper,
        regions: this.selectedRegionKeeper,
        transmissions: this.selectedTransmissionKeeper,
        priceRanges: this.selectedPriceKeeper,
        mileages: this.selectedMileageKeeper,
        skip: this.selectedSkip,
        top: this.selectedTop,
        orderBy: this.selectedOrderBy
      },
    ).pipe(map(response => response), retry({
        delay: errors => errors.pipe(delay(5000), take(5))
    }));
  }

  public getListAllCapitecFilterValuesForDataKeeper(query: string): Observable<Array<string>> {

    this.editedColorKeeper = _.clone(this.selectedColorKeeper);

    this.selectedRegionKeeper.toString().replace(/^\s+|\s+$/g, '');
    this.selectedYearKeeper.reduce((acc, val) => acc.concat(val), []);

    const params = new URLSearchParams();
    params.set('query', query);

    for (const i in this.editedColorKeeper) {
      if (this.editedColorKeeper[i] === 'Other') {
        this.editedColorKeeper.splice(this.editedColorKeeper.indexOf(i), 1);
      }
    }

    return this.http.post<Array<string>>(this.capitecFilterValues() + query,
      {
        makes: this.selectedMakeKeeper,
        masterModels: this.selectedSeriesKeeper,
        customBodyTypes: this.selectedBodyTypeKeeper,
        modelYears: this.selectedYearKeeper,
        colours: this.selectedColorKeeper,
        regions: this.selectedRegionKeeper,
        transmissions: this.selectedTransmissionKeeper,
        priceRanges: this.selectedPriceKeeper,
        mileages: this.selectedMileageKeeper
      },
    ).pipe(map(response => response), retry({
        delay: errors => errors.pipe(delay(5000), take(5))
    }));
  }

  public getCar(car: AllCarData): Observable<AllCarData> {
    let filterString = '';
    if (car.id !== null) {
      filterString = this.queryStringForCar(car);  // TEMP
    }
    return this.http.get<AllCarData>(this.carApiODATA() + filterString, {
      headers: new HttpHeaders({
        'platform-source': 'thecarplace'
      })
    });
  }

  public getCapitecCar(car: AllCarData): Observable<AllCarData> {
    let filterString = '';
    if (car.id !== null) {
      filterString = this.queryStringForCar(car);  // TEMP
    }
    return this.http.get<AllCarData>(this.capitecCarApiODATA() + filterString, {
      headers: new HttpHeaders({
        'platform-source': 'thecarplace'
      })
    });
  }

  public getDealershipDetails(): Observable<DealershipData> {
    return this.http.get<DealershipData>(this.dealershipApiODATA())
      .pipe(map(response => response));
  }

  private queryStringForCar(car: AllCarData): string {
    return `${car.id}`;
  }

  public filterReferenceNumber(companyRef: number = 0, idRef: number = 0): Observable<Array<ShowroomData>> {
    return this.http.get<Array<ShowroomData>>(this.capitecSearchCompany() + this.buildQuery(companyRef, idRef));
  }

  public filterReferenceNumberCarPlace(companyRef: number = 0, idRef: number = 0): Observable<Array<ShowroomData>> {
    return this.http.get<Array<ShowroomData>>(this.carplaceSearchCompany() + this.buildQuery(companyRef, idRef));
  }

  public buildQuery(companyRef: number, idRef: number): string {

    let query = '';
    if (companyRef !== 0) {
      query += `companyOldId=${companyRef}`;
    }

    if (idRef !== 0) {
      if (query.length > 0) {
        query += '&';
      }
      query += `oldId=${idRef}`;
    }
    return query;
  }
}
