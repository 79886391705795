<section class="page-container">
  <div id="hero">
    <div class="slider">
      <a [routerLink]="['/capitec-finance']" class="banner-redirect"></a>
      <img src="/assets/images/Home/home-banner.webp" alt="capitec-finance-banner" class="desktop" style="min-width: 100%;">
      <img src="/assets/images/Mobile/home-banner-mobile.webp" alt="capitec-finance-banner" class="mobile">
    </div>
  </div>

  <div class="md-content">
    <p-tabView (click)="getCountOfCapitecShowroom($event)" >
      <p-tabPanel header="SHOWROOM">
        <ng-template pTemplate="header">
          <i class="pi pi-car"></i>
          <span>SHOWROOM</span>
        </ng-template>
        <div class="home-body-container home_tab">
          <div class="container">
            <h2 style="text-transform: uppercase">Select by Category</h2>
            <div class="select-box-container">
              <h3 class="select-box-heading">Body Type</h3>
              <div class="row">
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Bakkie Double Cab,Bakkie Single Cab,Bakkie Club Cab', page: 1 }">
                    <figure>
                      <img [src]="truckImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Bakkies</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Convertible', page: 1 }">
                    <figure>
                      <img [src]="convertibleImage" width="149" height="55" alt="the car place" class="img-fluid">
                      <figcaption class="select-box-caption">Convertibles</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Coupe', page: 1 }">
                    <figure>
                      <img [src]="coupeImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Coupe's</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Hatchback', page: 1 }">
                    <figure>
                      <img [src]="hatchImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Hatchbacks</figcaption>
                    </figure>
                  </div>
                </div>

                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Sedan', page: 1 }">
                    <figure>
                      <img [src]="sedanImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Sedans</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Station Wagon', page: 1 }">
                    <figure>
                      <img [src]="wagonImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Station Wagons</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'SUV', page: 1 }">
                    <figure>
                      <img [src]="suvImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">SUV's</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ bodyType: 'Motorcycle', page: 1 }">
                    <figure>
                      <img [src]="bikeImage" width="87" height="57" alt="the car place">
                      <figcaption class="select-box-caption">Bikes</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="select-box-container">
              <h3 class="select-box-heading">Price Range</h3>
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 0, sellingMax: 50000, page: 1 }">
                    <span class="rand-symbol">R</span> 0 - 50 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft ">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 50001, sellingMax: 100000, page: 1 }">
                    <span class="rand-symbol">R</span> 50 001 - 100 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 100001, sellingMax: 150000, page: 1 }">
                    <span class="rand-symbol">R</span> 100 001 - 150 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 150001, sellingMax: 200000, page: 1 }">
                    <span class="rand-symbol">R</span> 150 001 - 200 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 200001, sellingMax: 300000, page: 1 }">
                    <span class="rand-symbol">R</span> 200 001 - 300 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 300001, sellingMax: 400000, page: 1 }">
                    <span class="rand-symbol">R</span> 300 001 - 400 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 400001, sellingMax: 500000, page: 1 }">
                    <span class="rand-symbol">R</span> 400 001 - 500 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/showroom']"
                       [queryParams]="{ sellingMin: 500000, sellingMax: 100000000, page: 1 }">
                    <span class="rand-symbol">R</span> 500 000 +
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="full-showroom-section">
            <h4 class="full-showroom-section-heading">Sometimes you just want to <span class="full-showroom-section-linebreak">See Everything</span>
            </h4>
            <button class="full-showroom-button"
                    [routerLink]="['/showroom']">BROWSE ALL <span class="vehicle_count">{{vehicleAutoComplete}}</span>
              VEHICLES
            </button>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="CAPITEC FINANCE SHOWROOM" >
        <ng-template pTemplate="header">
          <i class="pi pi-car"></i>
          <span>CAPITEC FINANCE SHOWROOM</span>
        </ng-template>
        <div class="home-body-container home_tab">
          <div class="container">
            <h2 style="text-transform: uppercase">Select by Category</h2>
            <div class="select-box-container">
              <h3 class="select-box-heading">Body Type</h3>
              <div class="row">
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Bakkie Double Cab,Bakkie Single Cab,Bakkie Club Cab', page: 1 }">
                    <figure>
                      <img [src]="truckImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Bakkies</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Convertible', page: 1 }">
                    <figure>
                      <img [src]="convertibleImage" width="149" height="55" alt="the car place" class="img-fluid">
                      <figcaption class="select-box-caption">Convertibles</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Coupe', page: 1 }">
                    <figure>
                      <img [src]="coupeImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Coupe's</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Hatchback', page: 1 }">
                    <figure>
                      <img [src]="hatchImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Hatchbacks</figcaption>
                    </figure>
                  </div>
                </div>

                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Sedan', page: 1 }">
                    <figure>
                      <img [src]="sedanImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Sedans</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Station Wagon', page: 1 }">
                    <figure>
                      <img [src]="wagonImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">Station Wagons</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'SUV', page: 1 }">
                    <figure>
                      <img [src]="suvImage" width="149" height="55" alt="the car place">
                      <figcaption class="select-box-caption">SUV's</figcaption>
                    </figure>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-3 padright padleft">
                  <div class="select-box"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ bodyType: 'Motorcycle', page: 1 }">
                    <figure>
                      <img [src]="bikeImage" width="87" height="57" alt="the car place">
                      <figcaption class="select-box-caption">Bikes</figcaption>
                    </figure>
                  </div>
                </div>

              </div>
            </div>
            <div class="select-box-container">
              <h3 class="select-box-heading">Price Range</h3>
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 0, sellingMax: 50000, page: 1 }">
                    <span class="rand-symbol">R</span>  0 - 50 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft ">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 50001, sellingMax: 100000, page: 1 }">
                    <span class="rand-symbol">R</span> 50 001 - 100 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 100001, sellingMax: 150000, page: 1 }">
                    <span class="rand-symbol">R</span> 100 001 - 150 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 150001, sellingMax: 200000, page: 1 }">
                    <span class="rand-symbol">R</span> 150 001 - 200 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 200001, sellingMax: 250000, page: 1 }">
                    <span class="rand-symbol">R</span> 200 001 - 250 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 250001, sellingMax: 300000, page: 1 }">
                    <span class="rand-symbol">R</span> 250 001 - 300 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 300001, sellingMax: 400000, page: 1 }">
                    <span class="rand-symbol">R</span> 300 001 - 400 000
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-3 padright padleft">
                  <div class="select-box price-bracket"
                       [routerLink]="['/capitec-finance']"
                       [queryParams]="{ sellingMin: 400001, sellingMax: 500000, page: 1 }">
                    <span class="rand-symbol">R</span> 400 001 - 500 000
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="full-showroom-section">
            <h4 class="full-showroom-section-heading">Sometimes you just want to <span class="full-showroom-section-linebreak">See Everything</span>
            </h4>
            <button class="full-showroom-button"
                    [routerLink]="['/capitec-finance']">BROWSE ALL <span class="vehicle_count">{{capitecVehicleAutoComplete}}</span>
              VEHICLES
            </button>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="SELL OR TRADE">
        <ng-template pTemplate="header">
          <i class="pi pi-money-bill"></i>
          <span>SELL OR TRADE</span>
        </ng-template>
        <div class="home_tab" >
          <app-sell-car-enquiry></app-sell-car-enquiry>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</section>
