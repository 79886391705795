import { Component } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dealer-faq',
  templateUrl: './dealer-faq.component.html',
  styleUrls: ['./dealer-faq.component.scss']
})
export class DealerFaqComponent {
  public chevronDown = faChevronDown;

}
