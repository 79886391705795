import { Component, OnInit } from '@angular/core';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLink, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { Meta, Title } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../../../services/article.service';
import {Article} from '../../../../models/article';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-news-advice-article',
  templateUrl: './news-advice-article.component.html',
  styleUrls: ['./news-advice-article.component.scss']
})
export class NewsAdviceArticleComponent implements OnInit {
  public article: Article | undefined;
  public safeHtml: any;
  public facebookLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';
  public twitterLink: string = 'https://www.twitter.com/share?url=';
  public whatsAppLink: string = 'https://api.whatsapp.com/send?text=';

  constructor(
    public faLibrary: FaIconLibrary,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public articleService: ArticleService,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private title: Title
  ) {
    faLibrary.addIcons(faFacebookF, faWhatsapp, faLink, faCheck);
  }

  async ngOnInit() {
    this.facebookLink += window.location.href;
    this.twitterLink += window.location.href;
    this.whatsAppLink += window.location.href;
    this.activatedRoute.queryParams.subscribe(params => {
      this.articleService.getArticle(+params.id)
        .subscribe((http) => {
          this.article = http;
          this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.article.htmlTemplate);
          this.article.dateCreated = new Date(this.article.dateCreated).toDateString();
          this.setMetaData();
        }, () => {
          window.location.pathname = "404";
        });
    });
  }


  private setMetaData() {
    this.title.setTitle(`The Car Place | ${this.article.heading}`);
    this.meta.addTags([
      {name: 'title', content: `The Car Place | ${this.article.heading}`},
      {name: 'description', content: `${this.article.description}`},
      {name: 'keywords', content: 'the car place, used cars for sale in western cape, car dealerships, car deals, bakkies, convertibles, coupes, hatchbacks, minivans, sedans, station wagons, suvs, cars for sale'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},
      {rel: 'canonical', href: `https://thecarplace.co.za${this.router.url}`},

      {property: 'og:title', content: `The Car Place | ${this.article.heading}`},
      {property: 'og:description', content: `${this.article.description}`},
      {property: 'og:url', content:  `https://thecarplace.co.za${this.router.url}`},
      {property: 'og:site_name', content: 'The Car Place'},
      {property: 'og:image', content: `${this.article.imageUrl}`},
      {property: 'og:image:url', content: `${this.article.imageUrl}`},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'The Car Place'},
      {property: 'og:type', content: 'article'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: `The Car Place | ${this.article.heading}`},
      {name: 'twitter:description', content: `${this.article.description}`},
      {name: 'twitter:domain', content: `https://thecarplace.co.za${this.router.url}`},
    ]);
  }

  protected readonly window = window;
}
