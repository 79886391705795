import {Component, OnInit} from '@angular/core';
import {ArticleService} from '../../../services/article.service';
import {Article} from '../../../models/article';

@Component({
  selector: 'app-news-advice',
  templateUrl: './news-advice.component.html',
  styleUrls: ['./news-advice.component.scss']
})
export class NewsAdviceComponent implements OnInit {
  public articles: Article[] = [];
  public article: Article;

  constructor(
    public articleService: ArticleService
  ) {
  }

  public async ngOnInit() {
   await this.getArticlesList();
  }

  public async getArticlesList() {
    this.articleService.getAllArticles()
      .subscribe(http => {
        this.articles = http;
        this.articles.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
    });
  }

  public getUrl(heading: string): string {
    return heading.replace(/\s+/g, '-').toLowerCase();
  }
}
