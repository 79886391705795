import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modelYear'
})
export class ModelYearPipe implements PipeTransform {

  public transform(items: any[], year: any): any {
    if (items.length <= 0 || !year) {
      return items;
    }

    let splicedValues: any[] = [];
    const index = items.indexOf(year);
    const arraySize = items.length;

    splicedValues = items.splice(index, arraySize);
    items.push(splicedValues);

    return items;
  }

}
