import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Article} from '../models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private articlesApi = 'https://carman-blogs-api.thecarplace.co.za/api/Articles/GetArticlesByView';
  private articleApi = 'https://carman-blogs-api.thecarplace.co.za/api/Articles/GetArticleByView';

  constructor(
    private http: HttpClient
  ) { }

  public getAllArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(this.articlesApi, {
      headers: new HttpHeaders({
        'platform-source': 'thecarplace'
      })
    })
      .pipe(map(response => response));
  }
  public getArticle(id: number): Observable<Article> {
    return this.http.get<Article>(this.articleApi + `?id=${id}`, {
      headers: new HttpHeaders({
        'platform-source': 'thecarplace'
      })
    })
      .pipe(map(response => response));
  }
}
