import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {CarsFilterService} from '../../services/cars-filter.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  logo = '../../../assets/images/new-logo.webp';

  menuIcon = faBars;
  filterIcon = faSearch;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private carsFilterService: CarsFilterService,
    public router: Router,
  ) {}

  public displayMobileFilter() {
    this.carsFilterService.filterDisplay = !this.carsFilterService.filterDisplay;
  }

}
