import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss']
})
export class NavLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public collapseMenu() {
    document.querySelector('.navbar-collapse').classList.remove('show');
  }

}
