import { HttpParams } from '@angular/common/http';

import { Filter } from '../models/filter';

export class QueryParamHelper {

  private _httpParams: HttpParams;
  private _filters = new Array<Filter>();

  public get uriQueryParams(): string {
    this.buildHttpParams();
    return this._httpParams.toString();
  }

  constructor(uriParams?: string) {
    this._httpParams = new HttpParams({ fromString: uriParams });
    this.populateFilters();
  }

  private populateFilters(): void {
    const keys: Array<string> = this._httpParams.keys() || new Array<string>();
    for (const key of keys) {
      const csv: string = this._httpParams.get(key) || '';
      const values: Array<string> = csv.split(',');
      this._filters.push({ key, values });
    }
  }

  public addParam(key: string, value: string): void {
    if (key === undefined || key === '' || value === undefined || value === '') {
      return;
    }

    const filter = this._filters.find(e => e.key.toString().toLowerCase() === key.toString().toLowerCase());
    if (filter !== undefined) {
      const existingValue = filter.values.find(e => e.toString().toLowerCase() === value.toString().toLowerCase());
      if (existingValue === undefined) {
        filter.values.push(value);
      }

      const csv: string = filter.values.join(',');
      this._httpParams = this._httpParams.set(filter.key, csv);
    } else {
      const values = new Array<string>();
      if (value !== undefined) {
        values.push(value);
      }

      this._filters.push({ key, values });
      this._httpParams = this._httpParams.set(key, value);
    }
  }

  public addParams(key: string, values: Array<string>): void {
    for (const value of values) {
      this.addParam(key, value);
    }
  }

  public updateParam(key: string, value: string): void {
    if (key === undefined || key === '') {
      return;
    }

    const index = this._filters.findIndex(e => e.key.toString().toLowerCase() === key.toString().toLowerCase());
    if (index >= 0) {
      const filter: Filter = this._filters[index];

      if (value !== undefined && value !== '') {
        const newValues = new Array<string>();
        newValues.push(value);

        filter.values = newValues;
        const csv: string = newValues.join(',');
        this._httpParams = this._httpParams.set(filter.key, csv);
      } else {
        this._filters.splice(index, 1);
        this._httpParams = this._httpParams.delete(filter.key);
      }
    }
  }

  public updateParams(key: string, values: Array<string>): void {
    if (key === undefined || key === '') {
      return;
    }

    const index = this._filters.findIndex(e => e.key.toString().toLowerCase() === key.toString().toLowerCase());
    if (index >= 0) {
      const filter: Filter = this._filters[index];
      const filteredValues: Array<string> = values.filter(item => item !== undefined && item !== '');

      if (filteredValues !== undefined && filteredValues.length > 0) {
        filter.values = filteredValues;
        const csv: string = filteredValues.join(',');
        this._httpParams = this._httpParams.set(filter.key, csv);
      } else {
        this._filters.splice(index, 1);
        this._httpParams = this._httpParams.delete(filter.key);
      }
    }
  }



  public upsertParam(key: string, value: string): void {
    if (key === undefined || key === '') {
      return;
    }

    const index = this._filters.findIndex(e => e.key.toString().toLowerCase() === key.toString().toLowerCase());
    if (index >= 0) {
      this.updateParam(key, value);
    } else {
      this.addParam(key, value);
    }
  }

  public upsertParams(key: string, values: Array<string>): void {
    if (key === undefined || key === '') {
      return;
    }

    const index = this._filters.findIndex(e => e.key.toString().toLowerCase() === key.toString().toLowerCase());
    if (index >= 0) {
      this.updateParams(key, values);
    } else {
      this.addParams(key, values);
    }
  }

  private buildHttpParams(): void {
    for (const filter of this._filters) {
      if (filter.key !== '' && filter.values.length > 0) {
        const csv: string = filter.values.join(',');
        this._httpParams = this._httpParams.set(filter.key, csv);
      }
    }
  }

  public removeAllWhitespaces(value: string): string {
    const regex: RegExp = /\s/g;
    return value.replace(regex, '');
  }
}
