import { Injectable } from "@angular/core";
@Injectable()
export class AllBrochureData {
  id: string;
  oldId: string;
  branchOldId: string;
  companyOldId: string;
  make: string;
  masterModel: string;
  code: string;
  vehicleDetailsId: number;
  mmCode: number;
  makeId: number;
  model: string;
  variant: string;
  vehicleType: string;
  bodyType: string;
  customBodyType: string;
  modelYear: string;
  used: string;
  sellingPrice: number;
  premium: number;
  mileage: number;
  color: string;
  condition: string;
  description: string;
  descriptionHtml: string;
  region: string;
  location: string;
  registrationNo: number;
  vinNo: number;
  engineNo: number;
  totalImages: number;
  dateCreated: string;
  branchId: string;
  images: [
    { imageUrl: string }
  ];
  branch: {
    oldId: string;
    companyName: string;
    tradingName: string;
    contactOneFirstName: string;
    contactOneEmailAddress: string;
    contactOneWorkNumber: string
    addressLine1: string;
    region: string;
    logoUrl: string;
    websiteUrl: string;
    facebookUrl: string;
    twitterUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
    whatsAppNo: string;
    tradingHours: [
      {
        days: string;
        times: string
      }
    ];
    latitude: number | string;
    longitude: number | string;
    city: string;
  };
  extras: [
    {
      name: string;
    }
  ];
  stockInformation: {
    height: number;
    width: number;
    length: number;
    fuelType: string;
    fuelTankSize: number;
    transmission: string;
    cooling: string;
    gears: number;
    seats: number;
    doors: string;
    cubicCapacity: number;
    kilowatts: number;
    axleConfiguration: string;
    tare: number;
    co2: number;
    gcm: number;
    gvm: number;
    frontTyreSize: string;
    rearTyreSize: string;
  };
  valueInKms?: string;
 tradingName: string;
}
