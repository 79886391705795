import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { LocalDataService } from '../../../services/local-data.service';
import { CarsFilterService } from '../../../services/cars-filter.service';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-dealer-registration',
  templateUrl: './dealer-registration.component.html',
  styleUrls: ['./dealer-registration.component.scss']
})
export class DealerRegistrationComponent implements OnInit{
  public attachments = {};
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#ffffff';
  public dealershipRegistrationFormHide = true;
  public checked: boolean;

  public dealershipRegistrationForm = new UntypedFormGroup({
    registeredName: new UntypedFormControl('', [Validators.required]),
    tradingName: new UntypedFormControl('', [Validators.required]),
    companyVATNumber: new UntypedFormControl('', [Validators.required]),
    companyRegNumber: new UntypedFormControl('', [Validators.required]),
    companyContactNumber: new UntypedFormControl('', [Validators.required]),
    primaryShowroomAddress1: new UntypedFormControl('', [Validators.required]),
    primaryShowroomAddress2: new UntypedFormControl('', ),
    suburb: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    province: new UntypedFormControl('', [Validators.required]),
    postCode: new UntypedFormControl('', [Validators.required]),

    ownersName: new UntypedFormControl('', [Validators.required]),
    ownersLastName: new UntypedFormControl('', [Validators.required]),
    ownersEmailAddress: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    ownersCellNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),

    uploaderName: new UntypedFormControl('', [Validators.required]),
    uploaderLastName: new UntypedFormControl('', [Validators.required]),
    uploaderEmailAddress: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    uploaderCellNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    termsConditions: new UntypedFormControl('', [Validators.required]),

  });

  public fileName: string;
  public filePreview: string;
  public cameraIcon = faCamera;

  constructor(
    private meta: Meta,
    private title: Title,
    private http: HttpClient,
    public localData: LocalDataService,
    public carsFilterService: CarsFilterService
  ) { }

  public ngOnInit() {
    this.setMetaData();
  }

  public async fileProgress(event) {
    if (event.files && event.files.length > 0) {
      for (const item of event.files) {
        const reader = new FileReader();
        const file = item;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name;
          this.filePreview = 'data:image/jpg' + ';base64,' + reader.result.toString().split(',')[1];
          this.attachments[this.fileName] = this.filePreview;
        };
      }
    }
  }

  public fileRemove(event) {
    const key = event.file.name;
    delete this.attachments[key];
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: 'customercare@thecarplace.co.za',
        CcList: 'info@thecarplace.co.za',
        BccList: 'webmaster@vmgsoftware.co.za',
        Subject: 'Dealership Registration',
        Message: '\nCompany Details: '
          + '\nRegistered Name: ' + this.dealershipRegistrationForm.value.registeredName
          + '\nTrading Name: ' + this.dealershipRegistrationForm.value.tradingName
          + '\nCompany VAT Number: ' + this.dealershipRegistrationForm.value.companyVATNumber
          + '\nCompany Reg Number: ' + this.dealershipRegistrationForm.value.companyRegNumber
          + '\nCompany Contact Number: ' + this.dealershipRegistrationForm.value.companyContactNumber
          + '\nPrimary Showroom Address 1: ' + this.dealershipRegistrationForm.value.primaryShowroomAddress1
          + '\nPrimary Showroom Address 2: ' + this.dealershipRegistrationForm.value.primaryShowroomAddress2
          + '\nSuburb: ' + this.dealershipRegistrationForm.value.suburb
          + '\nCity: ' + this.dealershipRegistrationForm.value.city
          + '\nProvince: ' + this.dealershipRegistrationForm.value.province
          + '\nPost Code: ' + this.dealershipRegistrationForm.value.postCode
          + '\n\nOwners Contact Details: '
          + '\nOwners Name: ' + this.dealershipRegistrationForm.value.ownersName
          + '\nOwners Surname: ' + this.dealershipRegistrationForm.value.ownersLastName
          + '\nOwners Email Address: ' + this.dealershipRegistrationForm.value.ownersEmailAddress
          + '\nOwners Cell Number: ' + this.dealershipRegistrationForm.value.ownersCellNumber
          + '\n\nPrimary Uploader Details: '
          + '\nUploader Name: ' + this.dealershipRegistrationForm.value.ownersName
          + '\nUploader Surname: ' + this.dealershipRegistrationForm.value.ownersLastName
          + '\nUploader Email Address: ' + this.dealershipRegistrationForm.value.ownersEmailAddress
          + '\nUploader Cell Number: ' + this.dealershipRegistrationForm.value.ownersCellNumber,
        MessageSubject: 'Dealership Registration',
        ToName: this.localData.store.eMail.contactMailName,
        FromName: this.dealershipRegistrationForm.value.ownersName,
        FromEmail: this.dealershipRegistrationForm.value.ownersEmailAddress,
        Attachments: this.attachments
      }
    };
    const dataString = JSON.stringify(this.formDetails);
    return this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers, reportProgress: true}).subscribe(
      (res) => {
        this.dealershipRegistrationForm.reset();
        this.dealershipRegistrationFormHide = false;
        this.btnShow = true;
        this.mailSent = true;
        this.spinner = false;
      },
      (err) => {
        this.btnShow = false;
        this.spinner = false;
      }
    );
  }

  public autoPopulate(event) {
    if (event.checked) {
      this.dealershipRegistrationForm.patchValue({
        uploaderName: this.dealershipRegistrationForm.get('ownersName').value,
        uploaderLastName: this.dealershipRegistrationForm.get('ownersLastName').value,
        uploaderEmailAddress: this.dealershipRegistrationForm.get('ownersEmailAddress').value,
        uploaderCellNumber: this.dealershipRegistrationForm.get('ownersCellNumber').value,
      });
    } else {
      this.dealershipRegistrationForm.patchValue({
        uploaderName: '',
        uploaderLastName: '',
        uploaderEmailAddress: '',
        uploaderCellNumber: ''
      });
    }
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace | Dealer Registration' );
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace | Dealer Registration'},
      {name: 'description', content: 'We are excited and privileged to try help you move some more metal via the Capitec Finance Channel.' +
          'Advertising on the platform is free of charge for any qualifying dealership. No catches, no funny business.' +
          'There some strict “rules of the Game” so please be sure to read the fine print before signing up.' },
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: 'We are excited and privileged to try help you move some more metal via the Capitec Finance Channel.' +
          'Advertising on the platform is free of charge for any qualifying dealership. No catches, no funny business.' +
          'There some strict “rules of the Game” so please be sure to read the fine print before signing up.'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace | Dealer Registration'},
      {property: 'og:description', content: 'We are excited and privileged to try help you move some more metal via the Capitec Finance Channel.' +
          'Advertising on the platform is free of charge for any qualifying dealership. No catches, no funny business.' +
          'There some strict “rules of the Game” so please be sure to read the fine print before signing up.'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace'},
      {property: 'og:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace | Dealer Registration'},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace | Dealer Registration'},
      {name: 'twitter:description', content: 'We are excited and privileged to try help you move some more metal via the Capitec Finance Channel.' +
          'Advertising on the platform is free of charge for any qualifying dealership. No catches, no funny business.' +
          'There some strict “rules of the Game” so please be sure to read the fine print before signing up.'},
      {name: 'twitter:image', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:image:url', content: 'https://thecarplace.co.za/assets/images/tcpmetaimage.png'},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }

}
