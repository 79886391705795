import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {faFacebook, faInstagram, faLinkedin, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faEnvelopeOpen, faPhone, faHome, faFighterJet} from '@fortawesome/free-solid-svg-icons';

import {LocalDataService} from '../../../services/local-data.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable} from 'rxjs';
import {ShowroomData} from '../../../models/showroom-data';
import {QueryParamHelper} from '../../../shared/helpers/query-param-helper';
import {CarsFilterService} from '../../../services/cars-filter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  store: any;
  logo = '../../assets/images/logo_vector.svg';
  footerYear: number = new Date().getFullYear();
  display = false;

  results: Array<ShowroomData> = [];
  resultEmpty = false;

  companyIdRef: number;
  oldIdRef: number;

  fbIcon = faFacebook;
  igIcon = faInstagram;
  liIcon = faLinkedin;
  ytIcon = faYoutube;
  twIcon = faTwitter;

  btnShow = true;
  formDetails = {};
  mailSent = false;
  spinner = false;
  spinnerColor = '#000000';
  newsLetterHide = true;
  validForm = false;

  public newsLetter = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
  });

  constructor(
    private http: HttpClient,
    public localData: LocalDataService,
    private routeParams: ActivatedRoute,
    public router: Router,
    private carsFilterService: CarsFilterService,
  ) {
  }

  carModel: ShowroomData = new ShowroomData();

  ngOnInit() {
    this.store = this.localData.store;
  }

  showDialog() {
    this.display = true;
  }

  navigate(id: string, make: string) {
    this.display = false;
    this.router.navigate(['/capitec-finance/vehicle', id, make]);
  }

  search() {

    if (this.companyIdRef === null) {
      this.companyIdRef = 0;
    }

    if (this.oldIdRef === null) {
      this.oldIdRef = 0;
    }

    if (this.companyIdRef === 0 && this.oldIdRef === 0) {
      return;
    }

    this.carsFilterService.filterReferenceNumber(this.companyIdRef, this.oldIdRef).subscribe(results => {
      this.results = results['stocks'];
      if (results['count'] > 0) {
        this.resultEmpty = false;
      } else {
        this.resultEmpty = true;
      }
    });
  }

  onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: 'customercare@thecarplace.co.za',
        CcList: '',
        BccList: '',
        Subject: 'STAY UPDATED WITH OUR LATEST ARTICLES, VIDEOS & NEWS:',
        Message: 'I would like to sign up and stay updated with the latest articles, videos and news: ' + this.newsLetter.value.email,
        MessageSubject: 'Website enquiry',
        ToName: this.localData.store.eMail.enquireMailName,
        FromName: '',
        FromEmail: this.newsLetter.value.email,
        Attachments: {}
      }
    };
    const dataString = JSON.stringify(this.formDetails);
    this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers}).subscribe(
      (res) => {
        this.newsLetter.reset();
        this.newsLetterHide = false;
        this.btnShow = true;
        this.mailSent = true;
        this.spinner = false;
      },
      (err) => {
        this.btnShow = false;
        this.spinner = false;
      }
    );
  }


}
