import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import {MultiSelectModule} from "primeng/multiselect";
import {SliderModule} from "primeng/slider";
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ShowroomComponent } from './components/pages/showroom/showroom.component';
import { NavLinksComponent } from './components/navigation/nav-links/nav-links.component';
import { VehicleComponent } from './components/pages/vehicle/vehicle.component';
import { VehicleEnquiryComponent } from './components/forms/vehicle-enquiry/vehicle-enquiry.component';
import { SellCarComponent } from './components/forms/sell-car-enquiry/sell-car.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { SellCarPageComponent } from './components/pages/sell-car/sell-car.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { RequestCarEnquiryComponent } from './components/forms/request-car-enquiry/request-car-enquiry.component';
import { RequestCarComponent } from './components/pages/request-car/request-car.component';
import { AllCarData } from './models/all-car-data';
import { Brochure } from './models/brochure';
import { NgPipesModule } from 'ngx-pipes';
import { FileUploadModule } from 'primeng/fileupload';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PoloShowroomComponent } from './components/pages/showroom/polo-showroom/polo-showroom.component';
import { BrochureComponent } from './components/pages/brochure/brochure.component';
import { DealershipsComponent } from './components/pages/dealerships/dealerships.component';
import { HiluxShowroomComponent } from './components/pages/showroom/hilux-showroom/hilux-showroom.component';
import { RangerShowroomComponent } from './components/pages/showroom/ranger-showroom/ranger-showroom.component';
import { CClassShowroomComponent } from './components/pages/showroom/c-class-showroom/c-class-showroom.component';
import { ThreeSeriesShowroomComponent } from './components/pages/showroom/three-series-showroom/three-series-showroom.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ModelYearPipe } from './pipes/model-year.pipe';
import { BrochureEnquiryComponent } from './components/forms/brochure-enquiry/brochure-enquiry.component';
import { BrochureFinanceEnquiryComponent } from './components/forms/brochure-finance-enquiry/brochure-finance-enquiry.component';
import { CapitecShowroomComponent } from './components/pages/capitec/capitec-showroom/capitec-showroom.component';
import { CapitecVehicleComponent } from './components/pages/capitec/capitec-vehicle/capitec-vehicle.component';
import { DealerRegistrationComponent } from './components/pages/dealer-registration/dealer-registration.component';
import { CapitecTermsConditionsComponent } from './components/pages/capitec/capitec-terms-conditions/capitec-terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { DealerFaqComponent } from './components/pages/faq/dealer-faq/dealer-faq.component';
import { DealerSignUpComponent } from './components/pages/dealer-sign-up/dealer-sign-up.component';
import { WhatIsVmgWebmasterComponent } from './components/pages/faq/what-is-vmg-webmaster/what-is-vmg-webmaster.component';
import { DealerCriteriaComponent } from './components/pages/faq/dealer-criteria/dealer-criteria.component';
import { WhatIsCapitecComponent } from './components/pages/faq/what-is-capitec/what-is-capitec.component';
import { WelcomePageComponent } from './components/pages/faq/welcome-page/welcome-page.component';
import { AllBrochureData } from './models/all-brochure-data';
import { CarImages } from './models/car-images';
import { CapitecFaqComponent } from './components/pages/faq/capitec-faq/capitec-faq.component';
import { FindDealershipsComponent } from './components/pages/find-dealerships/find-dealerships.component';
import { NewsAdviceComponent } from './components/pages/news-advice/news-advice.component';
import { NewsAdviceArticleComponent } from './components/pages/news-advice/news-advice-article/news-advice-article.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { TransunionComponent } from './components/pages/transunion/transunion.component';
import {CheckboxModule} from "primeng/checkbox";
import {RadioButtonModule} from "primeng/radiobutton";
import {DialogModule} from "primeng/dialog";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {SelectButtonModule} from "primeng/selectbutton";
import {CardModule} from "primeng/card";
import {ToastModule} from "primeng/toast";
import {StepsModule} from "primeng/steps";
import {DropdownModule} from "primeng/dropdown";
import {TabMenuModule} from "primeng/tabmenu";
import {GalleriaModule} from "primeng/galleria";
import {CarouselModule} from "primeng/carousel";
import {TabViewModule} from "primeng/tabview";
import {ChipModule} from "primeng/chip";
import {PaginatorModule} from "primeng/paginator";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InputTextModule} from "primeng/inputtext";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {
    swipe: {velocity: 0.4, threshold: 20} // override default settings
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NavigationComponent,
    NavLinksComponent,
    ShowroomComponent,
    VehicleComponent,
    VehicleEnquiryComponent,
    SellCarComponent,
    NotFoundComponent,
    SellCarPageComponent,
    TermsConditionsComponent,
    FaqComponent,
    RequestCarEnquiryComponent,
    RequestCarComponent,
    PoloShowroomComponent,
    BrochureComponent,
    DealershipsComponent,
    HiluxShowroomComponent,
    RangerShowroomComponent,
    CClassShowroomComponent,
    ThreeSeriesShowroomComponent,
    ModelYearPipe,
    BrochureEnquiryComponent,
    BrochureFinanceEnquiryComponent,
    CapitecShowroomComponent,
    CapitecVehicleComponent,
    DealerRegistrationComponent,
    CapitecTermsConditionsComponent,
    PrivacyPolicyComponent,
    DealerFaqComponent,
    DealerSignUpComponent,
    WhatIsVmgWebmasterComponent,
    DealerCriteriaComponent,
    WhatIsCapitecComponent,
    WelcomePageComponent,
    CapitecFaqComponent,
    FindDealershipsComponent,
    NewsAdviceComponent,
    NewsAdviceArticleComponent,
    ServicesComponent,
    TransunionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgPipesModule,
    FileUploadModule,
    LazyLoadImageModule,
    MultiSelectModule,
    SliderModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    DialogModule,
    AutoCompleteModule,
    ButtonModule,
    SelectButtonModule,
    CardModule,
    TableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ToastModule,
    StepsModule,
    DropdownModule,
    TabMenuModule,
    GalleriaModule,
    CarouselModule,
    TabViewModule,
    ChipModule,
    PaginatorModule,
    ProgressSpinnerModule,
    InputTextModule
  ],
  providers: [AllCarData, AllBrochureData, CarImages, Brochure, {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
