import { Component, OnInit } from '@angular/core';
import { LocalDataService } from '../../../services/local-data.service';
import { CarsFilterService } from '../../../services/cars-filter.service';
import { DealershipData } from '../../../models/dealership-data';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-find-dealerships',
  templateUrl: './find-dealerships.component.html',
  styleUrls: ['./find-dealerships.component.scss']
})
export class FindDealershipsComponent implements OnInit {

  public dealership: DealershipData[] = [];
  public car: any;
  public chevronDown = faChevronDown;

  constructor(
    public localData: LocalDataService,
    public carsFilterService: CarsFilterService
  ) { }

  public ngOnInit() {
    this.carsFilterService.getDealershipDetails().subscribe(http => {
      this.dealership = http['stocks'].sort((a: any, b: any) => {
        const nameA = (a.tradingName === '') ? a.companyName.toUpperCase() : a.tradingName.toUpperCase();
        const nameB = (b.tradingName === '') ? b.companyName.toUpperCase() : b.tradingName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    });
  }
}
