<section class="page-container">
  <div class="body-container">
    <div class="container">
      <div class="center-block less-padding">
      <div class="row ">
        <div class="col-lg-12 showroom-list">
          <p-card class="" *ngIf="!mobileSort">
            <div class="col-md-12">
              <div class="showroom-top-filter">
                <div class="row m-0">
                  <div class="col-md-12 text-end p-1 px-2" >
                    <span>Sort Vehicles By:  </span>
                    <p-dropdown [options]="vehicleSort"
                                [(ngModel)]="this.carsFilterService.sortingVal"
                                (onChange)="sortViewButton($event)"
                                [filterPlaceholder]="this.carsFilterService.sortingVal"
                                class="customSortDropdown">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
          <div class="row">
            <div class="col-md-8">
              <h5 style="margin: 20px 0">Currently showing <strong>{{dealerCars.length}}</strong> vehicles</h5>
            </div>
            <div class="col-md-4 justify-content-end vehicle-per-page text-end mt-2">
              <span>Vehicles per page: </span>
              <p-dropdown class="customSortDropdown" [options]="itemsPerPage" [(ngModel)]="selectedItemPerPage" styleClass="text-center" (onChange)="changeVehiclePerPAge()"></p-dropdown>
            </div>
          </div>
          <div *ngIf="loading"
               class="showroom-loader">
            <p-progressSpinner></p-progressSpinner>
            Loading Cars...
          </div>

          <div class="row">

            <div *ngFor="let car of getDisplayedCars(); let inx = index"
                 class=" col-md-4 col-lg-3">
              <figure class="car-preview-block">
                <div class="car-preview-img-container">
                  <a [routerLink]="['/vehicle', car.id, car.make]">
                    <img [lazyLoad]="car['imageUrl']"
                         [defaultImage]="defaultImage"
                         class="img-fluid" style="position: absolute;"
                         alt="{{car.make}}, {{car.masterModel}}">
                  </a>
                  <div class="capitec-approved-logo" *ngIf="car.sellingPrice <= 500000">
                    <img src="../../../../../assets/images/CapitecStamp.png" alt="capitec-approved-stamp">
                  </div>
                </div>
                <div class="price">{{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}</div>
                <figcaption>
                  <h4>{{car.make}}</h4>
                  <p>
                    <strong>{{car.variant | shorten:24:'...'}}</strong>
                    <br>
                    {{car.modelYear}}
                    <br>
                    <img src="/assets/images/speed.svg" alt="mileage" width="20px" height="100%" style="margin-right: 5px" class="img-fluid">

                    {{car.mileage}} km
                  </p>

                  <a [routerLink]="['/vehicle', car.id, car.make]">More Details</a>
                </figcaption>
              </figure>
            </div>

            <div *ngIf="dealerCars.length <= 0"
                 class="col-lg-12">
              <br>
              <h4>No Results Found</h4>
              <p>No vehicles are currently listed for your dealership</p>
            </div>

            <div class="paging-container col-lg-12">
              <p-paginator *ngIf="dealerCars && dealerCars.length > 0"
                           (onPageChange)="pageChange($event)"
                           [first]="first"
                           [rows]="rows"
                           [totalRecords]="totalRecords"
                           [showJumpToPageDropdown]="true"
                           [showPageLinks]="false"
                           styleClass="justify-content-center">
              </p-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="sticky-div"
       *ngIf="mobileSort">
    <div class="container showroom-top-filter">
      <div class="row">
        <div class="col-12 sort">
          <span>Vehicles Per Page:</span>
          <p-dropdown [options]="itemsPerPage" [(ngModel)]="selectedItemPerPage" styleClass="text-center" (onChange)="changeVehiclePerPAge()"></p-dropdown>
        </div>
      </div>
    </div>
  </div>
</section>
