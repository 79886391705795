import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-c-class-showroom',
  templateUrl: './c-class-showroom.component.html',
  styleUrls: ['./c-class-showroom.component.scss']
})
export class CClassShowroomComponent implements OnInit {

  constructor(
    private router: Router,
    private routeParams: ActivatedRoute,
    private meta: Meta,
    private title: Title
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  public ngOnInit() {
    this.router.navigate(
      [], {
        relativeTo: this.routeParams,
        queryParams: {
          series: 'C CLASS',
          page: 1
        },
        queryParamsHandling: 'merge'
      });
    this.setMetaData();
  }

  private setMetaData() {
    this.title.setTitle('TheCarPlace | Mercedes-Benz C-Class cars for sale');
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace | Mercedes-Benz C-Class cars for sale'},
      {name: 'description', content: 'The Car Place offers the best deals on used and new Mercedes-Benz C-Class for sale in South Africa.'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace | Mercedes-Benz C-Class cars for sale'},
      {property: 'og:description', content: 'The Car Place offers the best deals on used and new Mercedes-Benz C-Class for sale in South Africa.'},
      {property: 'og:url', content: 'https://thecarplace.co.za'},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace'},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace'},
      {property: 'og:country-name', content: 'South Africa'},
      {property: 'og:locale', content: 'en_ZA'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace | Mercedes-Benz C-Class cars for sale'},
      {name: 'twitter:description', content: 'The Car Place offers the best deals on used and new Mercedes-Benz C-Class for sale in South Africa.'},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }
}
