<section class="page-container">
  <div class="body-container">
    <div class="container terms-conditions">
      <div class="row">
        <div class="col-lg-12">
          <div id="privacy_notice">
            <h2>TheCarPlace</h2>
            <h3>Platform Usage Terms and Conditions</h3>
            <ol>
              <li>
                <h5>INTERPRETATION</h5>
                <ol>
                  <li>In this Agreement, unless inconsistent with or otherwise indicated by the context –
                    <ol>
                      <li>“the Agreement” or “this Agreement” means this agreement and all annexures thereto;</li>
                      <li>
                        “Business Day” means any day other than a Saturday, Sunday or public holiday in the Republic of South Africa; </li>
                      <li>TheCarPlace.co.za website, www.TheCarPlace.co.za, is made available, and owned by, VMG Software cc
                        (bearing registration number 2007/181521/23) (hereinafter referred to as “TheCarPlace.co.za”, “TheCarPlace”, “Website”, “we”, “our”, “us” or “its”).
                        Any reference to “TheCarPlace.co.za “, “we”, “our”, “us” or “its” shall include our employees, officers, directors, representatives, agents, shareholders,
                        affiliates, subsidiaries, holding companies, related entities, advisers, sub- contractors, service providers and suppliers.
                      </li>
                      <li>“Capitec” means Capitec Bank Limited, Registration number 1980/003695/06, a public company
                        and bank duly incorporated and registered according to the Laws of the Republic of South Africa
                        with registered address at 5 Neutron Road, Techno Park, Stellenbosch, Western Cape and herein
                        represented by Wiehahn Koch, he being duly authorised thereto;</li>
                      <li>“Capitec Bank Platform” means the Capitec Bank web-based or other platform used for credit estimates from time to time;</li>
                      <li>“Capitec Finance Channel” means the platform as described in point 10 below,
                        providing the client with a web link to the Capitec Bank platform subject to the process flow as described in clause 9 below.</li>
                      <li>“Dealer, Dealership and Dealers” means an entity which regularly trades in or is otherwise in the business of vehicle purchase and sales.</li>
                      <li>“The Seller” means Dealer, Dealership and Dealers.</li>
                      <li>“Financial Service provider” means a bank that has approved the seller to utilize their vehicle loan services on behalf of the sellers’ customers.</li>
                      <li>“Dealer Code” means that the dealer has been accredited with a major vehicle and asset financial services provider (bank) in South Africa.</li>
                      <li>“Purchaser” means either a person acting in their sole capacity, alternatively duly authorised to act on behalf of another person or
                        entity which utilises the Services as offered on the Website.</li>
                      <li>“Client” means a client who borrows money from Capitec in order to purchase a Vehicle from the Supplier.</li>
                      <li>“Hot Lead” is a client.</li>
                      <li>“Users” shall mean the Purchaser and the Seller collectively and “User” shall mean either of them as the context may indicate.</li>
                      <li>“Payout” means the transfer of funds to the sellers nominated bank account as stipulated on
                        the sellers invoice</li>
                      <li>"Confidential Information" includes, without limitation, any and all information or data of any
                        nature relating to the Disclosing Party which is disclosed by the Disclosing Party to the Receiving
                        Party (whether before or after the Signature Date) in whatever format (including oral, written,
                        electronic or visual form) and whether recorded or not (and if recorded, whether recorded in
                        writing, on any electronic medium or otherwise), and which is intended or which by its nature or
                        content could reasonably be identifiable as, or expected to be, confidential and/or proprietary to
                        the Disclosing Party, and which includes, without limitation even if it is not marked as
                        'confidential', 'proprietary' or 'restricted' (or any similar designation), any and all:
                        <ol>
                          <li>information which was disclosed to one Party by any third party in terms of any
                            agreement between such Party and the relevant third party and which has become known
                            to the Receiving Party;</li>
                          <li>information relating to the Disclosing Party's business activities, business relationships
                            (including information relating to such business relationships), services, processes, data,
                            employees, clients, technical information, techniques, know-how, operating methods and
                            procedures, business strategy and business models, including all agreements to which the
                            Disclosing Party is a party (including this Agreement);</li>
                          <li>information relating to costs, sources of services and customer lists (whether actual or
                            potential);</li>
                          <li>information relating to pricing, price lists and purchasing policies;</li>
                          <li>information contained in or relating to the Disclosing Party's technology and
                            telecommunications systems, computer data, databases, programmes and source codes,
                            including any third-party hardware, software or information or incidents concerning faults or
                            defects therein;
                          </li>
                          <li>products, specifications, software, documentation and configuration information;</li>
                          <li>marketing information of whatsoever nature or kind;</li>
                          <li>financial, technical, scientific, commercial and market information of whatsoever nature or kind;</li>
                          <li>personal information of whatsoever nature or kind, including 'personal information' as
                            defined in the Protection of Personal Information Act no4 0f 2013;</li>
                        </ol>
                      </li>
                      <li>“Credit Estimate” means an estimate of what credit the Client may qualify for, calculated by using the estimate tool available on the Capitec Bank Platform.</li>
                      <li>“Data” means representations of any information in electronic form.</li>
                      <li>“Event of Default” means an event as set out in clause 17.1 below;</li>
                      <li>“Laws” means any law (including common or customary law), any statute, constitution, decree,
                        judgment, treaty, regulation, directive, by- law, rule, guideline, order or any other legislative
                        measure; </li>
                      <li>“Loan Agreement” means an agreement between Capitec and a Client in terms of which
                        Capitec lends money to a Client to purchase a Vehicle; </li>
                      <li>“Loan Amount” means the amount lent by Capitec to a Client in terms of a Loan Agreement;</li>
                      <li>“NCA” means the National Credit Act no 34 of 2005, as amended;</li>
                      <li>“Parties” means TheCarPlace and The Seller and “Party” means any one of them as the
                        context may dictate;</li>
                      <li>“The Platform” means the vehicle aggregator search platform where Vehicles will be advertised to enable Clients to search for vehicles located at
                        <a [routerLink]="['/']">https://thecarplace.co.za/home</a>
                      </li>
                      <li>“VMG Platform” means VMG Webmaster. The software product developed by VMG that enables Sellers with the ability to upload vehicles and related content to the Website.</li>
                      <li>“Website” means the platform</li>
                      <li>“Advert” means an advert placed on the platform, which relates to the sale of a vehicle, setting out, inter alia, the vehicle specifications, photographs,
                        asking price, reviews and the like;</li>
                      <li>“Funds Release Form” means the document sellers will submit to TheCarPlace confirming that funds in holding for the payment of the Sellers invoice can be released
                        into the Sellers nominated bank account.
                      </li>
                      <li>“TransUnion” means Transunion Auto as it relates to the Transunion Vehicle Pricing Guide.</li>
                      <li>“Services” shall mean those Services as provided to the Users through the Website as more fully set out in clause 4 below</li>
                      <li>“Prime Rate” means the publicly quoted rate of interest (expressed as a nominal annual
                        compounded monthly in arrears rate) levied by FirstRand Bank Limited from time to time on
                        overdraft, calculated on a 365-day year factor, irrespective of whether or not the year is a leap
                        year, and proved, prima facie, in the event of a dispute and in the absence of manifest error, by a
                        certificate under the hand of any manager of FirstRand Bank Limited whose appointment and
                        authority need not be proven;
                      </li>
                      <li>“Purchase Price” means the amount payable by a Client to a Supplier for a Purchased Vehicle in terms of an invoice rendered to a Client by a Supplier for such Purchased Vehicle;</li>
                      <li>“Purchased Vehicles” means Vehicles purchased or to be purchased, as the case may be, by a Client from a Supplier; </li>
                      <li>“Signature Date” means the date of signature of this Agreement by the Party last signing;</li>
                      <li>“Supplier” means a motor dealership which have been validated by TheCarPlace and who’s Vehicles are advertised on The Platform; </li>
                      <li>“Vehicle/s” means motor vehicles and motor cycles;</li>
                      <li>“VMG” means VMG CC, Registration number 2007/1815221/23, a close corporation duly
                        incorporated and registered according to the Laws of the Republic of South Africa with registered
                        address at Unit 15, Mountain View Office Park, 28 Bella Rosa St, Cape Town and herein
                        represented by Alan Green, he being duly authorised thereto;</li>
                      <li>“VMG DMS platform” means VMG Dealership Management Software.</li>
                      <li>“Feed and Feeds” means the service provided by VMG that enables dealers to upload their vehicles to aggregation platforms other than TheCarPlace.</li>
                      <li>“Introductory Commission”. A commission charge by TheCarplace to the Seller for successful
                        transactions generated by The Seller from leads provided to the Seller by TheCarPlace.</li>
                      <li>“Health Check” means an assessment by TheCarPlace over the quality of data input into the VMG Platform.</li>
                      <li>unless the context indicates a contrary intention an expression which denotes:
                        <ol>
                          <li>any gender includes the other genders;</li>
                          <li>a natural person includes a juristic person and vice versa;</li>
                          <li>the singular includes the plural and vice versa.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Words importing the singular shall include the plural and vice versa.</li>
                  <li>The head notes to the paragraphs to this Agreement are inserted for reference purposes only and shall
                    not affect the interpretation of any of the provisions of which they relate.</li>
                  <li>This Agreement shall be binding on and enforceable by the permitted assigns or liquidators of the
                    Parties as fully and effectively as if they had signed this Agreement in the first instance and reference to
                    any Party shall be deemed to include such Party’s permitted assigns or liquidators, as the case may be.</li>
                  <li>If any provision in clause 1.1 is a substantive provision conferring rights or imposing obligations on
                    any Party then notwithstanding that such provision is contained in such clauses, effect shall be given
                    thereto as if such provisions were a substantive provision in the body of this Agreement.
                  </li>
                  <li>Where any term is defined within the context of any particular clause in this Agreement, the term so
                    defined shall unless it appears clearly from the clause in question that such term has limited application
                    to the relevant clause bear the meaning ascribed to it for all purposes in terms of this Agreement,
                    notwithstanding that such term has not been defined in this clause 1.
                  </li>
                  <li>When any number of Business Days is prescribed in this Agreement, same shall be reckoned
                    inclusively of the first and exclusively of the last Business Day.</li>
                  <li>Where the date for the performance of an obligation in terms of this Agreement does not fall on a
                    Business Day the performance shall be rendered on the immediately succeeding Business Day, except
                    where otherwise stated or appears from the context.
                  </li>
                  <li>The rule of interpretation that an agreement will be interpreted against the party responsible for the
                    drafting and any similar rules of interpretation will not apply to this Agreement.
                  </li>
                  <li>Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or
                    they, are taken as interchangeable and therefore as referring to the same.</li>
                </ol>
              </li>
              <li>
                <h5>TERMS OF SERVICE</h5>
                <ol>
                  <li>These terms are effective as of the date of Registration Submission via TheCarPlace registration form.</li>
                  <li>Through the use of this website and its related services, you are hereby accepting the terms and
                    conditions stipulated within. In addition, your continued use of the website following the posting of any
                    updates or changes to our terms and conditions shall mean that you are in agreement and acceptance
                    of such changes.
                  </li>
                  <li>These Terms, including any document incorporated by reference herein, including, but not limited to
                    the Privacy Policy (collectively, the “Terms”) apply to any Users who use the Services by accessing,
                    referring to, viewing and/or uploading or downloading any information or material made available on
                    the Website for whatever purpose, and which Users shall carry the definition as set out in clause 1.1.14
                    above and governs the Users’ relationship with TheCarPlace.co.za. These Terms will operate in addition
                    to any other, more specific, terms and conditions, which are separately agreed to by the User.
                  </li>
                  <li>Consent to these Terms will signify that the User has read, understands, accepts, and agrees to be bound, and is bound,
                    by the Terms in such User’s individual capacity and/or for and on behalf of any entity for whom the User utilises the Website.
                    Further, the User represents and warrants that the User has the authority to do so and that in the case of a User being a natural person,
                    the User is a Competent Person (as defined in the Protection of Personal Information Act, 4 of 2013, as amended).
                  </li>
                  <li>To the extent permitted by applicable law, we may modify the Terms with prospective effect without
                    prior notice to Users, and any revisions to the Terms shall be noted within such Terms and will take
                    effect when posted on the Website, unless a later date is otherwise stated in the revised Terms. A
                    User’s continued use of the Website and/or the Services will be construed as a User’s consent to the
                    amended or updated Terms and will be conditional upon the Terms in force at the time of use. A User’s
                    only remedy, should such User not agree to these Terms, is to stop the use of the Website. </li>
                  <li>Subject to, and on the basis of a User’s acceptance of the Terms, TheCarPlace.co.za grants the User a
                    limited, revocable, non-transferable license to access and use the Website in accordance with the
                    various policies and agreements which may govern such use and access.</li>
                </ol>
              </li>
              <li>
                <h5>Introduction</h5>
                <ol>
                  <li>TheCarPlace.co.za is a vehicle advertising platform that is offered to qualifying dealerships.</li>
                  <li>TheCarPlace.co.za is exclusively available to VMG Dealership Management Software customers.</li>
                  <li>TheCarPlace.co.za also makes available, exclusively to qualifying dealerships, the Capitec Finance
                    Channel service.</li>
                  <li>The Capitec Finance Channel is a service that provides potential purchasers access to apply for unsecured
                    finance facilitated by Capitec Bank.</li>
                  <li>The Capitec Financial Channel is helping potential car buyers (clients) get access to unsecured finance for
                    vehicles that traditionally were not financeable via traditional vehicle finance from other banks.</li>
                  <li>The vehicles sold through the Capitec Finance Channel will generally focus on late year models and
                    “cheapies”, however any vehicle under R 500 000.00 will be entertained by the Capitec Finance Channel.</li>
                  <li>The Capitec Finance Channel enables Sellers to list any vehicle where the invoice total for such vehicle
                    will be below R 500 000.00 (five hundred thousand rand).</li>
                  <li>Prospective purchasers received from the Capitec Finance Channel are clients that have been preapproved
                    for finance on a vehicle that the seller has advertised on the Capitec Finance Channel
                    webpages found on the TheCarPlace.co.za</li>
                  <li>Capitec offers loans to eligible clients.</li>
                  <li>VMG is a software development company that supplies dealership management software and related
                    products and services including a bespoke vehicle aggregator search platform where vehicles will be
                    displayed with Capitec providing a website link to enable Clients to obtain a Credit Estimate.</li>
                  <li>The Clients wish or may wish to borrow money from Capitec to purchase Vehicles from a Supplier
                    using the Platform and Capitec is prepared to offer loans to such Clients, subject to a NCA credit
                    assessment and approval of a credit application for such loan.</li>
                  <li>TheCarPlace and the Seller wish to regulate their relationship by written agreement, and this is the
                    agreement between them.</li>
                </ol>
              </li>
              <li>
                <h5>SERVICES</h5>
                <ol>
                  <li>The platform provides the following Services to Users:</li>
                  <ol>
                    <li>Access to the VMG Webmaster platform.</li>
                    <li>Using the VMG Platform, Sellers will place an Advert pertaining to the sale of a vehicle onto the
                      Website for the costs as set out in clause 6 below for viewing by prospective Purchasers; and</li>
                    <li>Provision of information comprising news and tools centered around vehicle purchases, third party
                      services, reviews, financing, insurance, and the like.</li>
                    <li>TheCarPlace will provide the platform where Vehicles will be advertised for sale to Clients and
                      which enables a client to search for a Vehicle on the VMG Platform, apply for a Credit Estimate and
                      to ultimately purchase a Vehicle from a Supplier.</li>
                    <li>TheCarPlace will publish a Capitec hyperlink on the Platform, when clicked upon will take the Client
                      to the Capitec Bank Platform.</li>
                  </ol></ol>
              </li>
              <li>
                <h5>SELLER ACCESS TO THE PLATFORM</h5>
                <ol>
                  <li>The seller agrees that he shall be granted access to the platform subject to the following conditions and
                    that should any of the conditions not be met at any time that the seller’s profile and adverts will be
                    removed from the platform until such time that the conditions below have been met.</li>
                  <ol>
                    <li>The seller shall have a minimum of one dealer code with a major vehicle and asset financial
                      service provider (bank).</li>
                    <li>The seller must have a stock holding of no less than 20 (twenty) vehicles at any given time and a
                      minimum of 15 vehicles uploaded to TheCarPlace at any time.</li>
                    <li>The seller agrees to participate in various quality control exercises utilized by TheCarPlace and
                      Capitec to ensure a quality client experience. Such exercises are at the discretion of TheCarPlace
                      and Capitec.</li>
                    <li>The seller agrees to remedy any quality control issues identified by TheCarPlace and communicated
                      electronically to the Seller, in a timeous manner.</li>
                  </ol>
                </ol>
              </li>
              <li>
                <h5>COSTS & PAYMENT</h5>
                <ol>
                  <li>Only sellers who have met the qualification criteria as described in section 5 above will be granted access
                    to the platform.</li>
                  <li>Sellers will be granted access to VMG Webmaster Platform at no charge.</li>
                  <li>Uploading vehicles to the Capitec Finance Channel showroom pages on the TheCarPlace will be free of
                    charge to qualifying Sellers.</li>
                  <li>Uploading vehicles to the general (non Capitec Finance Channel Pages) vehicle showroom webpages on
                    TheCarPlace will be exclusively available to VMG DMS customers and will be free of charge but subject to
                    the following commission criteria.
                    <ol>
                      <li>Vehicles sold to leads generated via TheCarPlace shall be subject to an introductory commission as
                        stipulated below.</li>
                      <li>Vehicles sold to Capitec Finance Approved Clients shall not be charged the introductory
                        commissions.
                      </li>
                      <li>The introduction commissions are set as follows
                        <ol>
                          <li>R 575 per vehicle sold up to a value of R 100 000.00</li>
                          <li>R 865 per vehicle sold between R 100 000.01 and R 150 000.00</li>
                          <li>R 1 150 per vehicle sold between R 150 000.01 and R 225 000.00</li>
                          <li>R 1 725 per vehicle sold between R 225 000.00 and R 400 000.00</li>
                          <li>R 2 300 per vehicle sold for R 400 000.01 and higher.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Sellers will be granted access to the use of “feeds”. Any feeds activated will incur a monthly cost to the
                    Seller of R 250.00 (two hundred and fifty rand) and shall be inclusive of Value Added Tax (VAT).</li>
                  <li>Sellers will be granted access to the software products available from VMG Software and each product
                    shall be quoted separately and shall remain outside the scope of this agreement.</li>
                  <li>Onsite training costs R 1 500 (one thousand five hundred rand) per user trained and shall be inclusive
                    of Value Added Tax (VAT).</li>
                  <li>TheCarPlace.co.za reserves its rights to charge Sellers, Purchasers and/or Users for use of the Services
                    from time to time in its sole and absolute discretion.</li>
                  <li>Where applicable, payment shall be affected via electronic funds transfer, debit order or credit card
                    through payment gateways, where applicable, provided via an independent third-party payment service
                    provider, in the discretion of TheCarPlace.co.za. The Purchaser is encouraged to consult the websites of
                    any third-party payment service providers to familiarize themselves with the security protocols and other
                    policies in place and which may apply to any transaction conducted through such payment gateways.</li>
                  <li>Upon successful activation of the Sellers profile on the platform and if payment, if any, have been
                    processed, the Seller will be able to post the Advert/s on the Website. This is the only manner in which
                    the Advert/s may be placed on to the Website. No e-mailed Advert/s will be accepted by
                    TheCarPlace.co.za.</li>
                </ol>
              </li>
              <li>
                <h5>THE ROLE OF THECARPLACE.CO.ZA</h5>
                <ol>
                  <li>TheCarPlace.co.za provides the Services as set out in clause 4 above.</li>
                  <li>Although TheCarPlace.co.za, by its provision of the Services, introduces Purchasers or potential
                    Purchasers to Sellers, the Users will be solely responsible for agreeing and formulating between
                    themselves the terms and conditions related to a sale of a vehicle that may arise from the provision of
                    the Services.</li>
                  <li>TheCarPlace.co.za is not a party to any vehicle sales, nor is it involved in the negotiation or final outcome
                    of any sale. Accordingly, TheCarPlace.co.za shall have no liability arising therefrom. Without limiting the
                    generality of clause 15 below, Users agree to indemnify and hold harmless TheCarPlace.co.za in respect
                    of any and all costs, losses and/or damages howsoever arising, whether direct, indirect, or consequential
                    out the sale of a vehicle pursuant to an Advert being posted on the Website.</li>
                  <li>In certain circumstances TheCarPlace.co.za may review, at its discretion, the contents of any Adverts, and
                    in this regard refuse to post Adverts, or may remove such Adverts which are deemed in its sole
                    discretion, not suitable for the Website.</li>
                </ol>
              </li>
              <li>
                <h5>UNDERTAKINGS & WARRANTIES BY THE SELLER</h5>
                <ol>
                  <li>The seller agrees that vehicles uploaded to the channel are done so exclusively through the use of the
                    VMG Webmaster Platform.</li>
                  <li>The seller agrees that no integrations with 3rd party service providers will be entertained by
                    TheCarPlace.</li>
                  <li>The Seller agrees that VMG Webmaster Platform training will provided via tutorial videos and that
                    should personal one on one training be required that the following will apply
                    <ol>
                      <li>Training may be provided via the telephone and a remote desktop connection with the relevant
                        user.</li>
                      <li>if onsite training is required that training charges will be applicable as stipulated in clause 6.7
                        above, where applicable.</li>
                    </ol>
                  </li>
                  <li>The seller agrees that If a HOT LEAD is assigned to the seller, that the seller will make use of the
                    Capitec Finance Channel Process Flow exclusively as described in clause 10 below.</li>
                </ol>
              </li>

              <li>The Seller agrees that should of any of the terms contained herein be breached for any reason, that the sellers
                profile and adverts, will be immediately removed from the platform.
                <ol>
                  <li>
                    The Seller hereby confirms that it shall not upload onto the Website any Adverts that are unlawful,
                    abusive, defamatory, libellous, pornographic, obscene, threatening, invasive of privacy or publicity rights,
                    inclusive of hate speech, or would constitute or encourage a criminal offense, violate the rights of any
                    party, or give rise to liability or violate any national or international law.
                  </li>
                  <li>
                    The seller hereby confirms that any vehicles uploaded to the platform conform with the following
                    requirements,
                    <ol>
                      <li>all the information of the Vehicle displayed on the Platform will be accurate including the selling
                        price, mileage, color, year and location of the Vehicle;</li>
                      <li>the advertised price is not more than 20% (twenty percent) above or 10% (ten percent) lower than
                        the TransUnion vehicle valuation;</li>
                      <li>Vehicles must be free of any incumbrance regardless of their nature.</li>
                      <li>Vehicles shall be confirmed to have no South African Police Interest.</li>
                      <li>Vehicles must display accurate data on selling price, mileage, colour year etc.</li>
                      <li>Vehicles must have quality images uploaded. Vehicles with poor quality images will be removed
                        from the platform without notice.</li>
                      <li>Vehicles advertised must be in a roadworthy condition.</li>
                      <li>Sold vehicles must be removed from the site within 24 hours of the delivery date.</li>
                    </ol>
                  </li>
                  <li>The seller hereby agrees that any vehicle listings that are older than 60 (sixty) days will be automatically
                    removed from the website and will have to be reuploaded by the seller.</li>
                  <li>The Seller hereby warrants that they are at least 18 years of age.</li>
                  <li>Failure to comply with this undertaking will result in the Advert either being removed from, or not posted
                    on the Website.</li>
                </ol>
              </li>

              <li>
                <h5>CAPITEC FINANCE CHANNEL PROCESS FLOW</h5>
                <ol>
                  <li>The seller acknowledges that HOT LEADS will be sent to the dealer via the Capitec Finance Channel and
                    that the following process will be applied.
                    <ol>
                      <li>Seller will upload his vehicles to the platform in accordance with the stipulations as described in
                        clause 9.2 above.</li>
                      <li>Prospective purchasers will browse the platform and may submit an enquiry to purchase the
                        sellers vehicle.</li>
                      <li>Upon an enquiry submission, the purchaser will be redirected to the Capitec Platform to
                        conclude a finance agreement Capitec.</li>
                      <li>Should the purchasers finance application be successful, Capitec will inform TheCarPlace
                        electronically.</li>
                      <li>The CarPlace will then notify the seller that they have a HOT LEAD via electronic mail and
                        nominated WhatsApp contact number.</li>
                      <li>The seller will acknowledge receipt of the HOT LEAD within 15 minutes of TheCarPlace
                        notification.</li>
                      <li>The Seller will arrange to take the client on a test drive of the vehicle.</li>
                      <li>The Seller will conclude the sales process with the client and prepare an invoice in the clients
                        name.</li>
                      <li>The seller will submit an invoice to TheCarPlace via electronic mail to <a href="mailto:capitec@thecarplace.co.za" target="_blank">capitec@thecarplace.co.za.</a></li>
                      <li>The seller agrees that the following documents will be sent to TheCarPlace upon submission of an
                        invoice to TheCarPlace.
                        <ol>
                          <li>Copy of Invoice</li>
                          <li>Copy of the client’s valid driver license.</li>
                        </ol>
                      </li>
                      <li>The seller agrees that invoices will only be eligible for payout subject to a verification process
                        conducted by TheCarPlace as per TheCarPlace’s agreement with Capitec. The Invoice verification
                        process includes the following criteria which may be updated by TheCarPlace without notice to
                        the Seller.
                        <ol>
                          <li>The invoice must be in the name of the financed client. No other details will be eligible for
                            payout.</li>
                          <li>The Client must have a valid driver’s licence.</li>
                          <li>The selling price value of the vehicle reflected on the invoice may not exceed 20% (twenty
                            percent) of the prescribed retail value of the specific model of vehicle as found in the
                            TransUnion vehicle pricing guide.</li>
                          <li>The invoice total will NOT exceed the total approved finance amount and that any invoices
                            above the total financed approved amount will be rejected.</li>
                          <li>Any items over and above the selling price of the vehicle shall meet with industry standard
                            pricing. This includes but is not limited to industry generic terms such as “On the Road Fees”,
                            “Service Fees”, “Delivery Fees”, “License and Registration Fees” and “Admin fees”.</li>
                          <li>Any value-added products will reflect the prescribed selling price as stipulated by the provider
                            of the value-added product.</li>
                        </ol>
                      </li>
                      <li>The seller agrees that only after the points described above have been verified by TheCarPlace,
                        that TheCarPlace will submit the invoice to Capitec for payout and that the following events will
                        then take effect,
                        <ol>
                          <li>Capitec will confirm with the client that they are happy to proceed.</li>
                          <li>Upon confirmation from the client to proceed, Capitec will transfer the funds to a holding
                            account and will notify TheCarPlace that funds are ready for release.</li>
                          <li>TheCarPlace will inform the seller that the funds are ready for release into the sellers
                            nominated bank account</li>
                        </ol>
                      </li>
                      <li>The Seller agrees to submit a Funds Release Form which shall be subject to verification by
                        TheCarPlace in order to be eligible for release of the funds from the holding account into the
                        Sellers nominated bank account.</li>
                      <li>The Seller agrees to submit the following required documents along with their Funds Release
                        Form
                        <ol>
                          <li>A clear copy of eNatis showing that the vehicle is registered in the name of the financed
                            client.</li>
                          <li>A clear copy of a valid Roadworthy Certificate</li>
                          <li>If applicable, a clear copy of Value Added Products documents as displayed on the invoice.</li>
                        </ol>
                      </li>
                      <li>The seller agrees that only after the required documents have been received and verified by
                        TheCarPlace, will the funds in holding be released into the Sellers nominated and verified bank
                        account</li>
                      <li>The seller agrees that after funds have been released into the sellers bank account that
                        TheCarPlace will send the seller a remittance electronically to The Sellers nominated email address.</li>
                      <li>The seller agrees that upon receipt of a remittance from TheCarPlace, that the Seller will be
                        responsible for the timeous delivery of the vehicle to the client.</li>

                      <li>The Seller acknowledges that TheCarPlace will contact the client to confirm that the vehicle has
                        been delivered.</li>
                      <li>The Seller agrees that should the Seller fail to deliver the sold vehicle to the Client in a
                        reasonable amount of time, that the Seller will, upon request by TheCarPlace, immediately return
                        any payments made by TheCarPlace to the Seller with immediate effect.</li>
                      <li>The Seller agrees that failure to return the funds as described in clause 10.1.19 above, will result
                        in the Seller having their profile and adverts immediately removed from the platform and that
                        TheCarPlace reserves the rights as described in this agreement, to pursue legal proceedings against
                        the seller, in favour of TheCarPlace.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <h5>FUNDS RELEASE FORM</h5>
                <ol>
                  <li>The seller agrees payout for an invoice sent to TheCarPlace is subject to the submission of a Funds
                    Release Form which shall be verified by TheCarPlace.</li>
                  <li>The seller agrees that the Fund Release Form will includes and declare the following.
                    <ol>
                      <li>Dealership Details</li>
                      <li>Client Details</li>
                      <li>Vehicle Details</li>
                      <li>Copy of Invoice</li>
                      <li>Copy of Valid Driver’s license of financed client</li>
                      <li>Copy of eNatis showing vehicle is registered in the name of the financed client.</li>
                      <li>Copy of Roadworthy Certificate</li>
                      <li>Copy of VAP documents as displayed on the invoice.</li>
                      <li>Vehicle is confirmed to have no police interest.</li>
                      <li>Vehicle is in a roadworthy condition.</li>
                      <li>Vehicle is free of any lien or other incumbrance.</li>
                      <li>Vehicle kilometer reading is accurate.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <h5>CAPITEC LOAN APPLICATION AND PAYOUT PROCESS</h5>
                <ol>
                  <li>The Capitec loan application process and any subsequent Loan Agreement shall be between the Client
                    and Capitec without any involvement from TheCarPlace and the Seller.</li>
                  <li>The costs of credit charged by and payable to Capitec in terms of the Loan Agreements shall be entirely
                    within the discretion of Capitec and The Seller shall not be entitled to prescribe such costs of credit.</li>
                  <li>The sale of a Vehicle by the Supplier to the Client and any disputes in respect of such Vehicle shall be
                    between the Client and the Supplier without any involvement from Capitec and TheCarPlace.</li>
                  <li>If a Client applied for a loan at Capitec to purchase a Vehicle and if the loan was approved by Capitec,
                    Capitec shall notify TheCarPlace in writing of the loan approval, whereafter TheCarPlace shall notify the
                    Supplier that the loan was approved by Capitec.</li>
                  <li>Capitec shall not be obliged to grant a loan to any Client. Whether or not Capitec grants a loan shall be in
                    the sole and absolute discretion of Capitec.</li>
                  <li>
                    TheCarPlace shall procure that if a Client purchases a Vehicle from the Supplier that the Supplier
                    forthwith furnishes TheCarPlace with an invoice (issued in favour of the Client) in respect of the Vehicle,
                    if the Supplier has not yet done so, containing the following information:
                    <ol>
                      <li>the Supplier’s name;</li>
                      <li>a description of the vehicle;</li>
                      <li>the vehicle’s chassis number;</li>
                      <li>the vehicle’s engine number;</li>
                      <li>the vehicle’s license number; and</li>
                      <li>the vehicle’s kilometer reading.</li>
                    </ol>
                  </li>
                  <li>After receipt of the invoice from the Supplier, TheCarPlace shall immediately furnish a copy of the invoice
                    to Capitec.</li>
                  <li>Upon receipt of the invoice by Capitec, Capitec shall on the instruction of the Client make payment of the
                    Loan Amount, being the Purchase Price, by electronic funds transfer to TheCarPlace’s designated bank
                    account and furnish proof of payment to TheCarPlace by email.</li>
                  <li>After receipt of the proof of payment in clause 11.8 above, TheCarPlace shall notify the Supplier that it
                    has received payment of the Purchase Price from Capitec and that an amount equal to the Purchase Price
                    is ready to be released to the Supplier.</li>
                  <li>TheCarPlace will only pay the Purchase Price to the Supplier if TheCarPlace is in possession of a
                    copy of the following:
                    <ol>
                      <li>National Traffic Information System document (Natis) indicating the Client as the registered
                        owner of the Vehicle;</li>
                      <li>A valid roadworthy certificate of the relevant vehicle; and</li>
                      <li>A valid drivers license of the client</li>
                    </ol>
                  </li>
                  <li>TheCarPlace will obtain written confirmation from the Supplier that the Purchased Vehicle was
                    delivered to the Client and on request, provide Capitec with such written confirmation.</li>
                  <li>The Parties record that it is their objective and intention that the closing of transactions, i.e. the
                    conclusion of Loan Agreements, payment of the Purchase Price by Capitec to the Supplier and delivery of
                    the Purchased Vehicle to the Client, be performed swiftly and efficiently in one continuous sequence
                    (unless interrupted or delayed by the Client). The Parties undertake to give effect to this objective as far
                    as reasonably possible.</li>
                </ol>
              </li>
              <li>

                <h5>INDEMNITY</h5>
                <ol>
                  <li>The Seller indemnifies and holds TheCarPlace and Capitec harmless, against any loss, expense damage or
                    claim arising as a result of a Client alleging, and/or failing or refusing to meet its obligations to Capitec in
                    terms of Loan Agreement on the grounds, of:
                    <ol>
                      <li>the Supplier not having received payment of the Purchase Price for any reason whatsoever after
                        payment to TheCarPlace was made by Capitec in terms of clause 11.8 of this Agreement; and/or</li>
                      <li>the Supplier having failed to deliver the Purchased Vehicle to the Client for any reasons
                        whatsoever; and/or</li>
                      <li>the Supplier and/or the Purchased Vehicle not complying with or being in contravention of, or
                        the Client asserts a right in terms of, Laws; and/or</li>
                      <li>circumstances arising from and/or being attributable to a contravention by VMG of its
                        obligations in terms of clauses 11, 13 and 14; and/or
                        (‘Indemnified Event’)</li>
                    </ol>
                  </li>
                  <li>If an Indemnified Event occurs TheCarPlace shall give notice thereof to the Seller, who shall forthwith
                    make payment of the full amount owing to Capitec under the relevant Loan Agreement which constitutes
                    the Purchase Price.</li>
                </ol>
              </li>


              <li>
                <h5>DATA PROTECTION</h5>
                <ol>
                  <li>The words Processing, Personal information, Operator, Data Subject and Regulator used in this clause 9
                    have the meaning as defined in the Protection of Personal Information Act no4 of 2013.</li>
                  <li>The Parties agree that they are each responsible for complying with their respective general obligations
                    under the Protection of Personal Information Act and any other applicable laws and standards governing
                    the Processing of Personal Information.</li>
                  <li>The Parties agree that insofar as The Seller acts as an Operator and will strictly comply with the
                    provisions of the Protection of Personal Information Act that applies to Operators.</li>
                  <li>The seller confirms that in respect of Personal Information collected from Clients, the seller is the
                    Responsible Party in respect of such information, in law. The Seller further expressly undertakes that it
                    would Process Personal Information disclosed by Capitec solely for the purpose of this Agreement and
                    acknowledges that insofar as it determines the means of Processing such Personal Information it is
                    deemed a joint-Responsible Party in law and must ensure that the Processing activities are performed in
                    a lawful manner and in the event that any part of the Processing is outsourced, that they will ensure
                    compliance by and accepts full responsibility for the actions of its sub-contractors.</li>
                  <li>The Seller hereby indemnify TheCarPlace against any liability, loss, cost or damage suffered by
                    TheCarPlace resulting from any action, proceeding or claim made by any Data Subject or Regulator
                    against TheCarPlace, attributable to any unlawful processing by The Seller, its’ employees, subcontractors,
                    agents or representatives or resulting from The Sellers breach of the Protection of Personal
                    Information Act. This indemnity shall survive the termination of this Agreement.</li>
                  <li>Each Party must notify the other immediately where there are reasonable grounds to believe that the
                    Personal Information of a Data Subject has been accessed or acquired by any unauthorised person.</li>
                  <li>In the event that TheCarPlace suspects a breach of this clause 12, it shall be entitled to appoint a
                    reputable independent consultant or auditor to undertake a compliance audit of The Seller in respect of
                    their compliance with this clause 12.</li>
                </ol>
              </li>
              <li>
                <h5>COMMENCEMENT & TERMINATION</h5>
                <ol>
                  <li>Subject to clause 5 above, these Terms commence on the date upon which the Seller submitted their
                    registration on TheCarPlace and shall terminate only upon the later of the termination of the
                    Subscription Period, Once Off Period, or User discontinuing their access to the Website.</li>
                </ol>
              </li>
              <li>
                <h5>SURVIVAL</h5>
                <ol>
                  <li>Clauses which out of necessity or by implication herein survive termination of these Terms, shall remain
                    in full and effect despite the termination hereof.</li>
                </ol>
              </li>

              <li>
                <h5>RESERVATION OF RIGHTS</h5>
                <ol>
                  <li>TheCarPlace.co.za reserves the right, in its sole discretion, to disable, remove or refuse to display on the
                    Website any Advert and refuse the Seller any and all future use of the Website if TheCarPlace.co.za
                    comprises, or has reasonable grounds to suspect, that the Advert contains false, infringing or misleading
                    information, or information not otherwise permitted in accordance with these Terms.</li>
                </ol>
              </li>
              <li>
                <h5>DISCLAIMER</h5>
                <ol>
                  <li>TheCarPlace.co.za does not have, and expressly disclaims, any liability to a User in connection with any
                    content, information, text, graphics, images, audio or video, or material a Seller uploads to the Website.
                    TheCarPlace.co.za cannot guarantee and makes no representations in relation to the completeness or
                    accuracy of any Advert.</li>
                  <li>TheCarPlace.co.za makes no express, implied, or statutory representations, warranties, or guarantees in
                    connection with the Website or the Services, relating to the quality, suitability, truth, accuracy or
                    completeness of any information or material contained or presented on the Website.</li>
                  <li>Without limiting the generality of the foregoing, TheCarPlace.co.za makes no warranty that the Website
                    will meet a User’s requirements, or that the Website will be uninterrupted, timely, secure, error free or
                    that defects in the Website will be corrected, nor that it will be virus free.</li>
                  <li>TheCarPlace.co.za makes no other warranties, express or implied, statutory, or otherwise, including
                    but not limited to warranties of merchantability, title, fitness for a particular purpose or non-infringement.</li>
                  <li>The User agrees that, whilst TheCarPlace.co.za uses reasonable efforts to prevent click fraud, a User
                    shall have no claim of any nature whatsoever against TheCarPlace.co.za in the event of any click fraud
                    arising in respect of an Advert.</li>
                  <li>TheCarPlace.co.za shall not be liable for delays, interruptions, service failures or other problems
                    inherent in the use of the internet and electronic communications or other systems outside the
                    reasonable control of TheCarPlace.co.za. While a User may have statutory rights, the duration of any
                    such statutorily warranties, will be limited to the shortest period to the extent permitted by required
                    law.</li>
                  <li>Subject to any express terms, TheCarPlace.co.za makes no representation or warranty as to the volume
                    of potential Users accessing the Website.</li>
                  <li>Except for any express warranties in these Terms of Service, the Website is provided on an “as is”
                    basis and used at the User’s own risk.</li>
                </ol>
              </li>
              <li>
                <h5>RELATIONSHIP BETWEEN CAPITEC AND VMG</h5>
                <ol>
                  <li>Nothing in this Agreement is construed as The Seller and TheCarPlace being the agent, representative,
                    contractor, or partner of Capitec.</li>
                  <li>Nothing in this Agreement is construed as The Seller being the agent, representative, contractor, or
                    partner of TheCarPlace.</li>
                </ol>
              </li>
              <li>
                <h5>UNAUTHORISED USE OF THE WEBSITE</h5>
                <ol>
                  <li>A User may not:
                    <ol>
                      <li>use the Website for any objectionable or unlawful purpose, including the posting of any
                        threatening, libellous, defamatory, obscene, scandalous, inflammatory, pornographic, profane or
                        spam material;</li>
                      <li>take any action that imposes an unreasonable or disproportionately large load on the Website’s
                        infrastructure, including spam or other unsolicited mass e-mailing techniques;</li>
                      <li>use the Website in any manner that would result in a User breaching any applicable legislation or
                        licensing obligations (including with respect to privacy) or any obligations a User may owe to third
                        parties;</li>
                      <li>mislead or deceive others through any act or omission or make a false representation about a
                        User’s identity, including the impersonation of a real or fictitious person or using an alternative
                        identity or pseudonym;</li>
                      <li>post content which contravenes a confidentiality or non-disclosure agreement, insider trading
                        laws, or intellectual property rights which a User is not authorised to transfer to another party;</li>
                      <li>conduct any activity which compromises or breaches another party’s patent rights, trademark,
                        copyright or other intellectual property rights;</li>
                      <li>introduce any virus, worm, trojan horse, malicious code or other program which may damage
                        computers or other computer-based equipment to the Website or to other Users;</li>
                      <li>crawling, spidering or scraping of content is prohibited, except to the extent required by
                        recognised search engines (e.g. Google) for the purposes of indexing the TheCarPlace.co.za
                        Website;</li>
                      <li>remove or alter TheCarPlace.co.za copyright notices or other means of identification including
                        any watermarks, as they appear on the Website.</li>
                    </ol>
                  </li>
                  <li>The User understands and agrees that it is solely responsible for compliance with any and all laws,
                    rules, regulations, and taxation obligations that may apply to its use of the Website or the Services.</li>
                </ol>
              </li>
              <li>
                <h5>COPYRIGHTS</h5>
                <ol>
                  <li>TheCarPlace.co.za and the contents of the Website are the property of VMG Software cc and are
                    protected by South African and international copyright laws. Furthermore, the compilation (meaning the
                    collection, arrangement, and assembly) of all content on the Website and/or the Services, is the property
                    of TheCarPlace.co.za, unless credit is attributed to the author thereof, and is, likewise, protected by
                    South African and international copyright laws.</li>
                  <li>Except as stated in the Terms, none of the contents may be copied, reproduced, distributed, republished,
                    downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to,
                    electronic, mechanical, photocopying, recording, or otherwise, except as permitted by the fair use
                    privilege under the South African copyright laws or without the prior written permission of
                    TheCarPlace.co.za, and further, should such consent be provided, TheCarPlace.co.za reserves its right to
                    withdraw such consent at any stage, in its sole and absolute discretion.</li>
                  <li>Users are expressly prohibited to “mirror” any content, contained on the Website, on any other
                    server unless with the prior written permission of TheCarPlace.co.za, and further, should such
                    consent be provided, TheCarPlace.co.za reserves its right to withdraw such consent at any stage, in its
                    sole and absolute discretion.</li>
                  <li>TheCarPlace.co.za does not permit copyright infringing activities and infringement of intellectual
                    property rights on the Website, and TheCarPlace.co.za may, at its sole discretion, remove any
                    infringing content if they are of the view that such content infringes on another’s intellectual
                    property rights or our own.</li>
                  <li>The Seller is granted a limited, revocable, and non-exclusive right to create a hyperlink to the home
                    page or other applicable page of TheCarPlace.co.za, so long as the link does not portray
                    TheCarPlace.co.za, its affiliates, or its products or services in a false, misleading, derogatory, or
                    otherwise offensive manner. A User may not use TheCarPlace.co.za logo or other proprietary graphic
                    or trademark as part of the link without the express permission of TheCarPlace.co.za, its affiliates or
                    content suppliers.</li>
                  <li>All trademarks and copyrights, and also any other intellectual property rights, in and to any of the
                    content of the TheCarPlace.co.za Website are the exclusive property of VMG Software cc.</li>
                </ol>
              </li>

              <li>
                <h5>INTELLECTUAL PROPERTY</h5>
                <ol>
                  <li>A User undertakes not to attempt to decipher, decompile, disassemble, or reverse engineer any of the
                    software or code comprising or in any way making up a part of the Website including any algorithm used
                    by TheCarPlace.co.za.</li>
                  <li>TheCarPlace.co.za owns or is licensed to use all intellectual property on the Website. A User may not use
                    any of our intellectual property for any purpose other than as may be required to use the Website for its
                    intended purpose.</li>
                  <li>The ownership in and to any intellectual property comprising the Adverts and the contents thereof, shall
                    as and when posted by a User on the Website, be transferred to TheCarPlace.co.za, free of any charge. In
                    this regard, the User agrees to waive any and all moral rights attaching thereto. Accordingly,
                    TheCarPlace.co.za shall have the right to use, display, copy, modify, adapt, reproduce, commercialise,
                    prepare derivative works, display and publish all such intellectual property and that content forming all
                    or part of the Advert, for any reasonable purpose.</li>
                  <li>By using the Website, a User warrants that it owns or is licensed to utilise and post the intellectual
                    property rights comprising such Advert. This includes copyright in respect of any text posted, as well as
                    the right to use or display any image or logo. The User indemnifies TheCarPlace.co.za, and agrees to keep
                    TheCarPlace.co.za indemnified, from and against any claims by third parties arising from a User’s Advert,
                    including any breach of intellectual property rights any third party may bring against us, in relation to a
                    User’s Advert.</li>
                  <li>No User may use or display any trademarks on the Website without first obtaining the consent of the
                    owner of the trade mark. We own the distinctive “TheCarPlace.co.za” marks, brand and logo.</li>
                  <li>Nothing on this Website creates any right on a User’s part (express or implied) that would allow a User to
                    use or display a trade mark that such User does not own and has not otherwise validly licensed,
                    regardless of whether the trade mark is currently registered.</li>
                </ol>
              </li>
              <li>
                <h5>LIMITATION OF LIABILITY AND INDEMNITY</h5>
                <ol>
                  <li>The Website shall be used entirely at a User’s own risk.</li>
                  <li>TheCarPlace.co.za is not responsible for, and the User agrees that we will have no liability in relation to,
                    the use of and conduct in connection with the Website, the purchase and sale of any vehicles being the
                    subject of an Advert, or any other person’s use of or conduct in connection with the Website, in any
                    circumstance.</li>
                  <li>A User indemnifies us, and agrees to keep us indemnified, from and against any claim, loss, damage, cost
                    or expense that we may suffer or incur as a result of or in connection with a User’s improper use of or
                    conduct in connection with the Website, including any breach by a User of these terms or any applicable
                    law or licensing requirements.</li>
                  <li>To the maximum extent permitted by law TheCarPlace.co.za excludes all implied representations and
                    warranties which, but for these terms, might apply in relation to a User’s use of the Website.</li>
                  <li>To the extent that TheCarPlace.co.za liability cannot be excluded by law, our maximum liability, whether
                    in contract, equity, statute or tort (including negligence), to a User will be limited to the minimum
                    amount imposed by such law.</li>
                  <li>Notwithstanding anything to the contrary in these terms, in no circumstances will TheCarPlace.co.za be
                    liable for any indirect, punitive or consequential loss or damages, loss of income, profits, goodwill, data,
                    contracts, use of money or any loss or damages arising from or in any way connected to interruption of
                    the Services of any type, whether in delict, contract or otherwise.</li>
                </ol>
              </li>

              <li>
                <h5>CONFIDENTIALITY</h5>
                <ol>
                  <li>Confidential Information means any documents or information created, received or obtained from one
                    User by another alternatively TheCarPlace.co.za, together with information relating to TheCarPlace.co.za
                    as well as non-public information pertaining to TheCarPlace.co.za’s business (including, without
                    limitation pricing information, any related entities, the identity of Users, and any content marked
                    confidential or reasonably construed as being confidential due to its nature) which must remain strictly
                    confidential.</li>
                  <li>A User may not disclose, solicit, make available or misappropriate any Confidential Information, trade
                    secrets or other proprietary information that belongs to any third party without that party’s prior written
                    permission, or in violation of any express or implied duty or contractual right.</li>
                  <li>Where a User is legally required to disclose the Confidential Information, such User shall inform the third
                    party who disclosed the Confidential Information of the requirement as soon as reasonably practicable
                    and liaise with that party prior to disclosing any of that party’s Confidential Information.</li>
                  <li>A User shall notify TheCarPlace.co.za immediately upon becoming aware of a suspected or actual breach
                    of this obligation.</li>
                  <li>TheCarPlace.co.za will not disclose or make available to any third party, directly or indirectly, any User’s
                    Confidential Information, except where we have the User’s permission, where the Confidential
                    Information is already in the public domain (through no breach of these Terms), in furtherance of a
                    User’s use of the Services, to enforce these Terms or if legally compelled to do so, or in accordance with
                    our Privacy Policy.</li>
                </ol>
              </li>

              <li>
                <h5>GENERAL</h5>
                <ol>
                  <li>Our failure to enforce any provision of these Terms shall not constitute a waiver of such provision.
                    Further, any consent to depart from the terms of this Agreement shall be ineffective unless it is
                    confirmed in writing and signed by us.</li>
                  <li>Any present or future legislation which operates to vary an obligation or right, power or remedy of a
                    person in connection with these Terms is excluded except to the extent that its exclusion is prohibited or
                    rendered ineffective by law.</li>
                  <li>The rights, powers and remedies provided to us in these Terms are cumulative with and not exclusive of
                    the rights, powers or remedies provided by law independently of these Terms.</li>
                  <li>These Terms constitute the entire agreement, read together with the Privacy Policy. A User
                    acknowledges and agrees that it has not relied on any representations by us in entering this Agreement,
                    except those contained in these Terms.</li>
                  <li>The laws of the Republic of South Africa shall apply to these Terms and the Terms of Service.</li>
                  <li>Any claims, legal proceeding or litigation arising in connection with these Terms shall be subject solely to
                    the jurisdiction of the courts of the Republic of South Africa.</li>
                  <li>If any provision herein is held to be unenforceable, the remaining provisions shall remain in full force and
                    effect.</li>
                </ol>
              </li>
              <li>
                <h5>DOMICILIUM CITANDI ET EXECUTANDI AND CONTACT INFORMATION</h5>
                <ol>
                  <li>The Seller and TheCarPlace.co.za choose as their respective domicilium citandi et executandi for the
                    purpose of legal proceedings and for the purpose of giving or sending any notice provided for or
                    necessary of these Terms, the following:
                    <ol>
                      <li>
                        ZooPark Technologies trading as VMG Services
                        <br>
                        Unit 14 and 15, 28 Bella Rosa Street, Rosenpark, Durbanville, Cape Town, 7560
                        <br>
                        Email: <a href="mailto:info@thecarplace.co.za" target="_blank">info@thecarplace.co.za</a>
                      </li>
                      <li>The Seller<br>
                        The address as provided when registering on the Website.</li>
                    </ol>
                  </li>
                  <li>Both the Seller and TheCarPlace.co.za may change its domicilium to any other physical address or email
                    address by written notice to the other to that effect. Such change of address will be effective 7 (Seven)
                    days after receipt of notice of change of domicilium.</li>
                  <li>All notices to be given in terms of these Terms will:
                    <ol>
                      <li>be given in writing;</li>
                      <li>be delivered or sent by email; and</li>
                      <li>be presumed to have been received on the date of delivery.</li>
                    </ol>
                  </li>
                  <li>Notwithstanding the above, any notice actually received by the Party to whom notice is addressed will be
                    deemed to have been properly given and received, notwithstanding that such notice has not been given
                    in accordance with the provisions of this clause.</li>
                </ol>
              </li>
              <li>
                <h5>CERTIFICATE OF INDEBTEDNESS</h5>
                <ol>
                  <li>A certificate signed by any director or officer (whose appointment, authority or qualification need not be
                    proved) for the time being of TheCarPlace shall be prima facie proof of the quantum of any amount
                    payable by The Seller to TheCarPlace in terms of this Agreement and valid, together herewith, for any
                    purpose and as a liquid document (alternatively as proof of a liquidated amount) in any court of
                    competent jurisdiction for the purpose of obtaining provisional sentence, summary judgment or any
                    other judgment against The Seller.</li>
                </ol>
              </li>
              <li>
                <h5>CESSION AND DELEGATION</h5>
                <ol>
                  <li>Neither Party may transfer all or any of their rights and obligations under this Agreement, without the
                    prior written consent of the other Party.</li>
                </ol>
              </li>
              <li>
                <h5>APPLICABLE LAW AND COURT</h5>
                <ol>
                  <li>This Agreement shall in all respects whatsoever be governed by South African Law and the Parties hereby
                    consent to the non-exclusive jurisdiction of the High Court of South Africa (Western Cape High Court,
                    Cape Town).</li>
                  <li>Notwithstanding the provisions of clause 21.1, Capitec shall be entitled to institute action in any
                    Magistrates’ Court with jurisdiction in respect of VMG and VMG consents to the jurisdiction of the
                    Magistrates’ Court in terms of section 45 of the Magistrates’ Court Act no 32 of 1944 as amended.</li>
                </ol>
              </li>
              <li>
                <h5>NON-WAIVER</h5>
                <ol>
                  <li>No relaxation, indulgence or extension of time granted by either Party to the other Party shall be
                    construed as a waiver of such Party’s rights in terms hereof, or a novation of any of the terms of this
                    Agreement or estop such Party from enforcing strict and punctual compliance with the terms of this
                    Agreement.</li>
                </ol>
              </li>
              <li>
                <h5>NON-VARIATION</h5>
                <ol>
                  <li>No variation of, addition to, consensual cancellation of or waiver of any right arising in terms of this
                    Agreement (including this clause 23) shall be of any force and effect unless it is reduced to writing and
                    signed by a duly authorised representative of each of the Parties.</li>
                </ol>
              </li>

              <li>
                <h5>WHOLE AGREEMENT</h5>
                <ol>
                  <li>This Agreement constitutes the whole agreement between the Parties in relation to the subject matter
                    thereof and no Party shall accordingly be bound by any undertaking, representation or warranty not
                    recorded therein.</li>
                </ol>
              </li>

              <li>
                <h5>INDEPENDENT ADVICE</h5>
                <ol>
                  <li>Each of the Parties acknowledges, represents and agrees that it has not relied in any way upon any
                    information and/or advice given by the other Party in the preparation, negotiation and/or implantation
                    of this Agreement and has taken all reasonable actions to satisfy itself as to the consequences of entering
                    into this Agreement and all of the provisions of this Agreement and the restrictions herein contained are
                    fair and reasonable in all the circumstances and are part of the overall intention of the Parties in
                    connection with this Agreement.</li>
                </ol>
              </li>
              <li>
                <h5>SEVERABILITY</h5>
                <ol>
                  <li>The parties agree that each clause of this Agreement shall be severable, the one from the other, and if
                    any clause is found to be defective or unenforceable for any reason by any competent court, then the
                    remaining clauses shall be and continue to be of full force and effect.</li>
                </ol>
              </li>
              <li>
                <h5>EXECUTION IN COUNTERPARTS</h5>
                <ol>
                  <li>This Agreement may be executed in separate counterparts, none of which need to contain the signatures
                    of all of the Parties and each of which shall be deemed to be an original and all of which, taken together,
                    shall constitute one document and shall be valid and binding on the Parties, notwithstanding that one or
                    more of the Parties sign a facsimile copy hereof and whether or not such facsimile copy contains the
                    signatures of any other Party.</li>
                </ol>
              </li>
              <li>
                <h5>COSTS</h5>
                <ol>
                  <li>All legal costs, including costs as between attorney and client, charges and disbursements incurred by
                    Capitec in enforcing any of the provisions of this Agreement shall be for the account of VMG and payable
                    on demand.</li>
                </ol>
              </li>

            </ol>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
