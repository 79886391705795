<div class="request_car">
  <h1>Request a Car</h1>
  <p class="text-black">Use the form below to tell us what you are looking for, and a sales representatives will contact you with a possible deal.</p>
  <div class="mailsent"
       *ngIf="mailSent">Your request has been sent!
  </div>
  <form [formGroup]="requestCarForm"
        *ngIf="requestCarFormHide"
        (ngSubmit)="onSubmit()">
    <h2 class="form-heading">Car Details</h2>
    <div class="row">
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['make'].invalid &&
       (requestCarForm.controls['make'].dirty || requestCarForm.controls['make'].touched || requestCarForm.controls['make'].errors )"
                  class="required">*
            </span>
        <input type="text"
               formControlName="make"
               placeholder="Make"
               class="form-control">
      </div>
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['masterModel'].invalid &&
       (requestCarForm.controls['masterModel'].dirty || requestCarForm.controls['masterModel'].touched || requestCarForm.controls['masterModel'].errors )"
                  class="required">*
            </span>
        <input type="text"
               formControlName="masterModel"
               placeholder="Model"
               class="form-control">
      </div>
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['year'].invalid &&
       (requestCarForm.controls['year'].dirty || requestCarForm.controls['year'].touched || requestCarForm.controls['year'].errors )"
                  class="required">*
            </span>
        <input type="number"
               formControlName="year"
               placeholder="Year"
               class="form-control">
      </div>
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['mileage'].invalid &&
       (requestCarForm.controls['mileage'].dirty || requestCarForm.controls['mileage'].touched || requestCarForm.controls['mileage'].errors )"
                  class="required">*
            </span>
        <input type="number"
               formControlName="mileage"
               placeholder="Odometer"
               class="form-control">
      </div>
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['color'].invalid &&
       (requestCarForm.controls['color'].dirty || requestCarForm.controls['color'].touched || requestCarForm.controls['color'].errors )"
                  class="required">*
            </span>
        <input type="text"
               formControlName="color"
               placeholder="Color"
               class="form-control">
      </div>
      <div class="form-group col-sm-4">
            <span *ngIf="requestCarForm.controls['condition'].invalid &&
       (requestCarForm.controls['condition'].dirty || requestCarForm.controls['condition'].touched || requestCarForm.controls['condition'].errors )"
                  class="required">*
            </span>
        <input type="text"
               formControlName="condition"
               placeholder="Condition"
               class="form-control">
      </div>

    </div>
    <hr>
    <h2 class="form-heading">Personal Details</h2>
    <div class="row">
      <div class="form-group col-sm-6">
            <span *ngIf="requestCarForm.controls['name'].invalid &&
       (requestCarForm.controls['name'].dirty || requestCarForm.controls['name'].touched || requestCarForm.controls['name'].errors )"
                  class="required">*
            </span>
        <input type="text"
               formControlName="name"
               placeholder="Full Name"
               class="form-control">
      </div>
      <div class="form-group  col-sm-6">
            <span *ngIf="requestCarForm.controls['contactNumber'].invalid &&
        (requestCarForm.controls['contactNumber'].dirty || requestCarForm.controls['contactNumber'].touched || requestCarForm.controls['contactNumber'].errors )"
                  class="required">*
            </span>
        <input type="tel"
               formControlName="contactNumber"
               placeholder="Contact Number"
               class="form-control"
               pattern="[0-9]*">
      </div>
      <div class="form-group col-sm-12">
            <span *ngIf="requestCarForm.controls['eMail'].invalid &&
        (requestCarForm.controls['eMail'].dirty || requestCarForm.controls['eMail'].touched || requestCarForm.controls['eMail'].errors )"
                  class="required">*
            </span>
        <input type="email"
               formControlName="eMail"
               placeholder="Email"
               class="form-control">
      </div>
    </div>
    <div class="form-group ">
              <textarea
                formControlName="message"
                placeholder="Additional Information"
                class="form-control"
                style="height: 120px">
              </textarea>
    </div>
    <div class="form-group center">
      <button class="sell-button"
              *ngIf="btnShow"
              [disabled]="!requestCarForm.valid">SEND
      </button>
    </div>
  </form>
  <div class="form-spinner"
       *ngIf="spinner">
    <div class="lds-dual-ring loader-container">
        <p-progressSpinner></p-progressSpinner>
      SENDING
    </div>
  </div>

</div>
