<section class="page-container">
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>Capitec Finance FAQ’s</h1>
          <div class="accordion customAccordion" id="accordionExample">
            <div class="faq">
              <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <p-card class="question-faq">
                  What is Capitec Vehicle Finance?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseOne" class="faq-body collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" >
                <p>It is personal credit offered at a discounted interest rate over an extended term, that you can use to pay for a vehicle at a dealership on “The Car Place” platform.
                  You can only apply through “The Car Place” website. If you qualify and your credit is approved, the money will be paid directly to the dealership on your behalf.</p>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <p-card class="question-faq">
                  What are the benefits?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseTwo" class="faq-body collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <ul>
                  <li>Enjoy a discounted interest rate from the prime lending rate.</li>
                  <li>You own the vehicle from day 1, not the bank</li>
                  <li>Finance any vehicle up to R500 000 over and extended term of up to 84 months</li>
                  <li>Save time, no need to visit a branch</li>
                  <li>Apply online and you can get pre-approved immediately</li>
                  <li>No further payment needs to be made, the credit is paid directly to the dealership on your behalf</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <p-card class="question-faq">
                  What is the maximum amount I could qualify for?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapseThree" class="faq-body collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <p>The term and amount that you could qualify for is based on your personal credit profile, salary details and affordability:</p>
                <ul>
                  <li>If you get paid monthly you could qualify for up to R500 000</li>
                  <li>If you get paid weekly you could qualify for up to R50 000</li>
                  <li>If you get paid fortnightly you could qualify for up to R12 000</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                <p-card class="question-faq">
                  Who could qualify?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse4" class="faq-body collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                <p>To apply for credit, you must be permanently employed, 18 years or older and have a valid SA ID document/smart ID card.
                  The credit assessment will be based on our credit granting rules, your personal credit profile, and your affordability. You may also need to provide:</p>
                <ul>
                  <li>Your latest 3 consecutive salary slips</li>
                  <li>A bank statement showing your latest 3 consecutive salary deposits (only if your salary is not paid into your Capitec account)</li>
                  <li>No proof of residential address is needed; however, you may be asked to provide supporting documents to complete your application</li>
                </ul>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                <p-card class="question-faq">
                  How would I know how much I could qualify for?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse5" class="faq-body collapse" aria-labelledby="heading5" data-parent="#accordionExample">
                <p>Search for a Capitec qualifying vehicle on “The Car Place” website and follow the apply now link.
                  You will receive a pre-approval on the full amount requested to purchase the vehicle (e.g., R125,000), or if you don’t qualify for the full amount
                  but you still qualify for credit (e.g., R85,000) it will be displayed on the screen after submission of your application.</p>
                <p>Alternatively, you can go to your nearest Capitec branch to find out if you qualify for finance on a vehicle that you are interested in.
                  You would simply need to take note of the reference number of that vehicle on our website to show to the consultant at the Capitec branch.</p>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                <p-card class="question-faq">
                  Do I need to be a Capitec client to apply?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse6" class="faq-body collapse" aria-labelledby="heading6" data-parent="#accordionExample">
                <p>No, you do not have to be a Capitec client to apply.</p>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                <p-card class="question-faq">
                  Can I move my existing loan to Capitec?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse7" class="faq-body collapse" aria-labelledby="heading7" data-parent="#accordionExample">
                <p>
                  You can’t use Capitec Vehicle Finance to settle an existing loan. But if the existing loan reflects on
                  the credit bureau and you qualify for another Capitec credit product (e.g., term loan or access facility), it can be settled with the proceeds.
                </p>
              </div>
            </div>

            <div class="faq">
              <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                <p-card class="question-faq">
                  What documents do I need to apply?
                  <fa-icon [icon]="chevronDown"></fa-icon>
                </p-card>
              </button>
              <div id="collapse8" class="faq-body collapse" aria-labelledby="heading8" data-parent="#accordionExample">
                <p>You will need:</p>
                <ul>
                  <li>A valid SA ID document/smart ID card (must be 18 years or older. If your name and/or surname differs from your ID document, a
                    marriage certificate, divorce court order or name change letter will be required)</li>

                  <li>Your latest 3 consecutive salary slips</li>
                  <li>A bank statement showing your latest 3 consecutive salary deposits (only if your salary is not paid into your Capitec account)</li>
                  <li>NO proof of residential address is needed; however, you may be asked to provide supporting documents to complete your application</li>

                </ul>
              </div>
            </div>

          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
              <p-card class="question-faq">
                How is the interest rate calculated and what is the cost (interest rate, monthly fee)?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse9" class="faq-body collapse" aria-labelledby="heading9" data-parent="#accordionExample">
              <p>The interest rate you qualify for is based on your personal credit profile, salary details and overall affordability.
                Capitec Vehicle Finance is offered at a discounted interest rate, which means you’ll pay less interest compared to any of our other credit products.
                Once you’ve taken credit with Capitec, you can view your interest rate on your statement or agreement.
                If you select a reduced term to pay back your loan, your interest rate will decrease
                (e.g., if you prefer to select a 48 month loan or a 72 month loan compared to a 84 month loan term, your interest rate will be lower).</p>
            </div>
          </div>

          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
              <p-card class="question-faq">
                What are the most common reasons my application would be declined?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse10" class="faq-body collapse" aria-labelledby="heading10" data-parent="#accordionExample">
              <p>There could be various reasons why your credit application was declined, and it could vary from client to client.</p>
              <p>The most common reasons are:</p>
              <ul>
                <li>Affordability – you do not meet the minimum affordability criteria</li>
                <li>Low credit score – when our standard credit scoring model indicates that the credit score for your application is below the minimum required</li>
                <li>You are under debt administration. Once you are under debt administration, we are not allowed to enter into a credit agreement with you</li>
                <li>One or more of your Capitec accounts were previously handed over to an external debt collector due to non-payment</li>
              </ul>
            </div>
          </div>

          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
              <p-card class="question-faq">
                Can I apply while I’m under debt review?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse11" class="faq-body collapse" aria-labelledby="heading11" data-parent="#accordionExample">
              <p>We unfortunately cannot provide you with credit while you’re in the debt review process.
                Only once you have fully paid up your accounts and the debt counsellor has issued you with a clearance certificate, will you be able to apply for credit again.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
              <p-card class="question-faq">
                Can I apply if I am blacklisted?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse12" class="faq-body collapse" aria-labelledby="heading12" data-parent="#accordionExample">
              <p>No, you cannot apply for credit if you are blacklisted.</p>
            </div>
          </div>

          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
              <p-card class="question-faq">
                How can I make sure I have a good credit score?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse13" class="faq-body collapse" aria-labelledby="heading13" data-parent="#accordionExample">
              <p>Your credit score is determined by your credit profile. Below are some guidelines on how to build and maintain a good credit score:</p>
              <ul>
                <li>If you usually pay for everything in cash, it will help to open some credit facilities to show good credit behaviour</li>
                <li>Make sure you pay your accounts on time, every time. Don’t skip payments – even if you pay double the next month, it will negatively affect your credit profile</li>
                <li>Don’t be tempted by easy credit. Avoid loan sharks and ‘pay day’ loans</li>
                <li>Every time you apply for credit it shows on your credit record. Too many checks will reduce your score, so don’t just apply for credit everywhere you go</li>
                <li>Make sure you complete any credit application carefully so there are no errors on your credit profile</li>
                <li>If you think you may not be able to pay your loan or account, contact your credit provider to discuss repayment options.</li>
              </ul>
              <p>Don’t ignore the problem. We encourage you to download our free Money Guide to get tips and learn more about how to manage your money.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
              <p-card class="question-faq">
                How can I improve my credit score/record?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse14" class="faq-body collapse" aria-labelledby="heading14" data-parent="#accordionExample">
              <p>To help improve a low credit score:</p>
              <ul>
                <li>Make sure that any accounts in arrears are brought up to date, and that all defaulted accounts are settled in full. If you need to,
                  contact each creditor and try to arrange a suitable payment arrangement to help you settle the accounts</li>
                <li>Ensure that all your remaining accounts are paid on time each month and try not to make any new credit applications</li>
                <li>Avoid quick, ‘pay day’ loans as this will reflect on your credit profile and contribute to a lower credit rating</li>
                <li>Once you have done the above, maintain good payment behaviour for at least 3 – 6 months before you apply for credit again</li>
              </ul>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
              <p-card class="question-faq">
                If the vehicle has a problem after the agreement has been concluded and the dealership has been paid, will I be able to cancel the loan agreement and return the vehicle?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse15" class="faq-body collapse" aria-labelledby="heading15" data-parent="#accordionExample">
              <p>The credit will have to be repaid in full according to the Capitec credit agreement signed,
                and any problems with the vehicle will have to be resolved between the client and the dealership. Capitec does not take responsibility for the quality.
                Certain vehicles could qualify for an extended warranty, and the client should enquire about these options at the dealership when purchasing a vehicle.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
              <p-card class="question-faq">
                What is the difference between Capitec Vehicle Finance and a term loan/personal loan or a student loan for example?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse16" class="faq-body collapse" aria-labelledby="heading16" data-parent="#accordionExample">
              <p>Capitec Vehicle Finance is used for a specific need being the finance of a vehicle purchase.
                It’s offered at a discounted interest rate and over an extended term. You can only apply through The Car Place website.
                If you qualify and your credit is approved, the money will be paid directly to the dealership on your behalf.
                A term loan or personal loan can be used for various needs and the money is paid into your bank account on approval,
                but you won’t receive the advantage of a discounted interest rate or an extended term.</p>
            </div>
          </div>


          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
              <p-card class="question-faq">
                Where can I apply? Can I visit a Capitec branch?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse17" class="faq-body collapse" aria-labelledby="heading17" data-parent="#accordionExample">
              <p>To apply you have two options:</p>
              <p>You can apply online directly on “The Car Place’s” website. Complete the online application to see if you qualify, and if you do,
                a consultant will contact you asap to complete your application (taking weekend non-trading hours into account).</p>
              <p>Or you can apply in branch at your nearest Capitec Branch after viewing and test driving the vehicle at the dealership.
                For this option, you would need to take the Offer to Purchase and TheCarPlace payment document from the dealership to the branch when you apply.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
              <p-card class="question-faq">
                How soon will I get the money?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse18" class="faq-body collapse" aria-labelledby="heading18" data-parent="#accordionExample">
              <p>Once your application has been approved, you just have to approve your DebiCheck debit order.
                The money will then be paid to the dealership after you completed the purchase process of the vehicle at the dealership.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
              <p-card class="question-faq">
                Is the money paid directly to the dealership?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse19" class="faq-body collapse" aria-labelledby="heading19" data-parent="#accordionExample">
              <p>Yes, the money will be paid directly to the dealership using the bank account details provided on the invoice.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
              <p-card class="question-faq">
                What is the cost (interest rate, monthly fee)?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse20" class="faq-body collapse" aria-labelledby="heading20" data-parent="#accordionExample">
              <p>The interest rate you qualify for is based on your personal credit profile and affordability.
                Capitec Vehicle Finance is offered at a discounted interest rate, which means you’ll pay less interest compared to any of our other credit products.
                Once you’ve taken credit with Capitec, you can view your interest rate on your statement or agreement.
                If you select a reduced term to pay back your loan, your interest rate will decrease
                (e.g., if you select a 48 month loan term compared to a 84 month loan term, your interest rate will be lower).</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
              <p-card class="question-faq">
                How do I complete my application online after doing an estimate?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse21" class="faq-body collapse" aria-labelledby="heading21" data-parent="#accordionExample">
              <p>Once you’ve completed the online estimate steps, you’ll have the option to choose ‘I’m interested’ or ‘Cancel’.
                If you are happy with the pre-approval offer and would like to proceed with the credit application,
                choose ‘I’m interested’ and a consultant will contact you asap to complete your application.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
              <p-card class="question-faq">
                How do I finalise my application?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse22" class="faq-body collapse" aria-labelledby="heading22" data-parent="#accordionExample">
              <p>Make sure that you have all the required documents to finalise your credit approval so that we can pay out the proceeds of your loan to the dealership.
                Documents can be sent to CreditDocuments@capitecbank.co.za. Please ensure the provisional quote you send is the final one.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
              <p-card class="question-faq">
                Who can complete the application?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse23" class="faq-body collapse" aria-labelledby="heading23" data-parent="#accordionExample">
              <p>You must complete the application yourself on “The Car Place’s” website or at your nearest Capitec branch.
                For your own safety and the protection of your personal information, nobody else should complete the application on your behalf.</p>
            </div>
          </div>
          <div class="faq">
            <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
              <p-card class="question-faq">
                Where can I direct my queries to?
                <fa-icon [icon]="chevronDown"></fa-icon>
              </p-card>
            </button>
            <div id="collapse24" class="faq-body collapse" aria-labelledby="heading24" data-parent="#accordionExample">
              <p>You can call us on 0860 88 88 40, Monday – Friday 8am – 6pm or Saturdays 8am – 1pm.</p>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
