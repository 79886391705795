import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {faCarSide} from '@fortawesome/free-solid-svg-icons';
import {LocalDataService} from '../../../services/local-data.service';
import {AllCarData} from '../../../models/all-car-data';

@Component({
  selector: 'app-brochure-enquiry',
  templateUrl: './brochure-enquiry.component.html',
  styleUrls: ['./brochure-enquiry.component.scss']
})
export class BrochureEnquiryComponent{
  @Input() carData: AllCarData;
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public spinnerColor = '#000000';
  public enquireFormHide = true;

  public dataCrm = {};
  public enquireForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    contactNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
  });
  public arrowIcon = faCarSide;

  constructor(
    private http: HttpClient,
    public localData: LocalDataService
  ) {
  }

  public onSubmit() {
    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        ToList: this.carData.branchEmailAddress,
        CcList: 'info@thecarplace.co.za',
        BccList: 'leads@thecarplace.co.za, webmaster@vmgsoftware.co.za',
        Subject: 'The CarPlace Brochure Enquiry for ' + this.carData.make + ' ' + this.carData.masterModel,
        Message: ''
          + '\n\nMake: ' + this.carData.make
          + '\nModel: ' + this.carData.masterModel
          + '\nstockId: ' + this.carData.id
          + '\nPrice: ' + this.carData.sellingPrice
          + '\nContact: ' + this.enquireForm.value.contactNumber,
        MessageSubject: 'The CarPlace enquiry',
        ToName: this.localData.store.eMail.enquireMailName,
        FromName: this.enquireForm.value.name,
        FromEmail: this.enquireForm.value.eMail,
        Attachments: {}
      }
    };
    this.dataCrm = {
      dealer_id: 25,
      branch_guid: '5dee6391-00bc-472c-8581-d3785d9b8bb0',
      cellphone_no: this.enquireForm.value.contactNumber.toString(),
      make: this.carData.make,
      colour: this.carData.color,
      email_address: this.enquireForm.value.eMail,
      lead_name: this.enquireForm.value.name,
      lead_source: 'TheCarPlace',
      mileage: this.carData.mileage,
      price: this.carData.sellingPrice,
      selling: false,
      stock_id: this.carData.oldId,
      year: this.carData.modelYear
    };

    const dataString = JSON.stringify(this.formDetails);
    const leadString = JSON.stringify(this.dataCrm);

    this.http.post<any>(this.localData.store.serverMailSellApi, dataString,
      {headers: this.localData.store.headers}).subscribe(
        {
          next: () => {
            this.enquireForm.reset();
            this.enquireFormHide = false;
            this.btnShow = true;
            this.mailSent = true;
            this.spinner = false;
            document.querySelector('html').scrollIntoView();
          },
          error: () => {
            this.btnShow = false;
            this.spinner = false;
          }
        });

    this.http.get('https://thecarplace.co.za/lead-token.php', {responseType: 'text'}).subscribe(token => {
      this.http.post<any>('https://api.vmgdms.com/leads/v1/new_dealer_lead', leadString,
        { headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            authorization: 'Bearer ' + token
          }}).subscribe(
          {
            next: () => {
            },
            error: (err) => {
              console.log('leads failed: ', err);
            }
          });
    });
  }
}
