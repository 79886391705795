import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';

import { LocalDataService } from '../../../services/local-data.service';
import { AllCarData } from '../../../models/all-car-data';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-vehicle-enquiry',
  templateUrl: './vehicle-enquiry.component.html',
  styleUrls: ['./vehicle-enquiry.component.scss']
})
export class VehicleEnquiryComponent implements OnInit {
  @Input() carData: AllCarData;
  public btnShow = true;
  public formDetails = {};
  public mailSent = false;
  public spinner = false;
  public deposit: boolean;
  public credit: boolean;
  public tradeIn: boolean;
  public finance: boolean;
  public spinnerColor = '#000000';
  public enquireFormHide = true;
  public validForm = false;

  public dataCrm = {};
  public enquireForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    contactNumber: new UntypedFormControl('', [Validators.required, Validators.min(99999999), Validators.max(999999999)]),
    eMail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    suburb: new UntypedFormControl(''),
    creditCheck: new UntypedFormControl(''),
    credit: new UntypedFormControl(''),
    deposit: new UntypedFormControl('', ),
    depositAmount: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
    tradeIn: new UntypedFormControl('', ),
    tradeInDetails: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
    financeCheck: new UntypedFormControl(''),
    subscribed: new UntypedFormControl(true),
    myId: new UntypedFormControl('', [Validators.min(999999999999), Validators.max(9999999999999)]),

  });
  public mailServerApi: string;

  constructor(
    private http: HttpClient,
    public localData: LocalDataService) { }

  public ngOnInit() {
    this.formListener();

  }

  public depositChange() {
    if (this.enquireForm.value.deposit) {
      this.enquireForm.controls.depositAmount.enable();
    } else {
      this.enquireForm.controls.depositAmount.disable();
    }
  }
  public tradeinChange() {
    if (this.enquireForm.value.tradeIn) {
      this.enquireForm.controls.tradeInDetails.enable();
    } else {
      this.enquireForm.controls.tradeInDetails.disable();
    }
  }

  public formListener(): void {
    this.enquireForm.valueChanges.subscribe(() => {
      this.validForm = this.enquireForm.valid;
    });
  }

  public onSubmit() {

    this.spinner = true;
    this.btnShow = false;
    this.formDetails = {
      notification: {
        BranchId:  this.carData.branchId,
        CompanyId: this.carData.companyOldId,
        ToList: 'leads@thecarplace.co.za',
        CcList: '',
        BccList: 'webmaster@vmgsoftware.co.za',
        Subject: 'The CarPlace Showroom Enquiry for ' + this.carData.make + ' ' + this.carData.masterModel,
        Message: '',
        MessageSubject: 'Website enquiry',
        ToName: '',
        FromName: this.enquireForm.value.name,
        FromEmail: this.enquireForm.value.eMail,
        Attachments: {},
        Stock: {
          ReferenceNumber: (this.carData.companyOldId + ' - ' + this.carData.oldId).toString(),
          Make: this.carData.make,
          Model: this.carData.model,
          Variant: this.carData.variant,
          Year: this.carData.modelYear,
          SellingPrice: this.carData.sellingPrice,
          StockCode: this.carData.code.toString(),
          VehicleWebLink: window.location.href,
          DealershipName: this.carData.branchTradingName
        },
        Finance: {
          Deposit: this.enquireForm.value.deposit || false,
          DepositAmount: this.enquireForm.value.depositAmount || 0,
          TradeIn: this.enquireForm.value.tradeIn || false,
          TradeInStock: this.enquireForm.value.tradeInDetails,
          FinanceRequired: this.enquireForm.value.financeCheck || false,
          CreditCheck: this.enquireForm.value.creditCheck || false,
          IdNumber: this.enquireForm.value.myId.toString()
        },
        LeadEmail: {
          Name: this.enquireForm.value.name,
          Email: this.enquireForm.value.eMail,
          CellNumber: this.enquireForm.value.contactNumber,
          Suburb: this.enquireForm.value.suburb
        }
      }
    };

    this.dataCrm = {
      dealer_id: this.carData.companyOldId,
      branch_guid: this.carData.branchId,
      cellphone_no: this.enquireForm.value.contactNumber.toString(),
      make: this.carData.make,
      model: this.carData.model,
      colour: this.carData.color,
      email_address: this.enquireForm.value.eMail,
      lead_name: this.enquireForm.value.name,
      lead_source: 'TheCarPlace',
      mileage: this.carData.mileage,
      price: this.carData.sellingPrice,
      selling: false,
      stock_id: this.carData.oldId,
      year: this.carData.modelYear,
      stock_code: this.carData.code,
      message: 'Dealer Name: ' +  this.carData.branchTradingName + ' Branch Id: ' + this.carData.branchId,
      subscribe: this.enquireForm.value.subscribed
    };

    const dataString = JSON.stringify(this.formDetails);
    const leadString = JSON.stringify(this.dataCrm);

    if (this.carData.sellingPrice <= 500000) {
      this.mailServerApi = `${environment.emailVehicleEnquiriesBaseUrl}/1`;
    } else if (this.carData.sellingPrice > 500000) {
      this.mailServerApi = `${environment.emailVehicleEnquiriesBaseUrl}/2`;
    }
    this.http.post<any>(this.mailServerApi, dataString,
      {headers: this.localData.store.headers}).subscribe(
      (res) => {
        this.enquireForm.reset();
        this.enquireFormHide = false;
        this.btnShow = true;
        this.mailSent = true;
        this.spinner = false;
        document.querySelector('html').scrollIntoView();
      },
      (err) => {
        this.btnShow = false;
        this.spinner = false;
      }
    );

    this.http.get('https://thecarplace.co.za/lead-token.php', {responseType: 'text'}).subscribe(token => {
      this.http.post<any>('https://api.vmgdms.com/leads/v1/new_dealer_lead', leadString,
      { headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          authorization: 'Bearer ' + token
        }}).subscribe(
      (res) => {
      },
      (err) => {
        console.log('leads failed: ', err);
      });
  });
  }
}
