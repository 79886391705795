<div class = "car-enquire">
  <div class = "mailsent"
       *ngIf = "mailSent">Your request has been sent!
  </div>
  <div style="text-align: center;" class="container form-container">
    <div  *ngIf="carData?.sellingPrice <= 500000" style="text-align: center">
      <p *ngIf = "!mailSent" style=" padding: 5px; font-size: 15px;color: #007b9a;font-weight: 600;">
        Alternatively, fill out the form below and the dealership will contact you.
      </p>
    </div>
    <form [formGroup] = "enquireForm"
          *ngIf = "enquireFormHide"
          (ngSubmit) = "onSubmit()">
      <div class = "row">
        <div class="col-md-12">
          <div class = "form-group">
            <span *ngIf = "enquireForm.controls['name'].invalid &&
        (enquireForm.controls['name'].dirty || enquireForm.controls['name'].touched || enquireForm.controls['name'].errors )"
                  class = "required">*</span>
            <input type = "text"
                   formControlName = "name"
                   placeholder = "Name"
                   class = "form-control">
          </div>
        </div>
        <div class="col-md-12">
          <div class = "form-group">
            <span *ngIf = "enquireForm.controls['contactNumber'].invalid &&
        (enquireForm.controls['contactNumber'].dirty || enquireForm.controls['contactNumber'].touched || enquireForm.controls['contactNumber'].errors )"
                  class = "required">
               *
            </span>
            <input type = "tel"
                   formControlName = "contactNumber"
                   placeholder = "Cell Number"
                   class = "form-control"
                   pattern="[0-9]*">
          </div>
        </div>
      </div>
      <div class = "form-group">
            <span *ngIf = "enquireForm.controls['eMail'].invalid &&
        (enquireForm.controls['eMail'].dirty || enquireForm.controls['eMail'].touched || enquireForm.controls['eMail'].errors )"
                  class = "required">
               *
            </span>
        <input type = "email"
               formControlName = "eMail"
               placeholder = "Email"
               class = "form-control">
      </div>
      <div class = "form-group">
            <span>
            </span>
        <input type = "text"
               formControlName = "suburb"
               placeholder = "Suburb"
               class = "form-control">
      </div>


      <div class="form-group" style="margin-bottom: 8px">
        <label class="check">
          <input type="checkbox"
                 (change)="depositChange()"
                 formControlName="deposit" >
          Do you have a deposit?
        </label>
      </div>
      <div class="form-group"
           *ngIf="enquireForm.controls['deposit'].value">
        <span *ngIf = "enquireForm.controls['depositAmount'].invalid &&
        (enquireForm.controls['depositAmount'].dirty || enquireForm.controls['depositAmount'].touched || enquireForm.controls['depositAmount'].errors )"
              class = "required">
               *
            </span>
        <input type="number"
               required
               formControlName="depositAmount"
               placeholder="Deposit Amount"
               class="form-control">
      </div>
      <div class="form-group" style="margin-bottom: 8px">
        <label class="check">
          <input type="checkbox"
                 (change)="tradeinChange()"
                 formControlName="tradeIn">
          Do you have a trade in?
        </label>
      </div>

      <div class="form-group"
           *ngIf="enquireForm.controls['tradeIn'].value">
                <span *ngIf = "enquireForm.controls['tradeInDetails'].invalid &&
        (enquireForm.controls['tradeInDetails'].dirty || enquireForm.controls['tradeInDetails'].touched || enquireForm.controls['tradeInDetails'].errors )"
                      class = "required">
               *
            </span>
        <input formControlName="tradeInDetails"
                  placeholder="Year, Make, Model, Condition"
                  class="form-control">
      </div>
      <div class="form-group" style="margin-bottom: 8px">
        <label class="check">
          <input type="checkbox"
                 formControlName="financeCheck">
          Do you need finance?
        </label>
      </div>
      <div class="form-group"
           *ngIf="finance">
        <label class="check">
          <input type="checkbox"
                 formControlName="creditCheck">
          Permission granted for NCR credit check
        </label>
      </div>
      <div class="form-group"
           *ngIf="credit">
        <input type="number"
               required
               formControlName="myId"
               placeholder="ID Number"
               class="form-control">
      </div>
      <div class="form-group" style="margin-bottom: 8px">
        <label class="check">
          <input type="checkbox"
                 [checked]="true"
                 formControlName="subscribed">
          Subscribe to our newsletter?
        </label>
      </div>
      <div class="form-group">
        <button
                [ngClass]="{'vehicle-enquiry-button' : validForm }"
                *ngIf="btnShow"
                class="submit-button"
                [disabled] = "!enquireForm.valid">Submit Enquiry
        </button>
        <p class="text-left">* - Required Field</p>
        <div *ngIf="spinner">
          <img src="assets/images/TCP_Enquiry_Gif.gif" alt="loading Filter Data">
        </div>
      </div>
    </form>
  </div>
</div>

