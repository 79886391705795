import {Component} from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './capitec-terms-conditions.component.html',
  styleUrls: ['./capitec-terms-conditions.component.scss']
})
export class CapitecTermsConditionsComponent{

}
