<section class="page-container">
  <div class="body-container ">
    <div class="background">
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="info-box text-center">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="border">
                    <h3 style="margin-top: 10px">A BRAND NEW WAY FOR DEALERS</h3>
                    <h5 style="color: #E63934;font-weight: 300">TO ADVERTISE AND SELL</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="logo-box">
                    <div class="col-md-5">
                      <img src="../../../../../assets/images/new-logo.webp" alt="carplace-logo">
                    </div>
                    <div class="col-md-2">
                      <span>&</span>
                    </div>
                    <div class="col-md-5">
                      <img src="../../../../../assets/images/welcome-page/CapitecLanding_CapitecLogoSmall.webp" alt="capitec-logo">

                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="border">
                    <p style="color: #299846; margin-bottom: 0">EXCLUSIVELY AVAILABLE TO</p>
                    <img src="../../../../../assets/images/welcome-page/CapitecLanding_VMGLogo.webp" alt="vmg-logo">
                  </div>
                </div>
              </div>
              <a [routerLink]="['/dealer-sign-up']" >
                <button>Get Started</button>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container welcome-page">
      <div class="row carplace-faq">
        <div class="col-md-12 text-center">
          <img src="../../../../../assets/images/welcome-page/CapitecLanding_Info.webp" alt="attention-icon" class="attention-icon">
          <h3>WHAT IS THE CAR PLACE, <br> AND WHY IS IT A GAMECHANGER?</h3>
        </div>
        <div class="col-md-6">
          <div class="block">
            <p>Unlike other aggregators your dealership can advertise with <strong>TheCarPlace</strong> and have no subscription or monthly fees.
              It is <strong>100%</strong> free to advertise, as much as you want. You <strong>ONLY</strong> pay a small spotters fee when a car is sold.
            </p>
            <div class="row logo-button-box">
              <div class="col-md-6">
                <img src="../../../../../assets/images/new-logo.webp" alt="carplace-logo">
              </div>
              <div class="col-md-6 text-right">
                <p>Try it out!</p>
                <a [routerLink]="['/dealer-sign-up']" >

                  <button>Get Started</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img src="../../../../../assets/images/welcome-page/CapitecLanding_Car1.webp" alt="car-one">
        </div>
      </div>

      <div class="giant-border capitec-faq">

        <div class="row capitec-block">
          <div class="col-md-12 text-center">
            <h3>WHY IS CAPITEC INVOLVED? <br>WE ARE CHANGING THE WAY YOU FINANCE</h3>
          </div>
          <div class="col-md-6">
            <img src="../../../../../assets/images/welcome-page/CapitecLanding_Car2.webp" alt="car-two">
          </div>
          <div class="col-md-6">
            <div class="block">
              <p>With the new <strong>Capitec</strong> Finance Channel, qualifying dealers can finance <strong>ANY</strong> car up to the value of <strong>R500,000</strong>
                - even with <strong>invoice extras</strong> such as VAPS.
                The finance process has also been stripped down and <strong>simplified</strong>: You advertise, an approved client comes to you, you invoice and deliver!
                Last, but not least, it is <strong>100% free</strong> - not even a spotters fee!</p>

              <div class="row logo-button-box">
                <div class="col-md-6">
                  <p>Let's get started</p>
                  <a [routerLink]="['/dealer-sign-up']" >

                    <button>Get Started</button>
                  </a>
                </div>
                <div class="col-md-6">
                  <img src="../../../../../assets/images/welcome-page/CapitecLanding_CapitecLogo.webp" alt="capitec-logo">
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <h3>LEARN MORE ABOUT THE PROCESS</h3>
          </div>
          <div class="col-md-12 ">
            <div class="process-img">
              <img src="../../../../../assets/images/VMGSite_Capitec_Roadmap.png" alt="car-one">
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12 text-center">
          <h3>WHAT IS THE DIFFERENCE?</h3>
        </div>
        <div class="col-md-6">
          <div class="border carplace-faq">
            <div class="text-center">
              <div class="carplace-faq-img">
                <img src="../../../../../assets/images/new-logo.webp" alt="carplace-logo">
              </div>
            </div>
            <p>TheCarPlace is a brand new website where dealerships can advertise their cars for free!</p>
            <p>
              <strong>Is it really free?</strong>
              <br>
              100% Free to advertise. You only pay a spotters fee once a car from your dealer has been sold to a lead from TheCarPlace.
            </p>
            <p>
              <strong>Can any dealer advertise on TheCarPlace?</strong>
              <br>
              Any dealer using VMG DMS, yes!
            </p>
            <p>
              <strong>When will a spotters fee apply?</strong>
              <br>
              When a vehicle is sold to a lead from TheCarPlace - EXCEPT for when the vehicle has been financed by Capitec.
            </p>
            <p>
              <strong>How do I advertise on TheCarPlace?</strong>
              <br>
              TheCarPlace is exclusive to VMG DMS WEBMASTER users.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="border capitec-faq">
            <div class="text-center">
              <div class="capitec-faq-img">
                <img src="../../../../../assets/images/welcome-page/CapitecLanding_CapitecLogoSmall.webp" alt="capitec-logo">
              </div>
            </div>
            <p>The Capitec Finance Channel is a customized and exclusive showroom that qualified dealers can advertise their approved vehicles to existing and potential Capitec customers.
              Capitec is helping VMG customers sell more vehicles.</p>
            <p>
              <strong>Is it really free?</strong>
              <br>
              100% Free. Not even a spotters fee!
            </p>
            <p>
              <strong>Can any dealer advertise on the Capitec channel?</strong>
              <br>
              Yes, if the dealership qualifies. We just want to make sure Capitec’s clients are well looked after, we are sure you want the same.
            </p>
            <p>
              <strong>When will a spotters fee apply?</strong>
              <br>
              No! If a vehicle is sold via the Capitec Finance Channel then no spotters fees apply.
              Advertising your qualified vehicles on the Capitec Finance Channel is at no cost to your dealership.
            </p>
            <p>
              <strong>How do I advertise on TheCarPlace?</strong>
              <br>
              The Capitec finance channel is exclusive to all VMG WEBMASTER users.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center" style="padding: 0">
        <div class="welcome-footer">
          <img src="../../../../../assets/images/welcome-page/CapitecLanding_Lines.webp" alt="lines" class="lines-img">
          <h3>WHAT ARE YOU WAITING FOR?</h3>
          <a [routerLink]="['/dealer-sign-up']" >
            <button>Get Started</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
