import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {faFacebookF, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faEnvelopeOpen, faLink, faPhone} from '@fortawesome/free-solid-svg-icons';

import {CarsFilterService} from '../../../services/cars-filter.service';
import {Brochure} from '../../../models/brochure';
import {ShowroomData} from '../../../models/showroom-data';
import {LocalDataService} from '../../../services/local-data.service';
import {Meta, Title} from '@angular/platform-browser';
import {AllCarData} from "../../../models/all-car-data";

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrochureComponent implements OnInit, OnDestroy {
  car: AllCarData = new AllCarData();
  public responsiveOptions: any[] = [
    {
      breakpoint: '3000px',
      numVisible: 4
    }
  ];
  public displayCustom: boolean | undefined;
  public activeIndex: number = 0;
  public facebookLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';
  public twitterLink: string = 'https://www.twitter.com/share?url=';
  public whatsAppLink: string = 'https://api.whatsapp.com/send?text=';
  public recommendedCars: Array<ShowroomData> = [];

  public slides: any[] = [];
  public defaultImage = '../../assets/images/nopic.png';

  public phoneIcon = faPhone;
  public emailIcon = faEnvelopeOpen;
  public linkIcon = faLink;

  public url = this.router.url;
  public extras = Array<any>();
  public mobileTabs: boolean;

  constructor(
    private carsFilterService: CarsFilterService,
    private routeParams: ActivatedRoute,
    private router: Router,
    public brochureModel: Brochure,
    public localData: LocalDataService,
    public faLibrary: FaIconLibrary,
    private meta: Meta,
    private title: Title,
  ) {
    faLibrary.addIcons(faFacebookF, faWhatsapp, faTwitter);
  }

  public ngOnInit() {
     this.facebookLink += window.location.href;
     this.twitterLink += window.location.href;
     this.whatsAppLink += window.location.href;
    this.routeParams.paramMap.subscribe(async params => {

      this.brochureModel.oldId = params.get('oldId');
      this.brochureModel.branchOldId = params.get('branchOldId');

      this.carsFilterService.filterReferenceNumberCarPlace(Number(this.brochureModel.branchOldId), Number(this.brochureModel.oldId)).subscribe(response => {

        const allCarData = new AllCarData();
        allCarData.id = response['stocks'][0].id;
        allCarData.make = response['stocks'][0].make;
        allCarData.bodyType = response['stocks'][0].bodyType;

        this.carsFilterService.getCar(allCarData).subscribe(car => {

          this.car = car;
          this.fixAbbreviation();
          this.car.extras = JSON.parse(this.car.extras);
          this.setMetaData();
          this.trimWebsiteUrl();
          this.convertToObject();
        });
      });
      this.carsFilterService.selectedCompanyOldId = Number(this.brochureModel.branchOldId);
      this.carsFilterService.selectedSkip = 0;
      this.carsFilterService.selectedTop = 8;
      this.carsFilterService.getStocksByPagination()
        .subscribe(http => {
          this.recommendedCars = http['stocks'];
        });
      this.mobileCheckForTabs();
    });

  }

  public imageClick(clickedImage: any) {
    this.activeIndex = this.slides.findIndex(image => image === clickedImage);
    this.displayCustom = true;
  }

  public convertToObject() {
    this.slides = [];
    const imagesObject = JSON.parse(this.car.images);
    imagesObject.forEach((item: any) => {
      this.slides.push(item.image_url);
    });
  }



  private fixAbbreviation(): void {
    if (this.car.stockInformationFuelType === 'D') {
      this.car.stockInformationFuelType = 'Diesel';
    }
    if (this.car.stockInformationFuelType === 'P') {
      this.car.stockInformationFuelType = 'Petrol';
    }
    if (this.car.stockInformationFuelType === 'E') {
      this.car.stockInformationFuelType = 'Electric';
    }
    if (this.car.stockInformationTransmission === 'A') {
      this.car.stockInformationTransmission = 'Automatic';
    }
    if (this.car.stockInformationTransmission === 'M') {
      this.car.stockInformationTransmission = 'Manual';
    }
    if (this.car.stockInformationCooling === 'W') {
      this.car.stockInformationCooling = 'Water';
    }
    if ( this.car.stockInformationCooling  === 'A') {
      this.car.stockInformationCooling  = 'Antifreeze';
    }
  }

  private mobileCheckForTabs(): void {
    if (window.screen.width < 768) {
      this.mobileTabs = true;
    }
  }

  public goToAllCars(): void {
    window.location.pathname = this.car.oldId + '/' + this.car.companyOldId;
  }

  public scrollToTop(): void {
    window.scroll(0, 0);
  }

  public trimWebsiteUrl() {
    this.car.branchWebsiteUrl = this.car.branchWebsiteUrl.replace('http://', '');
    this.car.branchWebsiteUrl = this.car.branchWebsiteUrl.replace('https://', '');
  }


  private setMetaData() {
    this.title.setTitle('TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant);
    this.meta.addTags([
      {name: 'title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'description', content: this.car.description || 'No description available'},
      {name: 'theme-color', content: '#ffffff'},
      {name: 'creative_description', content: this.car.description || 'No description available'},
      {name: 'author', content: 'VMG Software'},

      {property: 'og:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:description', content: this.car.description || 'No description available'},
      {property: 'og:url', content: 'https://thecarplace.co.za' + this.url},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:image', content: this.slides[0]},
      {property: 'og:image:url', content: this.slides[0]},
      {property: 'og:image:width', content: '300'},
      {property: 'og:image:height', content: '300'},
      {property: 'og:image:alt', content: 'TheCarPlace ' + this.car.make + ' ' + this.car.variant},
      {property: 'og:country-name', content: 'South Africa'},

      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:title', content: 'TheCarPlace' + ' | ' + this.car.make + ' ' + this.car.variant},
      {name: 'twitter:description', content: this.car.description || 'No description available'},
      {name: 'twitter:image', content: this.slides[0]},
      {name: 'twitter:image:url', content: this.slides[0]},
      {name: 'twitter:domain', content: 'thecarplace.co.za'},
    ]);
  }

  public ngOnDestroy() {
    this.carsFilterService.selectedCompanyOldId = 0;
  }
}
