<div class="home-body-container">
  <div class="container">
    <div class="row m-0">
      <div class="col-sm-12">
        <h1>Sell your Car</h1>
        <h5>Want to sell your car?</h5>
        <p>Let TheCarPlace know the details and we’ll get your closest approved dealership to contact you and help you evaluate your vehicle and buy it from you at their best possible price.</p>
        <p>TheCarPlace supports local and small family business and works with a wide network of approved independent dealerships who are able to provide you
          with personal service for all your car selling and buying needs.</p>
        <div class="mailsent"
             *ngIf="mailSent">Your request has been sent!
        </div>
        <form [formGroup]="sellCarForm"
              *ngIf="sellCarFormHide"
              (ngSubmit)="onSubmit()">
          <h2 class="form-heading">Car Details</h2>
          <div class="row">
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['make'].invalid &&
       (sellCarForm.controls['make'].dirty || sellCarForm.controls['make'].touched || sellCarForm.controls['make'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="make"
                     placeholder="Make"
                     class="form-control">
            </div>
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['masterModel'].invalid &&
       (sellCarForm.controls['masterModel'].dirty || sellCarForm.controls['masterModel'].touched || sellCarForm.controls['masterModel'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="masterModel"
                     placeholder="Model"
                     class="form-control">
            </div>
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['year'].invalid &&
       (sellCarForm.controls['year'].dirty || sellCarForm.controls['year'].touched || sellCarForm.controls['year'].errors )"
                  class="required">*
            </span>
              <input type="number"
                     formControlName="year"
                     placeholder="Year"
                     class="form-control">
            </div>
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['mileage'].invalid &&
       (sellCarForm.controls['mileage'].dirty || sellCarForm.controls['mileage'].touched || sellCarForm.controls['mileage'].errors )"
                  class="required">*
            </span>
              <input type="number"
                     formControlName="mileage"
                     placeholder="Odometer"
                     class="form-control">
            </div>
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['color'].invalid &&
       (sellCarForm.controls['color'].dirty || sellCarForm.controls['color'].touched || sellCarForm.controls['color'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="color"
                     placeholder="Color"
                     class="form-control">
            </div>
            <div class="form-group col-sm-4">
            <span *ngIf="sellCarForm.controls['condition'].invalid &&
       (sellCarForm.controls['condition'].dirty || sellCarForm.controls['condition'].touched || sellCarForm.controls['condition'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="condition"
                     placeholder="Condition"
                     class="form-control">
            </div>

            <div class="form-group col-sm-12">
              <p-fileUpload name="sellImages[]"
                            [showUploadButton]="false"
                            [showCancelButton]="true"
                            previewWidth="110"
                            fileLimit="10"
                            mode="advanced"
                            chooseLabel="Select Images"
                            (onSelect)="fileProgress($event)"
                            (onRemove)="fileRemove($event)"
                            [multiple]="true"
                            accept="image/*">
                <ng-template pTemplate="content">
                  <div class="dragdrop">
                    <fa-icon style='margin-right:15px; font-size:4rem; vertical-align:middle;'
                             [icon]="cameraIcon"></fa-icon>
                    DRAG / DROP Files
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
          <hr>
          <h2 class="form-heading">Personal Details</h2>
          <div class="row">
            <div class="form-group col-sm-6">
            <span *ngIf="sellCarForm.controls['name'].invalid &&
       (sellCarForm.controls['name'].dirty || sellCarForm.controls['name'].touched || sellCarForm.controls['name'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="name"
                     placeholder="Full Name"
                     class="form-control">
            </div>
            <div class="form-group  col-sm-6 " >
            <span *ngIf="sellCarForm.controls['contactNumber'].invalid &&
        (sellCarForm.controls['contactNumber'].dirty || sellCarForm.controls['contactNumber'].touched || sellCarForm.controls['contactNumber'].errors )"
                  class="required">*
            </span>
              <input type="tel"
                     formControlName="contactNumber"
                     placeholder="Contact Number"
                     class="form-control"
                     pattern="[0-9]*">
            </div>
            <div class="form-group col-sm-12">
            <span *ngIf="sellCarForm.controls['eMail'].invalid &&
        (sellCarForm.controls['eMail'].dirty || sellCarForm.controls['eMail'].touched || sellCarForm.controls['eMail'].errors )"
                  class="required">*
            </span>
              <input type="email"
                     formControlName="eMail"
                     placeholder="Email"
                     class="form-control">
            </div>
            <div class="form-group col-sm-6">
            <span *ngIf="sellCarForm.controls['suburb'].invalid &&
       (sellCarForm.controls['suburb'].dirty || sellCarForm.controls['suburb'].touched || sellCarForm.controls['suburb'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="suburb"
                     placeholder="Suburb"
                     class="form-control">
            </div>
            <div class="form-group col-sm-6">
            <span *ngIf="sellCarForm.controls['province'].invalid &&
       (sellCarForm.controls['province'].dirty || sellCarForm.controls['province'].touched || sellCarForm.controls['province'].errors )"
                  class="required">*
            </span>
              <input type="text"
                     formControlName="province"
                     placeholder="Province"
                     class="form-control">
            </div>
          </div>

          <div class="form-group ">
              <textarea
                formControlName="message"
                placeholder="Additional Information"
                class="form-control"
                style="height: 120px">
              </textarea>
          </div>
          <div class="form-group center">
            <button [ngClass]="{'sell-my-car-button' : validForm }"
                    class="sell-button"
                    *ngIf="btnShow"
                    [disabled]="!sellCarForm.valid">SEND
            </button>
          </div>
        </form>
        <div class="form-spinner"
             *ngIf="spinner">
          <div class="lds-dual-ring loader-container">
            <p-progressSpinner></p-progressSpinner>SENDING
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
