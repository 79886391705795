import { Injectable } from "@angular/core";
@Injectable()
export class CarImages {
  id: string;
  images: [
    { image_url: string }
  ];
  extras: [
    {
      name: string;
    }
  ];

}
